import { Icon, IconProps } from '@chakra-ui/react'
import { memo } from 'react'

type Props = Omit<IconProps, 'stroke' | 'fill'> & {
  color?: string
}

export const LetterLogo = memo(({ color = 'white', ...props }: Props) => {
  return (
    <Icon width="170px" height="38px" viewBox="0 0 170 38" {...props}>
      <path
        d="M143.88 32V30.78H145.54C145.847 30.78 146.087 30.69 146.26 30.51C146.433 30.33 146.52 30.11 146.52 29.85C146.52 29.67 146.48 29.51 146.4 29.37C146.32 29.23 146.207 29.12 146.06 29.04C145.92 28.96 145.747 28.92 145.54 28.92H143.88V27.73H145.41C145.67 27.73 145.88 27.6667 146.04 27.54C146.2 27.4067 146.28 27.2067 146.28 26.94C146.28 26.68 146.2 26.4867 146.04 26.36C145.88 26.2267 145.67 26.16 145.41 26.16H143.88V24.94H145.71C146.163 24.94 146.547 25.0233 146.86 25.19C147.18 25.3567 147.423 25.58 147.59 25.86C147.757 26.14 147.84 26.45 147.84 26.79C147.84 27.2233 147.7 27.59 147.42 27.89C147.14 28.19 146.727 28.3967 146.18 28.51L146.22 27.98C146.813 28.0933 147.27 28.3233 147.59 28.67C147.917 29.0167 148.08 29.4433 148.08 29.95C148.08 30.3367 147.983 30.6867 147.79 31C147.603 31.3067 147.33 31.55 146.97 31.73C146.617 31.91 146.19 32 145.69 32H143.88ZM142.68 32V24.94H144.23V32H142.68ZM149.986 32V24.94H151.556V32H149.986ZM151.186 32V30.64H155.056V32H151.186ZM151.186 29.05V27.74H154.706V29.05H151.186ZM151.186 26.29V24.94H155.006V26.29H151.186ZM158.554 32V25.04H160.124V32H158.554ZM156.344 26.31V24.94H162.334V26.31H156.344ZM162.711 32L165.511 24.94H166.931L169.701 32H168.041L165.921 26.14H166.491L164.341 32H162.711ZM164.301 30.72V29.44H168.151V30.72H164.301Z"
        fill={color}
      />
      <g clipPath="url(#clip0_1521_2)">
        <path
          d="M14.4506 28.105L21.3742 32.3887L14.4557 36.7214L0.515625 28.665V12.2114L21.3742 0.59375L35.064 8.65538V24.4793L28.6204 28.665L14.4557 20.8769"
          stroke={color}
          strokeWidth="1.03222"
          fill="transparent"
          strokeMiterlimit="10"
        />
        <path
          d="M14.4609 36.6927V20.1643L35.064 8.63184"
          stroke={color}
          strokeWidth="1.03222"
          strokeMiterlimit="10"
          fill="transparent"
        />
        <path
          d="M35.0969 24.4406L21.2109 16.5234"
          stroke={color}
          strokeWidth="1.03222"
          strokeMiterlimit="10"
          fill="transparent"
        />
        <path
          d="M14.5187 20.3172L0.632812 12.3975"
          stroke={color}
          strokeWidth="1.03222"
          strokeMiterlimit="10"
          fill="transparent"
        />
        <path d="M48.5078 31.5273V5.59277H53.207V31.5273H48.5078Z" fill={color} />
        <path
          d="M65.6532 31.9221C63.8365 31.9221 62.2159 31.5273 60.7941 30.7376C59.3722 29.948 58.2522 28.8719 57.4393 27.5094C56.6265 26.1468 56.2188 24.5933 56.2188 22.8463C56.2188 21.0993 56.6187 19.5819 57.4213 18.2194C58.2213 16.8568 59.3154 15.7808 60.7037 14.9911C62.0895 14.2015 63.6456 13.8066 65.3668 13.8066C66.9925 13.8066 68.4506 14.1782 69.7434 14.9189C71.0337 15.6595 72.0401 16.6891 72.7575 18.0026C73.4749 19.3187 73.8336 20.8128 73.8336 22.4876C73.8336 22.7999 73.8207 23.1147 73.7974 23.4372C73.7742 23.7598 73.7123 24.1134 73.6194 24.4953L59.0212 24.5314V21.0167L71.4311 20.9806L69.4931 22.4515C69.4699 21.4244 69.2892 20.5625 68.9563 19.8683C68.6209 19.1742 68.1538 18.6478 67.5577 18.2891C66.959 17.9304 66.2184 17.7523 65.3332 17.7523C64.4017 17.7523 63.5862 17.9562 62.8946 18.3613C62.2005 18.769 61.6689 19.3471 61.2973 20.1006C60.9257 20.8541 60.7425 21.7444 60.7425 22.774C60.7425 23.8037 60.9386 24.7404 61.3334 25.5172C61.7282 26.2939 62.2959 26.8874 63.0366 27.2926C63.7772 27.7003 64.6391 27.9016 65.6197 27.9016C66.5048 27.9016 67.3048 27.7571 68.0222 27.4706C68.7396 27.1842 69.3615 26.73 69.8879 26.1081L72.6852 28.9054C71.8001 29.9093 70.7601 30.6628 69.5653 31.166C68.368 31.6692 67.0674 31.9195 65.6558 31.9195L65.6532 31.9221Z"
          fill={color}
        />
        <path
          d="M75.2655 18.3276V14.2013H88.0702V18.3276H75.2655ZM79.3195 31.5271V6.99121H84.0187V31.5271H79.3195V31.5271Z"
          fill={color}
        />
        <path
          d="M89.8633 18.3276V14.2013H102.668V18.3276H89.8633ZM93.9173 31.5271V6.99121H98.6165V31.5271H93.9173V31.5271Z"
          fill={color}
        />
        <path
          d="M113.61 31.9221C111.794 31.9221 110.173 31.5273 108.751 30.7376C107.329 29.948 106.209 28.8719 105.396 27.5094C104.584 26.1468 104.176 24.5933 104.176 22.8463C104.176 21.0993 104.576 19.5819 105.378 18.2194C106.178 16.8568 107.272 15.7808 108.661 14.9911C110.047 14.2015 111.603 13.8066 113.324 13.8066C114.95 13.8066 116.408 14.1782 117.7 14.9189C118.991 15.6595 119.997 16.6891 120.715 18.0026C121.432 19.3187 121.791 20.8128 121.791 22.4876C121.791 22.7999 121.778 23.1147 121.754 23.4372C121.731 23.7598 121.669 24.1134 121.576 24.4953L106.978 24.5314V21.0167L119.388 20.9806L117.45 22.4515C117.427 21.4244 117.246 20.5625 116.913 19.8683C116.578 19.1742 116.111 18.6478 115.515 18.2891C114.916 17.9304 114.175 17.7523 113.29 17.7523C112.359 17.7523 111.543 17.9562 110.852 18.3613C110.157 18.769 109.626 19.3471 109.254 20.1006C108.883 20.8541 108.699 21.7444 108.699 22.774C108.699 23.8037 108.896 24.7404 109.29 25.5172C109.685 26.2939 110.253 26.8874 110.994 27.2926C111.734 27.7003 112.596 27.9016 113.577 27.9016C114.462 27.9016 115.262 27.7571 115.979 27.4706C116.697 27.1842 117.319 26.73 117.845 26.1081L120.642 28.9054C119.757 29.9093 118.717 30.6628 117.522 31.166C116.328 31.6692 115.024 31.9195 113.613 31.9195L113.61 31.9221Z"
          fill={color}
        />
        <path
          d="M124.73 31.5273V14.2015H129.43V31.5273H124.73ZM129.43 21.987L127.6 20.7303C127.814 18.6013 128.436 16.9214 129.466 15.6904C130.493 14.4595 131.977 13.8428 133.915 13.8428C134.777 13.8428 135.535 13.9795 136.193 14.2557C136.851 14.5318 137.455 14.9782 138.005 15.6001L135.063 19.009C134.8 18.6994 134.482 18.4774 134.113 18.3458C133.742 18.2142 133.319 18.1497 132.841 18.1497C131.837 18.1497 131.017 18.462 130.384 19.0813C129.75 19.7032 129.435 20.6709 129.435 21.987H129.43Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1521_2">
          <rect width="138" height="37.3251" fill={color} />
        </clipPath>
      </defs>
    </Icon>
  )
})
