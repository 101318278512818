import { Box, Flex, Text } from '@chakra-ui/react'

type Props = {
  title: string
  description: string | React.ReactNode
  index: number
  children?: React.ReactNode
}

export const AboutOption = ({ description, index, title, children }: Props) => {
  return (
    <Box>
      <Flex fontSize="18px" fontWeight="semibold" gridColumnGap="6px">
        <Text color="letter.pink.light">OPTION {index}</Text>

        <Text>{title}</Text>
      </Flex>

      <Text fontSize="18px">{description}</Text>

      {children && <Box marginTop="26px">{children}</Box>}
    </Box>
  )
}
