import { Box, Spinner } from '@chakra-ui/react'
import { Elements } from '@stripe/react-stripe-js'
import type { Stripe, StripeElements, StripeElementsOptions } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'

import { PaymentGenericModal, TPaymentGenericModalProps } from '../PaymentGenericModal/PaymentGenericModal'
import { PaymentStripeForm } from './PaymentStripeForm'

export type TStripeGetClientSecret = () => Promise<string | undefined>

export type TStripeOnPay = (stripe: Stripe, elements: StripeElements) => Promise<void>

type Props = Omit<TPaymentGenericModalProps, 'children' | 'disableClose' | 'showSpinner'> & {
  onPay: TStripeOnPay
  onGetClientSecret: TStripeGetClientSecret
}

const STRIPE_INSTANCE = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string)

const STRIPE_DEFAULT_OPTIONS: StripeElementsOptions = {
  locale: 'en',
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap',
    },
  ],
  appearance: {
    theme: 'none',
    rules: {
      '.Label': {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '14px',
        marginBottom: '12px',
      },

      '.Input': {
        border: '1px solid #E2E2E2',
        boxShadow: '0px 2px 4px #E2E2E2',
        padding: '10px 16px',
        borderRadius: '2px',
      },

      '.Input:focus': {
        outline: 'red',
      },
    },

    variables: {
      fontFamily: 'Outfit',
    },
  },
}

export const PaymentStripeModal = ({ isOpen, onGetClientSecret, onPay, isPaying, ...props }: Props) => {
  const [clientSecret, setClientSecret] = useState<string>()
  const [clientSecretIsRequesting, setClientSecretIsRequesting] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setClientSecret(undefined)
      return
    }

    setClientSecretIsRequesting(true)

    onGetClientSecret()
      .then(setClientSecret)
      .finally(() => setClientSecretIsRequesting(false))
  }, [onGetClientSecret, isOpen])

  return (
    <PaymentGenericModal
      isOpen={isOpen}
      {...props}
      disableClose={clientSecretIsRequesting}
      showSpinner={clientSecretIsRequesting}
      isPaying={isPaying}
    >
      <>
        {clientSecretIsRequesting ? (
          <Box position="absolute" paddingTop="32px" top="54px" left="50%" transform="translateX(-50%)">
            <Spinner />
          </Box>
        ) : (
          clientSecret && (
            <Elements
              stripe={STRIPE_INSTANCE}
              options={{
                ...STRIPE_DEFAULT_OPTIONS,
                clientSecret,
              }}
            >
              <PaymentStripeForm onPay={onPay} isPaying={isPaying} />
            </Elements>
          )
        )}
      </>
    </PaymentGenericModal>
  )
}
