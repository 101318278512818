import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'

import { GradientUserPay } from '../assets/components/GradientUserPay'
import { AboutHowPurchaseFlow } from '../components/about/AboutHowPurchaseFlow'
import { AboutHowPurchaseNeon } from '../components/about/AboutHowPurchaseNeon'
import { AboutHowPurchaseWalletConnect } from '../components/about/AboutHowPurchaseWalletConnect'
import { AboutSubHeader } from '../components/about/SubHeader'

export const AboutHowPurchaseLayout = () => {
  return (
    <Box width="full">
      <AboutSubHeader icon={<GradientUserPay />} title="Purchase" />

      <Tabs variant="unstyled" marginTop="38px" marginBottom="64px">
        <TabList justifyContent="center">
          <Flex height="36px" border="1px solid black" borderColor="letter.onSurface.main" borderRadius="full">
            <Tab
              width={{ base: '100px', sm: '160px' }}
              fontSize={{ base: '12px', sm: '18px' }}
              borderRadius="full"
              paddingX={{ base: '12px', sm: '24px' }}
              _selected={{ backgroundColor: 'letter.box.main', color: 'letter.onBox.main', fontWeight: 'bold' }}
            >
              Letter wallet
            </Tab>
            <Tab
              width={{ base: '100px', sm: '160px' }}
              fontSize={{ base: '12px', sm: '18px' }}
              paddingX={{ base: '12px', sm: '24px' }}
              borderRadius="full"
              _selected={{ backgroundColor: 'letter.box.main', color: 'letter.onBox.main', fontWeight: 'bold' }}
            >
              Neon wallet
            </Tab>
            <Tab
              width={{ base: '100px', sm: '160px' }}
              fontSize={{ base: '12px', sm: '18px' }}
              paddingX={{ base: '12px', sm: '24px' }}
              borderRadius="full"
              _selected={{ backgroundColor: 'letter.box.main', color: 'letter.onBox.main', fontWeight: 'bold' }}
            >
              Flow wallet
            </Tab>
          </Flex>
        </TabList>

        <TabPanels>
          <TabPanel>
            <AboutHowPurchaseNeon />
          </TabPanel>

          <TabPanel>
            <AboutHowPurchaseWalletConnect />
          </TabPanel>

          <TabPanel>
            <AboutHowPurchaseFlow />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
