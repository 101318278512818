import { Box, Flex, Heading, Text } from '@chakra-ui/react'

import { CheckIcon } from '../../../assets/components/CheckIcon'
import type { TStaticCategory } from '../../../types/pages/category'
import type { TEvent } from '../../../types/pages/event'
import { RoundedIcon } from '../../RoundedIcon'

type Props = {
  event: TEvent
  staticCategory: TStaticCategory
}

export const PaymentInfo = ({ event, staticCategory }: Props) => {
  return (
    <Box>
      <Box fontSize="18px">
        <Text>{event.paymentDetails ?? event.description}</Text>
      </Box>

      {event.paymentBenefits && (
        <Box>
          <Heading as="h4" fontSize="18px" fontWeight="bold" marginTop="28px" marginBottom="42px">
            Benefits:
          </Heading>

          <Flex direction="column" gridRowGap="16px">
            {event.paymentBenefits.map((benefit, index) => (
              <Flex alignItems="center" gridColumnGap="12px" key={`benefit-${index}`}>
                <RoundedIcon size="normal" icon={<CheckIcon />} background={staticCategory.theme.brand} />

                <Text fontSize="16px">{benefit}</Text>
              </Flex>
            ))}
          </Flex>
        </Box>
      )}
    </Box>
  )
}
