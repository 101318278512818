import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const LaptopIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 13" fill="white" {...props}>
      <path d="M3 2.22909H15V9.83938H3M15 11.3614C15.3978 11.3614 15.7794 11.2011 16.0607 10.9156C16.342 10.6302 16.5 10.2431 16.5 9.83938V2.22909C16.5 1.38435 15.825 0.707031 15 0.707031H3C2.1675 0.707031 1.5 1.38435 1.5 2.22909V9.83938C1.5 10.2431 1.65804 10.6302 1.93934 10.9156C2.22064 11.2011 2.60218 11.3614 3 11.3614H0V12.8835H18V11.3614H15Z" />
    </Icon>
  );
});
