import { Box, Flex, FlexProps, Text } from '@chakra-ui/react'

import { GradientAuthorizationIcon } from '../../assets/components/AuthorizationIcon'

type Props = {
  description?: string
} & FlexProps

export const SignMessageContent = ({ description, ...props }: Props) => {
  return (
    <Flex direction="column" alignItems="center" {...props}>
      <Flex
        width="150px"
        height="150px"
        backgroundColor="letter.surface.darkest"
        borderRadius="full"
        justifyContent="center"
        alignItems="center"
        marginY="28px"
      >
        <GradientAuthorizationIcon boxSize="128px" />
      </Flex>

      <Box maxWidth="432px" textAlign="center">
        <Text fontSize="16px" fontWeight="semibold">
          Please go to your wallet and follow the instructions to authorize this request.
        </Text>

        {description && (
          <Text fontSize="12px" fontWeight="light" marginTop="8px">
            {description}
          </Text>
        )}
      </Box>
    </Flex>
  )
}
