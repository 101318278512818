import { Link as ChakraLink, LinkProps as ChakraLinkProps, Text, TextProps } from '@chakra-ui/react'
import { cloneElement } from 'react'
import { Link as RouterLink } from 'react-router-dom'

type IconSize = 'normal' | 'big' | 'small'

export type LinkProps = {
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  label: string
  full?: boolean
  size?: 'normal' | 'big'
  to: string
  variant?: 'text' | 'contained' | 'outline'
  leftIconSize?: IconSize
  rightIconSize?: IconSize
  textProps?: TextProps
} & ChakraLinkProps

export const Link = ({
  label,
  to,
  size = 'normal',
  leftIcon,
  rightIcon,
  isExternal,
  variant = 'contained',
  full = false,
  leftIconSize = 'normal',
  rightIconSize = 'normal',
  textProps,
  ...props
}: LinkProps) => {
  const solidStyle: ChakraLinkProps =
    variant === 'contained'
      ? {
          backgroundColor: 'letter.button.main',
          paddingX: '20px',
          paddingY: '10px',
          borderRadius: '2px',
          width: !full ? 'fit-content' : undefined,
          height: size === 'normal' ? '40px' : '57px',
          textTransform: 'uppercase',
          _hover: { backgroundColor: 'letter.button.hover' },
        }
      : {}

  const outlineStyle: ChakraLinkProps =
    variant === 'outline'
      ? {
          backgroundColor: 'transparent',
          border: '1px solid',
          borderColor: 'letter.onSurface.divider',
          paddingX: '20px',
          paddingY: '10px',
          borderRadius: '2px',
          width: !full ? 'fit-content' : undefined,
          height: size === 'normal' ? '40px' : '57px',
          textTransform: 'uppercase',
          _hover: { backgroundColor: 'letter.button.hover' },
        }
      : {}

  const textStyle: ChakraLinkProps =
    variant === 'text'
      ? {
          backgroundColor: 'transparent',
          _hover: { color: 'letter.button.hover' },
        }
      : {}

  const iconStyle = (iconSize: IconSize) => ({
    boxSize: iconSize === 'normal' ? '18px' : iconSize === 'big' ? '38px' : '12px',
    minWidth: iconSize === 'normal' ? '18px' : iconSize === 'big' ? '38px' : '12px',
  })

  return (
    <ChakraLink
      as={!isExternal ? RouterLink : undefined}
      to={!isExternal ? to : undefined}
      href={isExternal ? to : undefined}
      isExternal={isExternal}
      display="flex"
      alignItems="center"
      justifyContent="center"
      color="letter.onSurface.main"
      {...solidStyle}
      {...outlineStyle}
      {...textStyle}
      {...props}
    >
      {!!leftIcon &&
        cloneElement(leftIcon, {
          ...iconStyle(leftIconSize),
          left: '20px',
        })}

      <Text
        width="100%"
        minWidth="0"
        noOfLines={1}
        whiteSpace="normal"
        fontSize="14px"
        lineHeight="14px"
        fontWeight="bold"
        textAlign="center"
        paddingX="8px"
        {...textProps}
      >
        {label}
      </Text>

      {!!rightIcon &&
        cloneElement(rightIcon, {
          ...iconStyle(rightIconSize),
          right: '20px',
        })}
    </ChakraLink>
  )
}
