import axios from 'axios'
import { IAuthenticator, MultiAuthenticator, ObjectResponse } from 'letter-sdk'

import {
  TBuyLicense,
  TCheckAccessParams,
  TGetEventParams,
  TGetEventsByCategoryParams,
  TGetEventsByCategoryResponse,
  TGetObjectParams,
  TGetObjectResponse,
  TGetPriceLicenseParams,
  TGetPropertiesParams,
  TPropertiesResponse,
  TSignMessageParams,
  TSignMessageResponse,
} from '../types/helpers/letterSDK'
import { TEvent } from '../types/pages/event'

export class LetterSDKHelper {
  private multiAuthenticator: MultiAuthenticator

  constructor(authenticators: IAuthenticator<any>[]) {
    this.multiAuthenticator = new MultiAuthenticator(authenticators)
  }

  private validURL(string: string) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$',
      'i'
    )
    return !!pattern.test(string)
  }

  private async getObjectContent(object: ObjectResponse): Promise<TEvent> {
    if (!object.metadata) throw new Error('Object does not have a metadata')

    const presentationUrl = object.metadata.presentationUrl

    if (!presentationUrl) throw new Error('Object metadata does not have a presentationUrl')

    if (!this.validURL(presentationUrl)) throw new Error('Object metadata presentationUrl is not a valid URL')

    const response = await axios.get(presentationUrl)

    return {
      type: 'online',
      ...response.data,
      label: object.label,
      categoryLabel: object.categories[0],
    }
  }

  public async getObject({ contentProviderLabel, objectLabel }: TGetObjectParams): Promise<TGetObjectResponse> {
    return await this.multiAuthenticator.getObject(contentProviderLabel, objectLabel)
  }

  public async getEvent({ contentProviderLabel, eventLabel }: TGetEventParams): Promise<TEvent> {
    const object = await this.multiAuthenticator.getObject(contentProviderLabel, eventLabel)

    const event = await this.getObjectContent(object)

    return event
  }

  public async getEventsByCategory({
    categoryLabel,
    contentProviderLabel,
  }: TGetEventsByCategoryParams): Promise<TGetEventsByCategoryResponse> {
    const objectLabels: string[] = []
    let pageIndex = 1
    let hasMore = true

    do {
      const listedObjects = await this.multiAuthenticator.listObjectsOfCategory(
        contentProviderLabel,
        categoryLabel,
        pageIndex - 1
      )

      objectLabels.push(...listedObjects.objectLabels)

      hasMore = pageIndex * listedObjects.pageSize < listedObjects.totalCount
      pageIndex++
    } while (hasMore)

    const promises = objectLabels.map(async eventLabel => {
      try {
        return await this.getEvent({ contentProviderLabel, eventLabel })
      } catch {}
    })

    const objectList = await Promise.all(promises)

    const filteredList = objectList.filter((object): object is TEvent => !!object).reverse()

    return {
      events: filteredList,
    }
  }

  public async checkAccess({
    objectLabel,
    contentProviderLabel,
    address,
    blockchainLabel,
  }: TCheckAccessParams): Promise<boolean> {
    return await this.multiAuthenticator.hasAccess(
      { accountId: address, blockchainLabel },
      contentProviderLabel,
      objectLabel
    )
  }

  public async signMessage({ message }: TSignMessageParams): Promise<TSignMessageResponse> {
    return this.multiAuthenticator.signMessage(message)
  }

  public async buyLicense({ contentProviderLabel, objectLabel }: TBuyLicense): Promise<void> {
    const txRef = await this.multiAuthenticator.buyLicenseNFT(contentProviderLabel, objectLabel)
    await this.multiAuthenticator.confirmMint(txRef)
  }

  public async getNFTProperties({ blockchainLabel, tokenId }: TGetPropertiesParams): Promise<TPropertiesResponse> {
    return await this.multiAuthenticator.properties({ blockchainLabel, id: tokenId })
  }

  public async getPrice({ contentProviderLabel, objectLabel }: TGetPriceLicenseParams): Promise<number> {
    const price = await this.multiAuthenticator.getPrice(contentProviderLabel, objectLabel, undefined)
    return price
  }
}
