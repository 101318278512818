import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const HomeIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 16" fill="white" {...props}>
      <path d="M7.2 15.3V9.9H10.8V15.3H15.3V8.1H18L9 0L0 8.1H2.7V15.3H7.2Z" />
    </Icon>
  );
});
