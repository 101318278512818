import { Neo3Authenticator } from 'letter-sdk'
import type { ReactNode } from 'react'

export enum EWalletConnectStatus {
  starting = 'starting',
  started = 'started',
  error = 'error',
}

type TWalletConnectConnectData = {
  uri: string
  approval(): Promise<void>
}
export interface WalletConnectContextData {
  connect(): Promise<TWalletConnectConnectData>
  getAuthenticator(): Neo3Authenticator | undefined
  disconnect(): Promise<void>
  status: EWalletConnectStatus
}

export type WalletConnectContextProps = {
  children: ReactNode
}
