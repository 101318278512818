import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const MoreIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 12" fill="white" {...props}>
      <path d="M0 0H18V2H0V0ZM0 5H18V7H0V5ZM0 10H18V12H0V10Z" />
    </Icon>
  );
});
