import { Box, Flex, Spinner, Text, useBreakpointValue } from '@chakra-ui/react'
import jsPDF from 'jspdf'
import { useEffect, useState } from 'react'

import { ArrowRightIcon } from '../../assets/components/ArrowRightIcon'
import { CopyIcon } from '../../assets/components/CopyIcon'
import { DownloadIcon } from '../../assets/components/DownloadIcon'
import { WarningIcon } from '../../assets/components/WarningIcon'
import { useClipboard } from '../../hooks/useClipboard'
import { GradientButton } from '../GradientButton'
import { ModalTemplate } from '../ModalTemplate'
import { SectionHeader } from '../SectionHeader'
import { LoginBackupOption } from './LoginBackupOption'

type Props = {
  onClose(): void
  isOpen: boolean
  onLogin: (code: string) => void
  onLoadCode: () => Promise<string>
}

export const LoginCreatedAccountModal = ({ isOpen, onClose, onLoadCode, onLogin }: Props) => {
  const modalSize = useBreakpointValue({ base: 'full', md: '2xl' })
  const buttonContinueFull = useBreakpointValue({ base: true, sm: false })
  const copy = useClipboard()

  const [alreadyBackup, setAlreadyBackup] = useState(false)
  const [code, setCode] = useState<string>()

  const handleDownload = () => {
    if (!code) return

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
    })

    pdf.text('YOUR AUTHENTICATION CODE:', 20, 20)
    pdf.text(code, 20, 30)
    pdf.save('bip39code-letter.pdf')

    setAlreadyBackup(true)
  }

  const handleCopy = () => {
    copy(code ?? '')
    setAlreadyBackup(true)
  }

  useEffect(() => {
    if (!isOpen) return

    onLoadCode().then(setCode)
  }, [isOpen, onLoadCode])

  return (
    <ModalTemplate
      size={modalSize}
      isOpen={isOpen}
      onClose={onClose}
      title="Account created!"
      disableClose={!alreadyBackup}
    >
      {!!code && (
        <>
          <Box marginTop="34px">
            <SectionHeader title="YOUR AUTHENTICATION & LOGIN CODE" />

            <Box
              backgroundColor="letter.surface.darkest"
              paddingTop="24px"
              paddingBottom="8px"
              paddingX="32px"
              marginTop="18px"
            >
              <Flex justifyContent="center">
                <Text fontSize="20px" fontWeight="semibold" letterSpacing="4px" noOfLines={2} textAlign="center">
                  {code}
                </Text>
              </Flex>

              <Flex justifyContent="center">
                <Text as="span" fontSize="12px" fontWeight="light">
                  BIP39 CODE
                </Text>
              </Flex>
            </Box>
          </Box>

          <Box marginTop="34px">
            <SectionHeader title="IMPORTANT INSTRUCTIONS" />

            <Text fontSize="18px" marginTop="18px">
              Now you have your Authentication Code it's ESSENTIAL that you back it up somewhere safe so you can use it
              to login next time you visit the site.
            </Text>
          </Box>

          <Box marginTop="24px">
            <Text fontSize="18px" fontWeight="semibold">
              Below we have provided you with a number of options to help with backup:
            </Text>

            <Flex
              gridColumnGap="20px"
              gridRowGap="22px"
              marginTop={{ base: '28px', md: '54px' }}
              direction={{ base: 'column', md: 'row' }}
            >
              <LoginBackupOption
                title="COPY AUTHENTICATION CODE"
                description="Copy the code to your clipboard so you can paste into a secure document or even better a secure password app."
                buttonIcon={<CopyIcon fill="letter.gradient.main" />}
                buttonLabel="COPY CODE TO CLIPBOARD"
                buttonClick={handleCopy}
              />

              <LoginBackupOption
                title="DOWNLOAD PDF BACK UP"
                description="Click the download button below to download a PDF document containing your code."
                buttonIcon={<DownloadIcon fill="letter.gradient.main" />}
                buttonLabel="DOWNLOAD YOUR CODE"
                buttonClick={handleDownload}
              />
            </Flex>

            {!alreadyBackup && (
              <Flex alignItems="center" gridColumnGap="8px" justifyContent="center" marginTop="34px">
                <WarningIcon fill="letter.pink.light" boxSize="20px" />

                <Text color="letter.pink.light" fontSize="16px">
                  Please select a back up option above in order to continue
                </Text>
              </Flex>
            )}

            <Flex justifyContent="center" marginTop="24px">
              <GradientButton
                disabled={!alreadyBackup}
                label="Continue"
                rightIcon={<ArrowRightIcon boxSize="10px" />}
                onClick={() => onLogin(code)}
                full={buttonContinueFull}
              />
            </Flex>
          </Box>
        </>
      )}

      {!code && (
        <Flex height="550px" alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      )}
    </ModalTemplate>
  )
}
