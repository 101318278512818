import { Box, Flex, Text } from '@chakra-ui/react'

import { GradientUserIcon } from '../assets/components/GradientUserIcon'
import dapper from '../assets/images/dapper.png'
import neonWallet from '../assets/images/neon-wallet.png'
import { AboutOption } from '../components/about/Option'
import { AboutStep } from '../components/about/Step'
import { AboutSubHeader } from '../components/about/SubHeader'
import { AboutSubStep } from '../components/about/SubStep'
import { Web3Link } from '../components/about/Web3Link'

export const AboutHowSignupLayout = () => {
  return (
    <Box width="full">
      <AboutSubHeader icon={<GradientUserIcon />} title="Sign up" />

      <Flex direction="column" marginTop="64px" gridRowGap="32px">
        <AboutStep
          title="Click on ‘Sign up’"
          description="To start your letter experience look to the top right of the site and click on the “Sign up” link in the menu bar."
          index={1}
        />

        <AboutStep
          title="Choose sign up method"
          description={
            <>
              On the Sign up page you have two methods for signing up.
              <br />
              The first is by creating a letter “One-Click Embedded Account”. Alternatively you can use a Web3 wallet
              like Neon or Dapper.
            </>
          }
          index={2}
        >
          <Flex direction="column" gridRowGap="54px">
            <AboutOption
              title="One-Click Embedded Account"
              description="A One-Click Embedded Account is a quick and easy way to get started."
              index={1}
            >
              <Flex direction="column" gridRowGap="38px">
                <AboutSubStep
                  index={1}
                  description="Click the “Create my 1-Click Account” button in the “New users” section."
                />

                <AboutSubStep
                  index={2}
                  description={
                    <>
                      You have now created your account, its that simple.
                      <br />
                      <br />
                      With your account created you will receive an Authentication & Login code.
                      <br />
                      <br />
                      <b>IMPORTANT:</b>
                      <br />
                      Remember to keep a backup, because it is essential to identify you as a member. You can either
                      copy it or download a PDF to keep it in your computer.
                    </>
                  }
                />
              </Flex>
            </AboutOption>

            <AboutOption
              title="Create an account with your Web3 wallet"
              description="Already a Neon, Dapper Wallet or other wallet user? Just connect your account in one step."
              index={2}
            >
              <AboutSubStep
                index={1}
                description="From the list of support wallets, select the wallet you would like to use. Remember all purchases will be associated with this wallet so remember to use this wallet to login. "
              />

              <Box marginTop="54px">
                <Text fontSize="18px" fontWeight="semibold">
                  If you would like to use a Web3 wallet you can download them from the sites below.
                </Text>

                <Flex direction="column" gridRowGap="8px" marginTop="34px">
                  <Web3Link label="NEON" leftIcon={neonWallet} to="https://neonwallet.com/" />
                  <Web3Link label="DAPPER" leftIcon={dapper} to="https://www.meetdapper.com" />
                </Flex>
              </Box>
            </AboutOption>
          </Flex>
        </AboutStep>
      </Flex>
    </Box>
  )
}
