import { Box, Flex, Heading, Image } from '@chakra-ui/react'

import { AttributesIcon } from '../assets/components/AttributesIcon'
import { NFTIcon } from '../assets/components/NFTIcon'
import { ObjectIcon } from '../assets/components/ObjectIcon'
import nftDefaultImage from '../assets/images/nft_default.png'
import { NFTDetailsPropertiesBox } from '../components/nft/NFTDetailsPropertiesBox'
import { MainTemplate } from '../templates/Main'
import { TGetObjectResponse, TPropertiesResponse } from '../types/helpers/letterSDK'

type Props = {
  loading: boolean
  properties?: TPropertiesResponse
  object?: TGetObjectResponse
}

export const NFTLayout = ({ properties, loading, object }: Props) => {
  return (
    <MainTemplate altBackground loading={loading}>
      {properties && (
        <Flex
          flexGrow={1}
          width="full"
          justifyContent="center"
          alignItems="center"
          padding={{ base: 'unset', md: '40px' }}
          color="letter.onSurface.main"
        >
          <Box
            paddingX={{ base: '12px', sm: '34px' }}
            paddingY={{ base: '28px', sm: '34px' }}
            marginY={{ base: 'unset', md: '64px' }}
            marginX={{ base: 'unset', md: '12px' }}
            color="letter.onSurface.main"
            backgroundColor="letter.surface.main"
            borderRadius={{ base: 'unset', md: 'md' }}
            border="1px solid"
            borderColor="letter.onSurface.divider"
            width="full"
            maxWidth="1110px"
          >
            <Box>
              <Heading as="h3" fontSize="32px" fontWeight="semibold" color="letter.onSurface.main">
                NFT details
              </Heading>

              <Box width="64px" height="8px" background="letter.gradient.dark" marginTop="12px" />
            </Box>

            <Flex
              marginTop={{ base: '28px', md: '58px' }}
              width="full"
              columnGap="86px"
              paddingX="20px"
              direction={{ base: 'column', lg: 'row' }}
              alignItems={{ base: 'center', lg: 'unset' }}
            >
              <Image
                src={properties?.image ?? nftDefaultImage}
                fallbackSrc={properties?.image ? nftDefaultImage : undefined}
                boxSize="192px"
                objectFit="contain"
                marginTop={{ base: 'unset', md: '24px' }}
              />

              <Flex width="full" direction="column" rowGap="38px">
                <NFTDetailsPropertiesBox
                  icon={<NFTIcon />}
                  title="DETAILS"
                  properties={[
                    { name: 'Name', value: properties.name, isBold: true },
                    { name: 'Description', value: properties.description },
                  ]}
                />

                <NFTDetailsPropertiesBox
                  icon={<AttributesIcon />}
                  title="ATTRIBUTES"
                  properties={[
                    { name: 'Object', value: properties.attributes.objectLabel },
                    { name: 'Category', value: properties.attributes.categoryLabel },
                    { name: 'Content Provider', value: properties.attributes.contentProviderLabel },
                    { name: 'Expiration', value: properties.attributes.expiration },
                    { name: 'Transferable', value: properties.attributes.transferable },
                  ]}
                />

                {object && (
                  <NFTDetailsPropertiesBox
                    icon={<ObjectIcon />}
                    title="OBJECT PROPERTIES"
                    properties={[
                      { name: 'Category', value: object.categories[0] },
                      { name: 'Content Provider', value: object.contentProviderLabel },
                      { name: 'Label', value: object.label },
                      { name: 'Metadata', value: JSON.stringify(object.metadata), isCollapsible: true },
                    ]}
                  />
                )}
              </Flex>
            </Flex>
          </Box>
        </Flex>
      )}
    </MainTemplate>
  )
}
