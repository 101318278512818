import { useDisclosure } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useLoaderData, useNavigate, useParams } from 'react-router-dom'

import { renderToast } from '../helpers/chakraToast'
import { useLazyFetch } from '../hooks/useFetch'
import { useSDK } from '../hooks/useSDK'
import { DiscordSignLayout } from '../layouts/DiscordSign'
import { TLoaderResponse } from '../routes/loaders/discordSign'

type TParams = {
  token: string
}

const DISCORD_BOT_SIGN_ENDPOINT = `${process.env.REACT_APP_DISCORD_BOT_URL}/sign`

export const DiscordSignPage = () => {
  const {
    isOpen: authorizationModalIsOpen,
    onClose: onCloseAuthorizationModal,
    onOpen: onOpenAuthorizationModal,
  } = useDisclosure()
  const { isOpen: successModalIsOpen, onClose: onCloseSuccessModal, onOpen: onOpenSuccessModal } = useDisclosure()

  const { token } = useParams<TParams>()
  const { signMessage, getStatus, needsAuthorizationToWrite } = useSDK()
  const [dispatch] = useLazyFetch({ endpoint: DISCORD_BOT_SIGN_ENDPOINT, method: 'POST' })
  const navigate = useNavigate()
  const { discordId, discordUsername } = useLoaderData() as TLoaderResponse

  const [isRequesting, setIsRequesting] = useState(false)

  const signMessageStatus = useMemo(() => getStatus('signMessage'), [getStatus])

  const handleCloseSuccessModal = () => {
    onCloseSuccessModal()
    navigate('/')
  }

  const handleGivePermission = async () => {
    try {
      setIsRequesting(true)

      if (!token) return

      const signedMessage = await signMessage({ message: token })

      await dispatch({ data: signedMessage })

      onOpenSuccessModal()
    } finally {
      setIsRequesting(false)
    }
  }

  useEffect(() => {
    if (!needsAuthorizationToWrite) return

    if (signMessageStatus === 'requesting') {
      onOpenAuthorizationModal()
      return
    }

    if (signMessageStatus === 'success') {
      onCloseAuthorizationModal()
      renderToast({ message: 'Sign successfully' })
      return
    }

    if (signMessageStatus === 'error') {
      onCloseAuthorizationModal()
      renderToast({ type: 'error', message: 'Error to sign message' })
    }
  }, [onCloseAuthorizationModal, onOpenAuthorizationModal, needsAuthorizationToWrite, signMessageStatus])

  return (
    <DiscordSignLayout
      authorizationModalIsOpen={authorizationModalIsOpen}
      onCloseAuthorizationModal={onCloseAuthorizationModal}
      successModalIsOpen={successModalIsOpen}
      onCloseSuccessModal={handleCloseSuccessModal}
      onGivePermission={handleGivePermission}
      isRequesting={isRequesting}
      discordId={discordId}
      discordUsername={discordUsername}
    />
  )
}
