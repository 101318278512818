import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const PaymentUserIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 19 13" fill="white" {...props}>
      <path d="M5.43359 6.75C7.08359 6.75 8.43359 5.4 8.43359 3.75C8.43359 2.1 7.08359 0.75 5.43359 0.75C3.78359 0.75 2.43359 2.1 2.43359 3.75C2.43359 5.4 3.78359 6.75 5.43359 6.75ZM8.43359 12.75V8.775C7.60859 8.475 6.55859 8.25 5.43359 8.25C2.50859 8.25 0.183594 9.6 0.183594 11.25V12.75H8.43359ZM16.6836 0.75H11.4336C10.6086 0.75 9.93359 1.425 9.93359 2.25V11.25C9.93359 12.075 10.6086 12.75 11.4336 12.75H16.6836C17.5086 12.75 18.1836 12.075 18.1836 11.25V2.25C18.1836 1.425 17.5086 0.75 16.6836 0.75ZM13.6836 11.25H12.1836V2.25H13.6836V11.25Z" />
    </Icon>
  );
});
