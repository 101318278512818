import { Box, Flex, Heading, Hide, Image, Text } from '@chakra-ui/react'

import { HomeIcon } from '../../assets/components/HomeIcon'
import type { TStaticCategory } from '../../types/pages/category'
import type { TEvent } from '../../types/pages/event'
import { Breadcrumb } from '../Breadcrumb'
import { FeaturedEvent } from './FeaturedEvent'

type Props = {
  featureEvent: TEvent
  staticCategory: TStaticCategory
}

export const EventCategoryInfo = ({ featureEvent, staticCategory }: Props) => {
  return (
    <Flex
      direction="column"
      grow={1}
      justifyContent="space-between"
      minHeight="570px"
      width="100%"
      maxWidth="2178px"
      paddingTop="18px"
      paddingBottom="22px"
      paddingX={{ base: '16px', md: '32px', lg: 'min(154px, 8%)' }}
      zIndex={2}
      color="letter.onBox.main"
    >
      <Hide below="md">
        <Breadcrumb
          items={[
            {
              label: <HomeIcon boxSize="18px" />,
              to: '/',
            },
            {
              label: staticCategory.title,
              to: `/category/${staticCategory.contentProviderLabel}/${staticCategory.label}`,
            },
          ]}
        />
      </Hide>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'unset', md: 'space-between' }}
        height="full"
        marginTop="18px"
        alignItems="flex-end"
        gap="68px"
      >
        <Box width="full" maxWidth="840px">
          {staticCategory.logoURL && (
            <Box width={{ base: '120px', md: '180px' }}>
              <Image src={staticCategory.logoURL} width="100%" objectFit="contain" />
            </Box>
          )}

          <Box marginTop="40px">
            <Heading as="h2" fontWeight="bold" fontSize={{ base: '26px', md: '38px' }} noOfLines={2}>
              {staticCategory.title}
            </Heading>

            <Box width="60px" height="8px" backgroundColor={staticCategory.theme.brand} marginTop="12px" />

            <Text fontSize="16px" noOfLines={3} marginTop="16px">
              {staticCategory.description}
            </Text>
          </Box>
        </Box>

        <FeaturedEvent event={featureEvent} staticCategory={staticCategory} />
      </Flex>
    </Flex>
  )
}
