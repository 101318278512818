import { Icon, IconProps } from '@chakra-ui/react'
import { memo } from 'react'

export const NFTIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 20" fill="white" {...props}>
      <path d="M18 14.5C18 14.88 17.79 15.21 17.47 15.38L9.57 19.82C9.41 19.94 9.21 20 9 20C8.79 20 8.59 19.94 8.43 19.82L0.53 15.38C0.21 15.21 0 14.88 0 14.5V5.5C0 5.12 0.21 4.79 0.53 4.62L8.43 0.18C8.59 0.0600001 8.79 0 9 0C9.21 0 9.41 0.0600001 9.57 0.18L17.47 4.62C17.79 4.79 18 5.12 18 5.5V14.5ZM9 2.15L2 6.09V13.91L9 17.85L16 13.91V6.09L9 2.15Z" />
      <path d="M4.295 12V8.44H4.995L5.295 9.19V12H4.295ZM6.745 12L4.68 9.405L4.995 8.44L7.06 11.035L6.745 12ZM6.745 12L6.495 11.21V8.44H7.495V12H6.745ZM8.08406 12V8.44H9.08406V12H8.08406ZM8.89406 10.735V9.905H10.6441V10.735H8.89406ZM8.89406 9.27V8.44H10.7191V9.27H8.89406ZM11.893 12V8.44H12.893V12H11.893ZM10.828 9.29V8.44H13.963V9.29H10.828Z" />
    </Icon>
  )
})
