import { Icon, IconProps } from '@chakra-ui/react'
import { memo } from 'react'

export const UserShieldIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 38 46" fill="white" {...props}>
      <path d="M19 0L0 8.36364V20.9091C0 32.5136 8.10667 43.3655 19 46C29.8933 43.3655 38 32.5136 38 20.9091V8.36364L19 0ZM19 8.36364C20.6797 8.36364 22.2906 9.02451 23.4783 10.2009C24.6661 11.3772 25.3333 12.9727 25.3333 14.6364C25.3333 16.3 24.6661 17.8955 23.4783 19.0719C22.2906 20.2482 20.6797 20.9091 19 20.9091C17.3203 20.9091 15.7094 20.2482 14.5217 19.0719C13.3339 17.8955 12.6667 16.3 12.6667 14.6364C12.6667 12.9727 13.3339 11.3772 14.5217 10.2009C15.7094 9.02451 17.3203 8.36364 19 8.36364ZM29.83 33.4545C27.2756 37.3227 23.4544 40.2291 19 41.6509C14.5456 40.2291 10.7244 37.3227 8.17 33.4545C7.45222 32.4091 6.84 31.3636 6.33333 30.2555C6.33333 26.8055 12.0544 23.9827 19 23.9827C25.9456 23.9827 31.6667 26.7427 31.6667 30.2555C31.16 31.3636 30.5478 32.4091 29.83 33.4545Z" />
    </Icon>
  )
})
