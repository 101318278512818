import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const GoogleCalendarIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 18" {...props}>
      <path d="M14.5 3.5H3.5V14.5H14.5V3.5Z" fill="white" />
      <path
        d="M9.83984 7.46059L10.3438 8.18059L11.1358 7.60459V11.7806H11.9998V6.30859H11.2798L9.83984 7.46059Z"
        fill="#1E88E5"
      />
      <path
        d="M8.4715 8.87256C8.784 8.58556 8.978 8.18756 8.978 7.74806C8.978 6.87456 8.2115 6.16406 7.2695 6.16406C6.4685 6.16406 5.7835 6.66856 5.6045 7.39056L6.433 7.60106C6.5155 7.26906 6.867 7.02806 7.2695 7.02806C7.7405 7.02806 8.124 7.35106 8.124 7.74806C8.124 8.14506 7.7405 8.46806 7.2695 8.46806H6.771V9.33206H7.2695C7.81 9.33206 8.266 9.70756 8.266 10.1521C8.266 10.6041 7.833 10.9721 7.3005 10.9721C6.8195 10.9721 6.4085 10.6671 6.3435 10.2631L5.5 10.4011C5.631 11.2191 6.405 11.8361 7.3 11.8361C8.3035 11.8361 9.12 11.0806 9.12 10.1521C9.12 9.64056 8.868 9.18156 8.4715 8.87256Z"
        fill="#1E88E5"
      />
      <path d="M14 18H4L3.5 16L4 14H14L14.5 16L14 18Z" fill="#FBC02D" />
      <path d="M16 14.5L18 14V4L16 3.5L14 4V14L16 14.5Z" fill="#4CAF50" />
      <path d="M14 4L14.5 2L14 0H1.5C0.6715 0 0 0.6715 0 1.5V14L2 14.5L4 14V4H14Z" fill="#1E88E5" />
      <path d="M14 14V18L18 14H14Z" fill="#E53935" />
      <path d="M16.5 0H14V4H18V1.5C18 0.6715 17.3285 0 16.5 0Z" fill="#1565C0" />
      <path d="M1.5 18H4V14H0V16.5C0 17.3285 0.6715 18 1.5 18Z" fill="#1565C0" />
    </Icon>
  );
});
