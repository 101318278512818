import { ReactNode } from 'react'

export enum TAuthType {
  neon = 'neon',
  wc = 'wc',
  flow = 'flow',
}

export type TAuthAuthenticatedGenericData<T extends TAuthType> = {
  address: string
  type: T
  blockchainLabel: string
}

export type TAuthAuthenticatedNeonData = TAuthAuthenticatedGenericData<TAuthType.neon> & { code: string }
export type TAuthAuthenticatedFlowData = TAuthAuthenticatedGenericData<TAuthType.flow>
export type TAuthAuthenticatedWalletConnectData = TAuthAuthenticatedGenericData<TAuthType.wc>
export type TAuthAuthenticatedAllData =
  | TAuthAuthenticatedNeonData
  | TAuthAuthenticatedWalletConnectData
  | TAuthAuthenticatedFlowData

export type TAuthAuthenticatedData = { isAuthenticated: true } & TAuthAuthenticatedAllData

export type TAuthUnauthenticatedData = {
  isAuthenticated: false
}

export type TAuthData = TAuthAuthenticatedData | TAuthUnauthenticatedData

export interface AuthContextData {
  data: TAuthData
  validate(authenticatedData: TAuthAuthenticatedAllData): void
  revoke(): void
}

export type AuthProps = {
  children: ReactNode
}
