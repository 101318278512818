import { Flex, FlexProps, Heading, IconProps } from '@chakra-ui/react'
import { cloneElement } from 'react'

type Props = {
  icon?: JSX.Element
  iconSize?: any
  iconColor?: string
  title: string
} & FlexProps &
  Pick<IconProps, 'boxSize'>

export const SectionHeader = ({ icon, title, iconSize, iconColor, ...props }: Props) => {
  const iconStyle = {
    boxSize: iconSize ?? '22px',
    minWidth: iconSize ?? '22px',
    fill: iconColor ?? 'letter.onSurface.main',
  }

  return (
    <Flex alignItems="center" gridColumnGap="8px" {...props}>
      {icon && cloneElement(icon, iconStyle)}
      <Heading as="h4" fontSize="16px" fontWeight="bold" textTransform="uppercase">
        {title}
      </Heading>
    </Flex>
  )
}
