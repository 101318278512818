import { FlowAuthenticator } from 'letter-sdk'
import { FlowWalletService } from 'letter-sdk/dist/utils/FlowAdapter'
import { ReactNode } from 'react'

export enum EFlowStatus {
  starting = 'starting',
  started = 'started',
}

export interface FlowContextData {
  status: EFlowStatus
  services: FlowWalletService[]
  connect(service?: FlowWalletService): Promise<void>
  disconnect(): Promise<void>
  getAuthenticator(): FlowAuthenticator
}

export type FlowContextProps = {
  children: ReactNode
}
