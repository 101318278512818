import { IconButton as ChakraIconButton, IconButtonProps as ChakraIconButtonProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

type Props = ChakraIconButtonProps & {
  isRounded?: boolean
}

export const IconButton = forwardRef<HTMLButtonElement, Props>(({ isRounded, ...props }, ref) => {
  return (
    <ChakraIconButton
      backgroundColor="transparent"
      borderRadius={isRounded ? 'full' : '2px'}
      _hover={{ backgroundColor: 'letter.button.hover' }}
      ref={ref}
      {...props}
    />
  )
})
