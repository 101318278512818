import { Flex, Spinner } from '@chakra-ui/react'
import { ReactNode } from 'react'

import background from '../assets/images/letter_logo-background.svg'
import { Footer } from '../components/footer/Footer'
import { Header } from '../components/header/Header'
import type { TStaticCategory } from '../types/pages/category'

type Props = {
  children: ReactNode
  staticCategory?: TStaticCategory
  loading?: boolean
  altBackground?: boolean
}

export const MainTemplate = ({ children, staticCategory, loading, altBackground }: Props) => {
  return (
    <Flex
      minHeight="100vh"
      direction="column"
      backgroundColor={staticCategory?.theme.surface.main ?? 'surface.main'}
      color={staticCategory?.theme.onSurface.main ?? 'onSurface.main'}
    >
      <Header />
      <Flex
        direction="column"
        grow={1}
        as="main"
        backgroundImage={altBackground ? { base: 'none', md: `url(${background})` } : undefined}
      >
        {loading ? (
          <Flex justifyContent="center" alignItems="center" grow={1}>
            <Spinner size="xl" />
          </Flex>
        ) : (
          children
        )}
      </Flex>

      <Footer staticCategory={staticCategory} />
    </Flex>
  )
}
