import copyToClipboard from 'copy-to-clipboard'
import { useCallback } from 'react'

import { ClipboardDefaultMessage } from '../components/ClipboardDefaultMessage'
import { renderToast } from '../helpers/chakraToast'

export const useClipboard = () => {
  const copy = useCallback((text: string, toastMessage?: string) => {
    copyToClipboard(text)

    renderToast({ message: toastMessage ?? ClipboardDefaultMessage })
  }, [])

  return copy
}
