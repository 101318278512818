import { Icon, IconProps } from '@chakra-ui/react'
import { memo } from 'react'

export const AddUser = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 14" fill="white" {...props}>
      <path d="M11.4545 8.18182C9.27 8.18182 4.90909 9.27 4.90909 11.4545V13.0909H18V11.4545C18 9.27 13.6391 8.18182 11.4545 8.18182ZM4.09091 4.90909V2.45455H2.45455V4.90909H0V6.54545H2.45455V9H4.09091V6.54545H6.54545V4.90909M11.4545 6.54545C12.3225 6.54545 13.155 6.20065 13.7687 5.5869C14.3825 4.97314 14.7273 4.14071 14.7273 3.27273C14.7273 2.40475 14.3825 1.57231 13.7687 0.958559C13.155 0.344804 12.3225 0 11.4545 0C10.5866 0 9.75413 0.344804 9.14038 0.958559C8.52662 1.57231 8.18182 2.40475 8.18182 3.27273C8.18182 4.14071 8.52662 4.97314 9.14038 5.5869C9.75413 6.20065 10.5866 6.54545 11.4545 6.54545Z" />
    </Icon>
  )
})
