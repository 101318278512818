import { Flex, Heading } from '@chakra-ui/react'

import { RoundedIcon } from '../RoundedIcon'

type Props = {
  icon: JSX.Element
  title: string
}

export const AboutSubHeader = ({ icon, title }: Props) => {
  return (
    <Flex
      alignItems="center"
      gridColumnGap="18px"
      borderBottom="1px solid"
      borderColor="letter.grey.dark"
      width="full"
      paddingBottom="22px"
    >
      <RoundedIcon icon={icon} size="giant" background="letter.surface.darkest" />
      <Heading as="h3" fontSize="32px" fontWeight="semibold" color="letter.onSurface.main">
        {title}
      </Heading>
    </Flex>
  )
}
