import type { TStaticCategory } from '../../types/pages/category'
import { Button, ButtonProps } from '../Button'

type Props = {
  staticCategory: TStaticCategory
} & ButtonProps

export const EventCategoryButton = ({ staticCategory, ...props }: Props) => {
  return (
    <Button
      backgroundColor={staticCategory.theme.primary}
      _hover={{ backgroundColor: staticCategory.theme.hover }}
      color={staticCategory.theme.onPrimary}
      {...props}
    />
  )
}
