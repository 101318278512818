import { Button, ButtonProps } from './Button'

export const GradientButton = (props: ButtonProps) => {
  return (
    <Button
      backgroundColor="transparent"
      background="letter.gradient.main"
      color="white"
      _active={{
        backgroundColor: 'red',
      }}
      _hover={{
        background: 'letter.gradient.secondary',
      }}
      {...props}
    />
  )
}
