import { useCallback, useMemo, useState } from 'react'

import { NEEDS_AUTHORIZATION_BY_AUTH_TYPE } from '../constants/auth'
import {
  TBuyLicense,
  TCheckAccessParams,
  TGetEventParams,
  TGetEventsByCategoryParams,
  TGetEventsByCategoryResponse,
  TGetObjectParams,
  TGetObjectResponse,
  TGetPriceLicenseParams,
  TGetPropertiesParams,
  TSignMessageParams,
  TSignMessageResponse,
} from '../types/helpers/letterSDK'
import { IUseSDK, TStatus } from '../types/hooks/useSDK'
import type { TEvent } from '../types/pages/event'
import { useMultiAuth } from './useMultiAuth'

export function useSDK(): IUseSDK {
  const { authData, getLetterSDKHelper } = useMultiAuth()

  const [status, setStatus] = useState<Partial<Record<keyof IUseSDK, TStatus>>>()

  const needsAuthorizationToWrite = useMemo(() => {
    if (!authData.isAuthenticated) return false

    return NEEDS_AUTHORIZATION_BY_AUTH_TYPE[authData.type]
  }, [authData])

  const handleStatus = useCallback((status: TStatus, key: keyof IUseSDK) => {
    setStatus(prevStatus => ({ ...prevStatus, [key]: status }))
  }, [])

  const getEvent = useCallback(
    async (params: TGetEventParams): Promise<TEvent> => {
      try {
        handleStatus('requesting', 'getEvent')

        const letterSDKHelper = await getLetterSDKHelper()

        const event = await letterSDKHelper.getEvent(params)

        handleStatus('success', 'getEvent')

        return event
      } catch (error) {
        handleStatus('error', 'getEvent')
        throw error
      }
    },
    [getLetterSDKHelper, handleStatus]
  )

  const getEventsByCategory = useCallback(
    async (params: TGetEventsByCategoryParams): Promise<TGetEventsByCategoryResponse> => {
      try {
        handleStatus('requesting', 'getEventsByCategory')

        const letterSDKHelper = await getLetterSDKHelper()

        const events = await letterSDKHelper.getEventsByCategory(params)

        handleStatus('success', 'getEventsByCategory')

        return events
      } catch (error) {
        handleStatus('error', 'getEventsByCategory')
        throw error
      }
    },
    [getLetterSDKHelper, handleStatus]
  )

  const checkAccess = useCallback(
    async (params: TCheckAccessParams): Promise<boolean> => {
      try {
        if (!authData.isAuthenticated) throw new Error('User is not authenticated')

        handleStatus('requesting', 'checkAccess')

        const letterSDKHelper = await getLetterSDKHelper()

        const hasAccess = await letterSDKHelper.checkAccess(params)

        handleStatus('success', 'checkAccess')

        return hasAccess
      } catch (error) {
        handleStatus('error', 'checkAccess')
        throw error
      }
    },
    [getLetterSDKHelper, authData, handleStatus]
  )

  const signMessage = useCallback(
    async (params: TSignMessageParams): Promise<TSignMessageResponse> => {
      try {
        if (!authData.isAuthenticated) throw new Error('User is not authenticated')

        handleStatus('requesting', 'signMessage')

        const letterSDKHelper = await getLetterSDKHelper()

        const signedMessage = await letterSDKHelper.signMessage(params)

        handleStatus('success', 'signMessage')

        return signedMessage
      } catch (error) {
        handleStatus('error', 'signMessage')
        throw error
      }
    },
    [getLetterSDKHelper, authData, handleStatus]
  )

  const buyLicense = useCallback(
    async (params: TBuyLicense) => {
      try {
        handleStatus('requesting', 'buyLicense')
        if (!authData.isAuthenticated) throw new Error('User is not authenticated')

        const letterSDKHelper = await getLetterSDKHelper()

        await letterSDKHelper.buyLicense(params)

        handleStatus('success', 'buyLicense')
      } catch (error) {
        handleStatus('error', 'buyLicense')
        throw error
      }
    },
    [getLetterSDKHelper, authData, handleStatus]
  )

  const getNFTProperties = useCallback(
    async (params: TGetPropertiesParams) => {
      try {
        handleStatus('requesting', 'getNFTProperties')

        const letterSDKHelper = await getLetterSDKHelper()

        const response = await letterSDKHelper.getNFTProperties(params)

        handleStatus('success', 'getNFTProperties')

        return response
      } catch (error) {
        handleStatus('error', 'getNFTProperties')
        throw error
      }
    },
    [getLetterSDKHelper, handleStatus]
  )

  const getStatus = useCallback(
    (key: keyof IUseSDK) => {
      if (!status) return

      const keyStatus = status[key]

      return keyStatus
    },
    [status]
  )

  const getObject = useCallback(
    async (params: TGetObjectParams): Promise<TGetObjectResponse> => {
      try {
        handleStatus('requesting', 'getObject')

        const letterSDKHelper = await getLetterSDKHelper()

        const object = await letterSDKHelper.getObject(params)

        handleStatus('success', 'getObject')

        return object
      } catch (error: any) {
        handleStatus('error', 'getObject')
        throw error
      }
    },
    [getLetterSDKHelper, handleStatus]
  )

  const getPrice = useCallback(
    async (params: TGetPriceLicenseParams) => {
      try {
        handleStatus('requesting', 'getPrice')

        const letterSDKHelper = await getLetterSDKHelper()
        const price = await letterSDKHelper.getPrice(params)

        handleStatus('success', 'getPrice')

        return price
      } catch (error: any) {
        handleStatus('error', 'getPrice')
        throw error
      }
    },
    [getLetterSDKHelper, handleStatus]
  )

  return {
    checkAccess,
    getEventsByCategory,
    getEvent,
    signMessage,
    getStatus,
    needsAuthorizationToWrite,
    buyLicense,
    getNFTProperties,
    getObject,
    getPrice,
  }
}
