import { Box, Flex, Text } from '@chakra-ui/react'

import { PresenterIcon } from '../../assets/components/PresenterIcon'
import { EventHelper } from '../../helpers/event'
import type { TStaticCategory } from '../../types/pages/category'
import type { TEvent } from '../../types/pages/event'
import { SectionHeader } from '../SectionHeader'
import { EventPresenter } from './EventPresenter'
import { EventPresentersComingSoon } from './EventPresentersComingSoon'

type Props = {
  event: TEvent
  staticCategory: TStaticCategory
}

export const EventPresenters = ({ staticCategory, event }: Props) => {
  const isComingSoon = EventHelper.isComingSoon(event)

  return (
    <>
      {(isComingSoon || (event.presenters && event.presenters.length > 0)) && (
        <Box>
          <SectionHeader
            title="PRESENTERS"
            icon={<PresenterIcon />}
            iconColor={staticCategory.theme.onSurface.emphasis}
          />

          {event.presentersTitle && (
            <Text marginTop="8px" fontSize="18px">
              {event.presentersTitle}
            </Text>
          )}

          <Flex direction="column" marginTop="24px" gap="22px">
            {!isComingSoon ? (
              <>
                {!!event.presenters &&
                  event.presenters.map((presenter, index) => (
                    <EventPresenter
                      name={presenter.name}
                      staticCategory={staticCategory}
                      caption={presenter.caption}
                      description={presenter.description}
                      image={presenter.imageURL}
                      key={'presenter-' + index}
                    />
                  ))}
              </>
            ) : (
              <EventPresentersComingSoon staticCategory={staticCategory} />
            )}
          </Flex>
        </Box>
      )}
    </>
  )
}
