import { Icon, IconProps } from '@chakra-ui/react'
import { memo } from 'react'

export const GradientUserPay = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 45 32" {...props}>
      <path
        d="M30 16C30 13.4024 32.1 11.2941 34.6875 11.2941C37.275 11.2941 39.375 13.4024 39.375 16C39.375 18.5976 37.275 20.7059 34.6875 20.7059C32.1 20.7059 30 18.5976 30 16ZM24.375 0V32H45V0H24.375ZM41.25 24.4706C39.1875 24.4706 37.5 26.1647 37.5 28.2353H31.875C31.875 26.1647 30.2063 24.4706 28.125 24.4706V7.52941C30.2063 7.52941 31.875 5.85412 31.875 3.76471H37.5C37.5 5.85412 39.1875 7.52941 41.25 7.52941V24.4706ZM13.125 5.64706C15.1875 5.64706 16.875 7.34118 16.875 9.41176C16.875 11.4824 15.1875 13.1765 13.125 13.1765C11.0625 13.1765 9.375 11.4824 9.375 9.41176C9.375 7.34118 11.0625 5.64706 13.125 5.64706ZM13.125 1.88235C8.98125 1.88235 5.625 5.25176 5.625 9.41176C5.625 13.5718 8.98125 16.9412 13.125 16.9412C17.2688 16.9412 20.625 13.5718 20.625 9.41176C20.625 5.25176 17.2688 1.88235 13.125 1.88235ZM13.125 20.7059C5.86875 20.7059 0 24.0753 0 28.2353V32H20.625V28.2353H3.75C3.75 27.1435 7.03125 24.4706 13.125 24.4706C16.5563 24.4706 19.0688 25.3176 20.625 26.2588V22.0612C18.5063 21.2141 15.9375 20.7059 13.125 20.7059Z"
        fill="url(#paint0_linear_1101_4639)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1101_4639"
          x1="-4.51226e-07"
          y1="8.68217"
          x2="33.6063"
          y2="43.1189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072" />
          <stop offset="1" stopColor="#370097" />
        </linearGradient>
      </defs>
    </Icon>
  )
})
