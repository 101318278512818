import { Text } from '@chakra-ui/react'

import { GradientCheckAltIcon } from '../../assets/components/CheckAltIcon'
import { DiscordSignTemplateModal } from './DiscordSignTemplateModal'

type Props = {
  isOpen: boolean
  onClose(): void
}

export const DiscordSignSuccessModal = ({ isOpen, onClose }: Props) => {
  return (
    <DiscordSignTemplateModal
      isOpen={isOpen}
      onClose={onClose}
      icon={<GradientCheckAltIcon fill="letter.gradient.main" strokeWidth="5px" boxSize="90px" />}
    >
      <Text fontSize="16px" fontWeight="semibold">
        Request has now been authorized.
      </Text>

      <Text fontSize="16px" marginTop="28px">
        You can now go back to discord and get started!
      </Text>
    </DiscordSignTemplateModal>
  )
}
