import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const AppleCalendarIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 19 19" fill="none" {...props}>
      <path
        d="M15.0466 18.2402H3.43383C1.67011 18.2402 0.240234 16.8104 0.240234 15.0466V3.43383C0.240234 1.67011 1.67011 0.240234 3.43383 0.240234H15.0466C16.8104 0.240234 18.2402 1.67011 18.2402 3.43383V15.0466C18.2402 16.8104 16.8104 18.2402 15.0466 18.2402Z"
        fill="#FEFDEF"
      />
      <path
        d="M16.9746 7.41797V8.54297"
        stroke="#1F212B"
        strokeWidth="0.18"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9746 6.29492V6.85742"
        stroke="#1F212B"
        strokeWidth="0.18"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9741 9.38867V14.6399C16.9741 15.9294 15.9287 16.9748 14.6392 16.9748H3.84031C2.55078 16.9748 1.50537 15.9294 1.50537 14.6399V6.29492"
        stroke="#1F212B"
        strokeWidth="0.18"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1465 0.246094H3.33398C1.62539 0.246094 0.240234 1.63125 0.240234 3.33984V5.16797H18.2402V3.33984C18.2402 1.63125 16.8551 0.246094 15.1465 0.246094Z"
        fill="#E85654"
      />
      <path
        d="M15.0466 18.2402H3.43383C1.67011 18.2402 0.240234 16.8104 0.240234 15.0466V3.43383C0.240234 1.67011 1.67011 0.240234 3.43383 0.240234H15.0466C16.8104 0.240234 18.2402 1.67011 18.2402 3.43383V15.0466C18.2402 16.8104 16.8104 18.2402 15.0466 18.2402Z"
        stroke="#1F212B"
        strokeWidth="0.36"
        strokeMiterlimit="10"
      />
      <path
        d="M9.09961 5.16797H17.959"
        stroke="#1F212B"
        strokeWidth="0.18"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25537 5.16797H5.44287"
        stroke="#1F212B"
        strokeWidth="0.18"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.19336 5.16797H0.240234"
        stroke="#1F212B"
        strokeWidth="0.18"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.59961 5.16797H4.31836"
        stroke="#1F212B"
        strokeWidth="0.18"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.79932 3.3435C6.79932 3.29653 6.81 3.26419 6.83138 3.24562C6.85275 3.22734 6.88482 3.21834 6.92757 3.21834C7.01307 3.21834 7.05582 3.24787 7.05582 3.30694C7.05582 3.41466 7.09097 3.50212 7.16129 3.56934C7.2316 3.63656 7.32975 3.67003 7.45575 3.67003C7.72435 3.67003 7.85879 3.52575 7.85879 3.23634V1.88409H7.46194C7.43325 1.88409 7.4116 1.87312 7.39613 1.85062C7.38094 1.82841 7.37335 1.8 7.37335 1.76541C7.37335 1.69003 7.40288 1.65234 7.46194 1.65234H7.99913C8.0295 1.65234 8.0565 1.6605 8.07985 1.67681C8.10319 1.69312 8.115 1.71563 8.115 1.74403V3.23662C8.115 3.46697 8.05538 3.63516 7.93641 3.74203C7.81744 3.84863 7.66022 3.90234 7.46475 3.90234C7.26535 3.90234 7.10447 3.85088 6.98241 3.74794C6.86035 3.64528 6.79932 3.51056 6.79932 3.3435Z"
        fill="#FEFDEF"
      />
      <path
        d="M8.6792 3.23662V1.74094C8.6792 1.71225 8.69186 1.69059 8.71745 1.67512C8.74276 1.65994 8.77286 1.65234 8.80745 1.65234C8.84204 1.65234 8.87214 1.65994 8.89745 1.67512C8.92276 1.69059 8.93542 1.71225 8.93542 1.74094V3.23662C8.93542 3.52603 9.07182 3.67031 9.34464 3.67031C9.47682 3.67031 9.57864 3.63431 9.64979 3.56203C9.72095 3.48975 9.75667 3.38147 9.75667 3.23662V1.74094C9.75667 1.68187 9.79942 1.65234 9.88492 1.65234C9.91951 1.65234 9.94961 1.65994 9.97492 1.67512C10.0005 1.69059 10.0132 1.71225 10.0132 1.74094V3.23662C10.0132 3.46697 9.95214 3.63516 9.83007 3.74203C9.70801 3.84863 9.54629 3.90234 9.34464 3.90234C9.14298 3.90234 8.98182 3.84863 8.86089 3.74203C8.73967 3.63516 8.6792 3.46697 8.6792 3.23662Z"
        fill="#FEFDEF"
      />
      <path
        d="M10.646 3.79237V1.74094C10.646 1.71225 10.6587 1.69059 10.684 1.67512C10.7096 1.65994 10.7394 1.65234 10.774 1.65234C10.8086 1.65234 10.8387 1.65994 10.864 1.67512C10.8896 1.69059 10.9022 1.71225 10.9022 1.74094V3.65175H11.7356C11.762 3.65175 11.7825 3.66356 11.7966 3.68691C11.8109 3.71025 11.818 3.73725 11.818 3.76791C11.818 3.79828 11.8107 3.82528 11.7966 3.84863C11.7822 3.87225 11.762 3.88378 11.7356 3.88378H10.7616C10.7312 3.88378 10.7039 3.87563 10.6809 3.85931C10.6575 3.84328 10.646 3.82078 10.646 3.79237Z"
        fill="#FEFDEF"
      />
      <path
        d="M5.80029 8.82533C5.80029 8.74377 5.84473 8.6473 5.93389 8.53564L6.75851 7.17636C6.82545 7.05767 6.91461 6.99805 7.02598 6.99805C7.18911 6.99805 7.27095 7.07623 7.27095 7.23205V14.8981C7.27095 15.0615 7.17786 15.143 6.99251 15.143C6.80661 15.143 6.71407 15.0615 6.71407 14.8981V8.13486L6.24607 8.93727C6.20136 9.03402 6.1347 9.08211 6.04554 9.08211C5.97861 9.08211 5.92123 9.05595 5.87286 9.00392C5.8242 8.95189 5.80029 8.89227 5.80029 8.82533Z"
        fill="#1F212B"
      />
      <path
        d="M8.68652 8.90351V7.2433C8.68652 7.18395 8.71437 7.12827 8.77005 7.07623C8.82574 7.0242 8.88705 6.99805 8.95371 6.99805H12.9092C12.9686 6.99805 13.0282 7.0242 13.0875 7.07623C13.1471 7.12827 13.177 7.18395 13.177 7.2433V8.11236C13.177 8.1793 13.1545 8.24595 13.1097 8.31289L9.66724 15.0207C9.61662 15.1099 9.55137 15.1543 9.47177 15.1543C9.38402 15.1543 9.30752 15.1304 9.24199 15.082C9.17618 15.0339 9.14355 14.9726 9.14355 14.8984C9.14355 14.8761 9.15818 14.8278 9.18799 14.7535L12.6198 8.10139V7.53327H9.24368V8.9038C9.24368 8.97045 9.21584 9.02811 9.16015 9.07648C9.10446 9.12486 9.03949 9.14877 8.96524 9.14877C8.89099 9.14877 8.82602 9.12486 8.77034 9.07648C8.71437 9.02811 8.68652 8.97017 8.68652 8.90351Z"
        fill="#1F212B"
      />
    </Icon>
  );
});
