import { Box, Flex, Hide, Image, Show } from '@chakra-ui/react'

import { EventAccessDetails } from '../components/event/EventAccessDetails'
import { EventCalendarButtons } from '../components/event/EventCalendarButtons'
import { EventDetails } from '../components/event/EventDetails'
import { EventExclusiveAccessCard } from '../components/event/EventExclusiveAccessCard'
import { EventInfo } from '../components/event/EventInfo'
import { EventSidebar } from '../components/event/EventSidebar'
import {
  PaymentStripeModal,
  TStripeGetClientSecret,
  TStripeOnPay,
} from '../components/event/PaymentStripeModal/PaymentStripeModal'
import { PaymentWithFlowModal, TPaymentWithFlowOnPay } from '../components/event/PaymentWithFlowModal'
import { useMultiAuth } from '../hooks/useMultiAuth'
import { MainTemplate } from '../templates/Main'
import { TAuthType } from '../types/contexts/auth'
import type { TStaticCategory } from '../types/pages/category'
import type { TEvent } from '../types/pages/event'

type Props = {
  event?: TEvent
  eventPrice?: number
  staticCategory: TStaticCategory
  loading: boolean
  hasAccess?: boolean
  isPayed: boolean
  isPaying: boolean

  paymentModalIsOpen: boolean
  onClosePaymentModal: () => void
  onOpenPaymentModal: () => void

  showPaymentSignMessageAlert: boolean

  onStripePay: TStripeOnPay
  onStripeGetClientSecret: TStripeGetClientSecret

  onPayWithFlow: TPaymentWithFlowOnPay
}

export const EventLayout = ({
  loading,
  staticCategory,
  event,
  eventPrice,
  hasAccess,
  onClosePaymentModal,
  onOpenPaymentModal,
  paymentModalIsOpen,
  showPaymentSignMessageAlert,
  onStripePay,
  onStripeGetClientSecret,
  onPayWithFlow,
  isPayed,
  isPaying,
}: Props) => {
  const { authData } = useMultiAuth()

  return (
    <MainTemplate staticCategory={staticCategory} loading={loading}>
      {event && eventPrice && (
        <>
          <Flex position="relative" justifyContent="center">
            <Image
              src={event.backgroundImage ?? event.thumbnailImage}
              position="absolute"
              zIndex={1}
              width="full"
              height="full"
              objectFit="cover"
            />

            {!event.disableBackgroundDarkLayer && (
              <Box position="absolute" width="full" height="full" zIndex={2} backgroundColor="letter.box.500" />
            )}

            <EventInfo event={event} hasAccess={hasAccess} staticCategory={staticCategory} />
          </Flex>

          <Flex width="full" justifyContent="center" id="EventInfo">
            <Flex
              width="100%"
              maxWidth="2178px"
              paddingX={{ base: '16px', md: '32px', lg: 'min(154px, 8%)' }}
              marginTop="42px"
              marginBottom="64px"
              justifyContent={{ base: 'flex-start', xl: 'flex-end' }}
              alignItems={{ base: 'center', xl: 'flex-start' }}
              gridColumnGap="54px"
              direction={{ base: 'column', xl: 'row' }}
              gridRowGap={{ base: '20px', xl: '0px' }}
            >
              <Hide above="xl">
                {!hasAccess && (
                  <EventExclusiveAccessCard
                    staticCategory={staticCategory}
                    onGetAccess={onOpenPaymentModal}
                    event={event}
                  />
                )}

                <EventAccessDetails event={event} staticCategory={staticCategory} hasAccess={hasAccess} />
              </Hide>

              <EventDetails event={event} staticCategory={staticCategory} />

              <Show above="xl">
                <EventSidebar
                  staticCategory={staticCategory}
                  event={event}
                  hasAccess={hasAccess}
                  onGetAccess={onOpenPaymentModal}
                />
              </Show>

              <Hide above="xl">
                {event.calendarButtons && (
                  <EventCalendarButtons buttons={event.calendarButtons} staticCategory={staticCategory} />
                )}
              </Hide>
            </Flex>
          </Flex>

          {authData.isAuthenticated &&
            (authData.type === TAuthType.flow ? (
              <PaymentWithFlowModal
                event={event}
                staticCategory={staticCategory}
                isOpen={paymentModalIsOpen}
                onClose={onClosePaymentModal}
                onPay={onPayWithFlow}
                showSignMessageAlert={showPaymentSignMessageAlert}
                isPayed={isPayed}
                isPaying={isPaying}
                eventPrice={eventPrice}
              />
            ) : (
              <PaymentStripeModal
                event={event}
                eventPrice={eventPrice}
                staticCategory={staticCategory}
                isOpen={paymentModalIsOpen}
                onClose={onClosePaymentModal}
                onPay={onStripePay}
                onGetClientSecret={onStripeGetClientSecret}
                showSignMessageAlert={showPaymentSignMessageAlert}
                isPayed={isPayed}
                isPaying={isPaying}
              />
            ))}
        </>
      )}
    </MainTemplate>
  )
}
