import { Flex } from '@chakra-ui/react'

import { UserShieldIcon } from '../../assets/components/UserShieldIcon'
import type { TStaticCategory } from '../../types/pages/category'
import { EventPresenter } from './EventPresenter'

type Props = {
  staticCategory: TStaticCategory
}

const EventPresenterComingSoon = ({ staticCategory }: Props) => {
  return (
    <EventPresenter
      name="TBC"
      staticCategory={staticCategory}
      caption={staticCategory.presenterCaptionPlaceholder}
      image={
        <Flex
          borderRadius="6px"
          width="92px"
          height="92px"
          minWidth="92px"
          justifyContent="center"
          alignItems="center"
          minHeight="92px"
          objectFit="cover"
          backgroundColor={staticCategory.theme.brand}
        >
          <UserShieldIcon boxSize="46px" />
        </Flex>
      }
    />
  )
}

export const EventPresentersComingSoon = (props: Props) => {
  return (
    <>
      <EventPresenterComingSoon {...props} />
      <EventPresenterComingSoon {...props} />
      <EventPresenterComingSoon {...props} />
    </>
  )
}
