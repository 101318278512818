import { TCategoryTheme } from '../../../types/pages/category'

export const APTop25light: TCategoryTheme = {
  primary: '#E71E29',
  primary400: '#E71E2966',
  onPrimary: '#FFFFFF',
  hover: '#AD171F',
  brand: '#FF322E',

  surface: {
    lightest: '#303135',
    light: '#2F2F34',
    main: '#0F0F14',
    dark: '#12171C',
  },

  onSurface: {
    main: '#FFFFFF',
    emphasis: '#E71E29',
    disable: '#57575B',
  },
}
