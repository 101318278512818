import { Icon, IconProps } from '@chakra-ui/react'

export const DownloadIcon = ({ fill, ...props }: IconProps) => {
  return (
    <Icon viewBox="0 0 16 20" fill={fill === 'letter.gradient.main' ? 'url(#paint0_linear_169_4354)' : fill} {...props}>
      <path d="M10 0L16 6V18C16 18.5304 15.7893 19.0391 15.4142 19.4142C15.0391 19.7893 14.5304 20 14 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H10ZM14 18V7H9V2H2V18H14ZM8 17L4 13H6.5V10H9.5V13H12L8 17Z" />
      <defs>
        <linearGradient
          id="paint0_linear_169_4354"
          x1="-5.2093"
          y1="2.4031"
          x2="18.9971"
          y2="8.99591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#26B9FF" />
          <stop offset="1" stopColor="#A3008E" />
        </linearGradient>
      </defs>
    </Icon>
  )
}
