import { Box, Flex, RenderProps } from '@chakra-ui/react'

export const Toast = ({ title, status }: RenderProps) => {
  return (
    <Flex
      background={status === 'error' ? 'letter.pink.light' : 'letter.gradient.dark'}
      color="letter.onBox.main"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Box textTransform="uppercase" fontSize="16px" fontWeight="medium">
        {title}
      </Box>
    </Flex>
  )
}
