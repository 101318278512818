import { Flex, FlexProps } from '@chakra-ui/react'
import { cloneElement } from 'react'

export type TRoundedIconSize = 'normal' | 'bigger' | 'big' | 'giant'

type Props = FlexProps & {
  icon: JSX.Element
  size?: TRoundedIconSize
  background?: string
  iconColor?: string
}

export const RoundedIcon = ({ icon, size = 'big', background, iconColor, ...props }: Props) => {
  const normalStyle: FlexProps =
    size === 'normal'
      ? {
          width: '20px',
          height: '20px',
          minWidth: '20px',
        }
      : {}

  const bigStyle: FlexProps =
    size === 'big'
      ? {
          width: '30px',
          height: '30px',
          minWidth: '30px',
        }
      : {}

  const biggerStyle: FlexProps =
    size === 'bigger'
      ? {
          width: '38px',
          height: '38px',
          minWidth: '38px',
        }
      : {}

  const giantStyle: FlexProps =
    size === 'giant'
      ? {
          width: '88px',
          height: '88px',
          minWidth: '88px',
        }
      : {}

  const iconStyle = {
    width: '50%',
    height: '50%',
    fill: iconColor ?? 'letter.onBox.main',
  }

  return (
    <Flex
      background={background ?? 'letter.box.main'}
      borderRadius="full"
      alignItems="center"
      justifyContent="center"
      {...normalStyle}
      {...bigStyle}
      {...biggerStyle}
      {...giantStyle}
      {...props}
    >
      {cloneElement(icon, iconStyle)}
    </Flex>
  )
}
