import { Icon, IconProps } from '@chakra-ui/react'
import { memo } from 'react'

export const GradientUserIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 30 30" {...props}>
      <path
        d="M26.4375 26.6875H26.6875V26.4375V24.375C26.6875 24.1298 26.5431 23.8965 26.36 23.6937C26.1696 23.4829 25.9001 23.2645 25.5723 23.0456C24.9151 22.6069 23.9897 22.1444 22.897 21.723C20.7115 20.8801 17.8214 20.1875 15 20.1875C12.1786 20.1875 9.28845 20.8801 7.103 21.723C6.01035 22.1444 5.08485 22.6069 4.4277 23.0456C4.09985 23.2645 3.83042 23.4829 3.64003 23.6937C3.45688 23.8965 3.3125 24.1298 3.3125 24.375V26.4375V26.6875H3.5625H26.4375ZM19.1875 7.5C19.1875 5.18693 17.3131 3.3125 15 3.3125C12.6869 3.3125 10.8125 5.18693 10.8125 7.5C10.8125 9.81307 12.6869 11.6875 15 11.6875C17.3131 11.6875 19.1875 9.81307 19.1875 7.5ZM7.75 7.5C7.75 3.49432 10.9943 0.25 15 0.25C19.0057 0.25 22.25 3.49432 22.25 7.5C22.25 11.5057 19.0057 14.75 15 14.75C10.9943 14.75 7.75 11.5057 7.75 7.5ZM0.25 24.375C0.25 23.2199 0.826628 22.1897 1.8093 21.2827C2.79368 20.3742 4.16877 19.6064 5.71562 18.9868C8.80973 17.7473 12.5281 17.125 15 17.125C17.4719 17.125 21.1903 17.7473 24.2844 18.9868C25.8312 19.6064 27.2063 20.3742 28.1907 21.2827C29.1734 22.1897 29.75 23.2199 29.75 24.375V29.75H0.25V24.375Z"
        fill="url(#paint0_linear_1101_4638)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1101_4638"
          x1="-3.00817e-07"
          y1="8.13953"
          x2="30"
          y2="30"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072" />
          <stop offset="1" stopColor="#370097" />
        </linearGradient>
      </defs>
    </Icon>
  )
})
