import { createContext, useCallback, useState } from 'react'

import { AuthContextData, AuthProps, TAuthAuthenticatedAllData, TAuthData } from '../types/contexts/auth'

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider = ({ children }: AuthProps) => {
  const [data, setData] = useState<TAuthData>({ isAuthenticated: false })

  const validate = useCallback(async (authenticatedData: TAuthAuthenticatedAllData) => {
    setData({ isAuthenticated: true, ...authenticatedData })
  }, [])

  const revoke = useCallback(async () => {
    setData({ isAuthenticated: false })
  }, [])

  return (
    <AuthContext.Provider
      value={{
        data,
        validate,
        revoke,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
