import { Box, Flex, Image, Text } from '@chakra-ui/react'

import dapperLogo from '../../assets/images/dapper-logo.png'
import { GradientButton } from '../GradientButton'
import { PaymentGenericModal, TPaymentGenericModalProps } from './PaymentGenericModal/PaymentGenericModal'

export type TPaymentWithFlowOnPay = () => Promise<void>

type Props = Omit<TPaymentGenericModalProps, 'children' | 'disableClose' | 'showSpinner'> & {
  onPay: TPaymentWithFlowOnPay
}

export const PaymentWithFlowModal = ({ isOpen, onPay, onClose, isPaying, ...props }: Props) => {
  return (
    <PaymentGenericModal onClose={onClose} isOpen={isOpen} isPaying={isPaying} {...props}>
      <Box height="full">
        <Flex direction="column" marginY="84px" alignItems="center">
          <Image src={dapperLogo} width="248px" objectFit="contain" marginBottom="36px" />
          <Text fontSize="14px" fontWeight="bold" color="letter.gray.dark">
            Pay with your Dapper wallet
          </Text>
        </Flex>
        <GradientButton label="PAY" full onClick={onPay} isLoading={isPaying} />
      </Box>
    </PaymentGenericModal>
  )
}
