import { Box, Collapse, Divider, Flex, Heading, Text, useDisclosure } from '@chakra-ui/react'
import { cloneElement } from 'react'

import { IconButton } from '../IconButton'

export type NFTDetailsProperties = {
  name: string
  value?: string | number | boolean
  isCollapsible?: boolean
  isBold?: boolean
}

type Props = {
  icon: JSX.Element
  title: string
  properties: NFTDetailsProperties[]
}

export const NFTDetailsPropertiesBox = ({ icon, properties, title }: Props) => {
  const { isOpen, onToggle } = useDisclosure()
  return (
    <Box color="letter.onSurface.main">
      <Flex columnGap="8px">
        {cloneElement(icon, { boxSize: { base: '18px', md: '20px' }, fill: 'letter.onSurface.main' })}

        <Heading as="h4" fontSize="16px">
          {title}
        </Heading>
      </Flex>

      <Box padding="18px" borderRadius="6px" border="1px solid" borderColor="letter.onSurface.divider" marginTop="16px">
        {properties.map(({ name, value, isBold, isCollapsible }, index) => (
          <>
            {value && (
              <Box key={name}>
                <Flex justifyContent="space-between" paddingX={{ base: 'unset', md: '16px' }} marginBottom="4px">
                  <Text fontSize={{ base: '16px', md: '14px' }} fontWeight="bold">
                    {name}
                  </Text>

                  {isCollapsible && (
                    <IconButton
                      aria-label="Expand metadata"
                      icon={<Box background="letter.gradient.dark" height="4px" width="20px" />}
                      size="sm"
                      onClick={onToggle}
                    />
                  )}
                </Flex>

                {isCollapsible ? (
                  <Collapse in={isOpen}>
                    <Text
                      fontSize="18px"
                      textAlign={{ base: 'left', md: 'right' }}
                      fontWeight={isBold ? 'bold' : 'normal'}
                      paddingX={{ base: 'unset', md: '16px' }}
                    >
                      {String(value)}
                    </Text>
                  </Collapse>
                ) : (
                  <Text
                    fontSize="18px"
                    textAlign={{ base: 'left', md: 'right' }}
                    fontWeight={isBold ? 'bold' : 'normal'}
                    paddingX={{ base: 'unset', md: '16px' }}
                  >
                    {String(value)}
                  </Text>
                )}

                {index !== properties.length - 1 && properties.slice(index + 1).some(({ value }) => !!value) && (
                  <Divider marginY="8px" />
                )}
              </Box>
            )}
          </>
        ))}
      </Box>
    </Box>
  )
}
