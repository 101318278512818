import axios from 'axios'
import { useCallback, useEffect, useReducer } from 'react'

import {
  ERequestStatus,
  ERequestType,
  TAction,
  TEagerFetchOptions,
  TLazyFetchData,
  TLazyFetchOptions,
  TState,
} from '../types/hooks/useFetch'

function useFetchReducer<T>() {
  const initialState: TState<T> = {
    status: ERequestStatus.init,
  }

  const fetchReducer = (state: TState<T>, action: TAction<T>): TState<T> => {
    switch (action.type) {
      case ERequestType.request:
        return {
          status: ERequestStatus.fetching,
        }
      case ERequestType.success:
        return {
          data: action.payload,
          status: ERequestStatus.fetched,
        }
      case ERequestType.failure:
        return {
          status: ERequestStatus.error,
          error: action.error,
        }
      default:
        return state
    }
  }

  const [state, dispatchReducer] = useReducer(fetchReducer, initialState)

  return [state, dispatchReducer] as const
}

export function useEagerFetch<T = Record<string, unknown>>({ endpoint, method, options }: TEagerFetchOptions) {
  const [stateReducer, dispatchReducer] = useFetchReducer<T>()

  useEffect(() => {
    dispatchReducer({ type: ERequestType.request })

    const cancelToken = axios.CancelToken.source()

    axios(endpoint, { cancelToken: cancelToken.token, method, ...options })
      .then(response => dispatchReducer({ type: ERequestType.success, payload: response.data }))
      .catch((error: any) =>
        dispatchReducer({
          type: ERequestType.failure,
          error,
        })
      )

    return () => {
      cancelToken.cancel()
    }
  }, [dispatchReducer, endpoint, options, method])

  return stateReducer
}

export function useLazyFetch<T = Record<string, unknown>>({ endpoint, method, options }: TLazyFetchOptions) {
  const [stateReducer, dispatchReducer] = useFetchReducer<T>()

  const dispatchFetch = useCallback(
    async (data?: TLazyFetchData) => {
      try {
        dispatchReducer({ type: ERequestType.request })

        const response = await axios<T>(endpoint, {
          method,
          ...options,
          ...data,
        })

        dispatchReducer({
          type: ERequestType.success,
          payload: response.data,
        })

        return response
      } catch (error: any) {
        dispatchReducer({
          type: ERequestType.failure,
          error,
        })
        throw error
      }
    },
    [dispatchReducer, endpoint, options, method]
  )

  return [dispatchFetch, stateReducer] as const
}
