import { Flex, FlexProps, Image, Text } from '@chakra-ui/react'

type Props = {
  title: string
  imageURL?: string
} & FlexProps

export const EventPartner = ({ title, imageURL, ...props }: Props) => {
  return (
    <>
      {(imageURL || title) && (
        <Flex maxWidth={{ base: '190px', md: '145px' }} direction="column" alignItems="center" {...props}>
          <Text fontSize="12px" fontWeight="700">
            IN PARTNERSHIP WITH
          </Text>
          {imageURL ? (
            <Image src={imageURL} width="100%" objectFit="contain" alt={title} />
          ) : (
            <Text fontSize="18px" fontWeight="bold" textTransform="uppercase">
              {title}
            </Text>
          )}
        </Flex>
      )}
    </>
  )
}
