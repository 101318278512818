import { Icon, IconProps } from '@chakra-ui/react'
import { memo } from 'react'

export const DiscordAltIcon = memo(({ fill, ...props }: IconProps) => {
  return (
    <Icon viewBox="0 0 18 13" fill={fill === 'letter.gradient.main' ? 'url(#paint0_linear_693_5)' : fill} {...props}>
      <path d="M6.54609 0C4.22049 0.222528 2.45391 1.60881 2.45391 1.60881C2.45391 1.60881 0.36 4.61855 0 10.4976C2.1168 12.9095 5.31914 12.9231 5.31914 12.9231L5.98359 12.0398C5.30499 11.8065 4.72657 11.5072 4.13437 11.0514L4.25742 10.6869C5.36442 11.1911 6.786 11.486 9 11.486C11.214 11.486 12.6356 11.1893 13.7426 10.6869L13.8656 11.0514C13.2716 11.509 12.6932 11.8083 12.0164 12.0398L12.6809 12.9231C12.6809 12.9231 15.8832 12.9095 18 10.4976C17.64 4.61855 15.5461 1.60881 15.5461 1.60881C15.5461 1.60881 13.6715 0.165101 11.4539 0L10.9969 0.93234C10.3183 0.808514 9.6102 0.718533 9 0.718533C8.37 0.718533 7.66209 0.803467 6.99609 0.91832L6.54609 0ZM5.75859 5.10334C6.55059 5.10334 7.2 5.90731 7.2 6.89791C7.2 7.88852 6.55059 8.6925 5.75859 8.6925C4.96659 8.6925 4.3207 7.88852 4.3207 6.89791C4.3207 5.90731 4.96659 5.10334 5.75859 5.10334ZM12.2414 5.10334C13.0334 5.10334 13.6793 5.90731 13.6793 6.89791C13.6793 7.88852 13.0334 8.6925 12.2414 8.6925C11.4494 8.6925 10.8 7.88852 10.8 6.89791C10.8 5.90731 11.4494 5.10334 12.2414 5.10334Z" />
      <defs>
        <linearGradient
          id="paint0_linear_693_5"
          x1="-1.8049e-07"
          y1="3.50626"
          x2="13.5743"
          y2="17.2835"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072" />
          <stop offset="1" stopColor="#370097" />
        </linearGradient>
      </defs>
    </Icon>
  )
})
