import { Box, BoxProps, Link, ListItem, OrderedList, Text } from '@chakra-ui/react'
import { NavLink as RouterLink } from 'react-router-dom'

type Item = {
  to: string
  label: string | JSX.Element
}

type Props = {
  items: Item[]
} & BoxProps

export const Breadcrumb = ({ items, ...props }: Props) => {
  return (
    <Box as="nav" {...props}>
      <OrderedList
        display="flex"
        fontSize="14px"
        fontWeight="600"
        textTransform="uppercase"
        listStyleType="none"
        margin="0"
      >
        {items.map((item, index) => (
          <ListItem display="flex" key={`breadcrumb-item-${index}`}>
            {index !== 0 && (
              <Text as="span" marginX="12px">
                |
              </Text>
            )}

            <Link
              display="flex"
              alignItems="center"
              as={RouterLink}
              to={item.to}
              noOfLines={1}
              _hover={{
                textDecoration: 'none',
                color: 'letter.onBox.light',
                fill: 'letter.onBox.light',
              }}
            >
              {item.label}
            </Link>
          </ListItem>
        ))}
      </OrderedList>
    </Box>
  )
}
