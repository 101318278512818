import { Flex } from '@chakra-ui/react'

import type { TStaticCategory } from '../../types/pages/category'
import type { TEvent } from '../../types/pages/event'
import { EventAccessDetails } from './EventAccessDetails'
import { EventCalendarButtons } from './EventCalendarButtons'
import { EventExclusiveAccessCard } from './EventExclusiveAccessCard'

type Props = {
  event: TEvent
  staticCategory: TStaticCategory
  hasAccess?: boolean
  onGetAccess?: () => void
}

export const EventSidebar = ({ event, staticCategory, hasAccess, onGetAccess }: Props) => {
  return (
    <Flex
      direction="column"
      gridRowGap={{ base: '20px', xl: '34px' }}
      width={{ base: '100%', xl: '320px' }}
      minWidth={{ base: '100%', xl: '320px' }}
    >
      {!hasAccess && (
        <EventExclusiveAccessCard staticCategory={staticCategory} onGetAccess={onGetAccess} event={event} />
      )}

      <EventAccessDetails event={event} staticCategory={staticCategory} hasAccess={hasAccess} />

      {event.calendarButtons && (
        <EventCalendarButtons buttons={event.calendarButtons} staticCategory={staticCategory} />
      )}
    </Flex>
  )
}
