import { Box, Modal, ModalContent, ModalOverlay, SimpleGrid, Spinner, useBreakpointValue } from '@chakra-ui/react'

import { PaymentUserIcon } from '../../../assets/components/PaymentUserIcon'
import type { TStaticCategory } from '../../../types/pages/category'
import type { TEvent } from '../../../types/pages/event'
import { SectionHeader } from '../../SectionHeader'
import { SignMessageContent } from '../../SignMessageModal/SignMessageContent'
import { EventPaymentInfo } from '../EventPaymentInfo'
import { EventPaymentSuccess } from '../EventPaymentSuccess'
import { PaymentInfo } from './PaymentInfo'
import { PaymentModalHeader } from './PaymentModalHeader'

export type TPaymentGenericModalProps = {
  event: TEvent
  eventPrice: number
  staticCategory: TStaticCategory
  isOpen: boolean
  onClose: () => void
  showSignMessageAlert: boolean
  disableClose?: boolean
  showSpinner?: boolean
  children?: React.ReactNode
  isPayed?: boolean
  isPaying?: boolean
}

export const PaymentGenericModal = ({
  event,
  staticCategory,
  isOpen,
  eventPrice,
  onClose,
  showSignMessageAlert,
  disableClose,
  showSpinner,
  isPayed,
  isPaying,
  children,
}: TPaymentGenericModalProps) => {
  const modalSize = useBreakpointValue({ base: 'full', lg: '5xl' })

  return (
    <Modal size={modalSize} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay background="letter.box.800" />

      <ModalContent overflow="hidden" borderRadius={modalSize === 'full' ? 'none' : 'md'}>
        <PaymentModalHeader
          staticCategory={staticCategory}
          event={event}
          onClose={onClose}
          disableClose={disableClose ?? isPaying}
        />

        <SimpleGrid
          paddingX={{ base: '16px', md: '32px' }}
          paddingY={{ base: '32px', md: '40px' }}
          columns={{ base: 1, md: 2 }}
          spacingX="96px"
          spacingY="48px"
        >
          <PaymentInfo event={event} staticCategory={staticCategory} />

          <Box position="relative">
            {showSignMessageAlert ? (
              <SignMessageContent description="We need you this operation to verify your identity, with that, the payment will be done safely." />
            ) : showSpinner ? (
              <Box position="absolute" paddingTop="32px" top="54px" left="50%" transform="translateX(-50%)">
                <Spinner />
              </Box>
            ) : isPayed ? (
              <EventPaymentSuccess onClose={onClose} />
            ) : (
              <>
                <EventPaymentInfo title="AP Top 25 Season Pass" price={eventPrice} />
                <SectionHeader
                  title="PAYMENT DETAILS"
                  icon={<PaymentUserIcon />}
                  iconColor="letter.gray.dark"
                  marginBottom={23}
                />

                {children}
              </>
            )}
          </Box>
        </SimpleGrid>
      </ModalContent>
    </Modal>
  )
}
