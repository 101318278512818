import { useMemo } from 'react'

import { STATIC_CATEGORIES } from '../constants/categories'
import { TUseStaticCategoryProps } from '../types/hooks/useStaticCategory'
import type { TStaticCategory } from '../types/pages/category'

export const useStaticCategory = ({ categoryLabel, contentProviderLabel }: TUseStaticCategoryProps) => {
  const staticCategory = useMemo<TStaticCategory>(() => {
    if (!contentProviderLabel || !categoryLabel) throw new Error('Missing required props')

    const category = STATIC_CATEGORIES[contentProviderLabel][categoryLabel]
    if (!category) throw new Error('Category not found')

    return {
      label: categoryLabel,
      contentProviderLabel,
      ...STATIC_CATEGORIES[contentProviderLabel][categoryLabel],
    }
  }, [contentProviderLabel, categoryLabel])

  return { staticCategory }
}
