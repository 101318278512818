import { useCallback, useMemo } from 'react'

import { LetterSDKHelper } from '../helpers/letterSDK'
import { EFlowStatus } from '../types/contexts/flow'
import { ENeontatus } from '../types/contexts/neon'
import { EWalletConnectStatus } from '../types/contexts/wallet-connect'
import { EMultiAuthStatus, TMultiAuthFunctionsByType } from '../types/hooks/useMultiAuth'
import { useAuth } from './useAuth'
import { useFlow } from './useFlow'
import { useNeon } from './useNeon'
import { useWalletConnect } from './useWalletConnect'

export const useMultiAuth = () => {
  const { data: authData } = useAuth()
  const { disconnect: flowDisconnect, connect: flowConnect, status: flowStatus, services: flowServices } = useFlow()
  const {
    disconnect: walletConnectDisconnect,
    connect: walletConnectConnect,
    status: walletConnectStatus,
    getAuthenticator: getWalletConnectAuthenticator,
  } = useWalletConnect()
  const {
    disconnect: neonDisconnect,
    connect: neonConnect,
    create: neonCreate,
    status: neonStatus,
    getAuthenticator: getNeonAuthenticator,
  } = useNeon()

  const authStatus = useMemo<EMultiAuthStatus>(() => {
    if (
      flowStatus !== EFlowStatus.starting &&
      walletConnectStatus !== EWalletConnectStatus.starting &&
      neonStatus !== ENeontatus.starting
    ) {
      return EMultiAuthStatus.started
    }

    return EMultiAuthStatus.starting
  }, [flowStatus, walletConnectStatus, neonStatus])

  const disconnect = useCallback(async () => {
    if (!authData.isAuthenticated) return

    const disconnectFunctiosByType: TMultiAuthFunctionsByType = {
      flow: flowDisconnect,
      neon: neonDisconnect,
      wc: walletConnectDisconnect,
    }

    const disconnectFunction = disconnectFunctiosByType[authData.type]

    await disconnectFunction()
  }, [flowDisconnect, neonDisconnect, walletConnectDisconnect, authData])

  const getLetterSDKHelper = useCallback(async () => {
    //TODO: Uncomment when flow contract is reviewed
    // const flowAuthenticator = getFlowAuthenticator()
    const neonAuthenticator = await getNeonAuthenticator()
    const walletConnectAuthenticator = getWalletConnectAuthenticator()

    const authenticators = [neonAuthenticator]

    if (walletConnectAuthenticator) {
      authenticators.push(walletConnectAuthenticator)
    }

    return new LetterSDKHelper(authenticators)
  }, [getNeonAuthenticator, getWalletConnectAuthenticator])

  return {
    disconnect,
    flowConnect,
    walletConnectConnect,
    neonConnect,
    neonCreate,
    authData,
    authStatus,
    flowStatus,
    walletConnectStatus,
    neonStatus,
    getLetterSDKHelper,
    flowServices,
  }
}
