import { FCLAdapter, FlowAuthenticator } from 'letter-sdk'
import { FlowWalletService } from 'letter-sdk/dist/utils/FlowAdapter'
import { createContext, useCallback, useEffect, useRef, useState } from 'react'

import { NETWORK } from '../constants/blockchain'
import { useAuth } from '../hooks/useAuth'
import { TAuthType } from '../types/contexts/auth'
import { EFlowStatus, FlowContextData, FlowContextProps } from '../types/contexts/flow'

const FlowContext = createContext<FlowContextData>({} as FlowContextData)

export const FlowProvider = ({ children }: FlowContextProps) => {
  const { validate, revoke } = useAuth()

  const [status, setStatus] = useState<EFlowStatus>(EFlowStatus.starting)
  const [services, setServices] = useState<FlowWalletService[]>([])

  const adapter = useRef(new FCLAdapter(NETWORK.flowOptions.adapterOptions))
  const authenticator = useRef(new FlowAuthenticator(adapter.current, NETWORK.flowOptions.authenticatorNetwork))

  const connect = useCallback(async (service?: FlowWalletService) => {
    await adapter.current.authenticate(service)
  }, [])

  const disconnect = useCallback(async () => {
    await adapter.current.unauthenticate()
    revoke()
  }, [revoke])

  const getAuthenticator = useCallback(() => {
    return authenticator.current
  }, [])

  useEffect(() => {
    adapter.current.authSubscribe(user => {
      if (!user || !user.addr) return

      validate({ address: user.addr, type: TAuthType.flow, blockchainLabel: authenticator.current.blockchainLabel })
    })
  }, [validate])

  useEffect(() => {
    ;(async () => {
      const services = await adapter.current.getWalletServices()
      setServices(services)
      setStatus(EFlowStatus.started)
    })()
  }, [])

  return (
    <FlowContext.Provider value={{ status, connect, disconnect, getAuthenticator, services }}>
      {children}
    </FlowContext.Provider>
  )
}

export default FlowContext
