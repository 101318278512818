import { Box, SimpleGrid, Text } from '@chakra-ui/react'

import { EventHelper } from '../../helpers/event'
import { useMultiAuth } from '../../hooks/useMultiAuth'
import { EMultiAuthStatus } from '../../types/hooks/useMultiAuth'
import type { TStaticCategory } from '../../types/pages/category'
import type { TEvent } from '../../types/pages/event'
import { EventCategoryButton } from '../category/EventCategoryButton'

type Props = {
  staticCategory: TStaticCategory
  event: TEvent
  onGetAccess?: () => void
}

export const EventExclusiveAccessCard = ({ staticCategory, onGetAccess, event }: Props) => {
  const { authStatus } = useMultiAuth()

  return (
    <SimpleGrid
      backgroundColor={staticCategory.theme.surface.lightest}
      borderRadius="6px"
      padding="22px"
      columns={{ base: 1, md: 2, xl: 1 }}
      spacing="28px"
      width="full"
      alignItems="center"
    >
      <Box>
        <Text fontSize="20px" fontWeight="bold" color={staticCategory.theme.primary}>
          Get exclusive access
        </Text>

        <Text color="letter.onBox.main" fontSize="16px" fontWeight="light" noOfLines={5} marginTop="6px">
          Buy a limited edition AP Season Pass to access AP Top 25 exclusive coverage
        </Text>
      </Box>

      <EventCategoryButton
        label={event.accessLink ? 'BUY SEASON PASS' : 'COMING SOON'}
        staticCategory={staticCategory}
        full
        onClick={onGetAccess}
        disabled={authStatus === EMultiAuthStatus.starting || EventHelper.isComingSoon(event)}
      />
    </SimpleGrid>
  )
}
