import { Icon, IconProps } from '@chakra-ui/react'
import { memo } from 'react'

export const GradientCalendarCheckIcon = memo((props: IconProps) => {
  return (
    <Icon width="55" height="61" viewBox="0 0 55 61" fill="none" {...props} >
      <path d="M48.8889 54.9H6.11111V21.35H48.8889M48.8889 6.1H45.8333V0H39.7222V6.1H15.2778V0H9.16667V6.1H6.11111C2.71944 6.1 0 8.845 0 12.2V54.9C0 56.5178 0.643847 58.0694 1.7899 59.2133C2.93596 60.3573 4.49034 61 6.11111 61H48.8889C50.5097 61 52.064 60.3573 53.2101 59.2133C54.3562 58.0694 55 56.5178 55 54.9V12.2C55 10.5822 54.3562 9.03062 53.2101 7.88665C52.064 6.74268 50.5097 6.1 48.8889 6.1ZM41.3417 30.683L38.1028 27.45L23.1917 42.334L16.7139 35.868L13.475 39.101L23.1917 48.8L41.3417 30.683Z" fill="url(#paint0_linear_1266_10582)"/>
      <defs>
        <linearGradient 
          id="paint0_linear_1266_10582" 
          x1="-5.51498e-07" 
          y1="16.5504" 
          x2="58.8155" 
          y2="55.1927" 
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072"/>
          <stop offset="1" stopColor="#370097"/>
        </linearGradient>
      </defs>
    </Icon>
  )
})
