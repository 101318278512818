import './assets/styles/chakra_toast.css'

import { ChakraProvider } from '@chakra-ui/react'
import { RouterProvider } from 'react-router-dom'

import { light } from './assets/themes/letter/light'
import { AuthProvider } from './contexts/auth'
import { FlowProvider } from './contexts/flow'
import { NeonProvider } from './contexts/neon'
import { WalletConnectProvider } from './contexts/wallet-connect'
import Fonts from './Fonts'
import { ToastContainer } from './helpers/chakraToast'
import { router } from './routes'

export default function App() {
  return (
    <ChakraProvider theme={light}>
      <Fonts />
      <AuthProvider>
        <FlowProvider>
          <WalletConnectProvider>
            <NeonProvider>
              <RouterProvider router={router} />
            </NeonProvider>
          </WalletConnectProvider>
        </FlowProvider>
      </AuthProvider>
      <ToastContainer />
    </ChakraProvider>
  )
}
