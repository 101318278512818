import { Link, LinkProps } from './Link'

export const GradientLink = (props: LinkProps) => {
  return (
    <Link
      backgroundColor="transparent"
      background="letter.gradient.main"
      color="white"
      _active={{
        backgroundColor: 'red',
      }}
      _hover={{
        background: 'letter.gradient.secondary',
      }}
      {...props}
    />
  )
}
