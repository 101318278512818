import { Box, Flex, useBreakpointValue } from '@chakra-ui/react'
import { ReactNode } from 'react'

import { ModalTemplate } from '../ModalTemplate'

type Props = {
  isOpen: boolean
  onClose(): void
  disableClose?: boolean
  icon: ReactNode
  children: ReactNode
}

export const DiscordSignTemplateModal = ({ isOpen, onClose, disableClose, children, icon }: Props) => {
  const modalSize = useBreakpointValue({ base: 'full', md: '2xl' })

  return (
    <ModalTemplate size={modalSize} isOpen={isOpen} onClose={onClose} disableClose={disableClose} title="Event access">
      <Flex direction="column" alignItems="center">
        <Flex
          width="150px"
          height="150px"
          backgroundColor="letter.surface.darkest"
          borderRadius="full"
          justifyContent="center"
          alignItems="center"
          marginY="28px"
        >
          {icon}
        </Flex>

        <Box maxWidth="432px" textAlign="center">
          {children}
        </Box>
      </Flex>
    </ModalTemplate>
  )
}
