import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const PresenterIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 16" fill="white" {...props}>
      <path d="M6.54359 2.39335C7.41133 2.39335 8.24352 2.72956 8.8571 3.32801C9.47068 3.92647 9.81539 4.73815 9.81539 5.58449C9.81539 6.43083 9.47068 7.24251 8.8571 7.84096C8.24352 8.43942 7.41133 8.77562 6.54359 8.77562C5.67586 8.77562 4.84366 8.43942 4.23008 7.84096C3.6165 7.24251 3.2718 6.43083 3.2718 5.58449C3.2718 4.73815 3.6165 3.92647 4.23008 3.32801C4.84366 2.72956 5.67586 2.39335 6.54359 2.39335M6.54359 10.3712C8.72752 10.3712 13.0872 11.4402 13.0872 13.5623V15.1579H0V13.5623C0 11.4402 4.35967 10.3712 6.54359 10.3712ZM12.8909 2.68055C14.5431 4.43568 14.5431 6.86892 12.8909 8.48044L11.5167 7.13219C12.2038 6.1908 12.2038 4.97019 11.5167 4.02881L12.8909 2.68055ZM15.5983 0C18.8128 3.23103 18.7883 8.0656 15.5983 11.169L14.265 9.86859C16.5308 7.33163 16.5308 3.7097 14.265 1.30039L15.5983 0Z" />
    </Icon>
  );
});
