import { Box, SimpleGrid } from '@chakra-ui/react'

import { AppleCalendarIcon } from '../../assets/components/AppleCalendarIcon'
import { ArrowDownAlt2Icon } from '../../assets/components/ArrowDownAlt2'
import { CalendarAltIcon } from '../../assets/components/CalendarAltIcon'
import { GoogleCalendarIcon } from '../../assets/components/GoogleCalendarIcon'
import { OutlookCalendarIcon } from '../../assets/components/OutlookCalendarIcon'
import type { TStaticCategory } from '../../types/pages/category'
import type { TEventCalendarButton, TEventCalendarType } from '../../types/pages/event'
import { Link } from '../Link'
import { SectionHeader } from '../SectionHeader'

type Props = {
  buttons: TEventCalendarButton[]
  staticCategory: TStaticCategory
}

const iconsByEventCalendarType: Record<TEventCalendarType, JSX.Element> = {
  google: <GoogleCalendarIcon />,
  apple: <AppleCalendarIcon />,
  outlook: <OutlookCalendarIcon />,
}

const labelByEventCalendarType: Record<TEventCalendarType, string> = {
  google: 'Google Calendar',
  apple: 'iCal',
  outlook: 'Outlook',
}

export const EventCalendarButtons = ({ buttons, staticCategory }: Props) => {
  return (
    <Box width="full">
      <SectionHeader
        title="ADD TO CALENDAR"
        icon={<CalendarAltIcon />}
        iconColor={staticCategory.theme.onSurface.emphasis}
      />

      <SimpleGrid gap="10px" columns={{ base: 1, md: 2, xl: 1 }} marginTop="24px">
        {buttons.map((button, index) => (
          <Link
            key={index}
            isExternal
            to={button.url}
            label={button.label ?? labelByEventCalendarType[button.type]}
            leftIcon={iconsByEventCalendarType[button.type]}
            leftIconSize="big"
            rightIcon={<ArrowDownAlt2Icon fill={'red'} />}
            color={staticCategory.theme.onSurface.main}
            backgroundColor={staticCategory.theme.surface.lightest}
            _hover={{
              opacity: 0.9,
            }}
            textTransform="none"
            size="big"
            full
          />
        ))}
      </SimpleGrid>
    </Box>
  )
}
