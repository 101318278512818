import { extendTheme } from '@chakra-ui/react'

import { global } from '../global'

export const light = extendTheme({
  ...global,
  colors: {
    letter: {
      gradient: {
        main: 'linear-gradient(108.8deg, #26B9FF -21.24%, #A3008E 100%)',
        secondary: 'linear-gradient(108.8deg, #2CF2FF -21.24%, #DA05BE 100%)',
        dark: 'linear-gradient(126.08deg, #E00072 11.44%, #370097 100%)',
      },
      blue: {
        main: '#26B9FF',
      },
      purple: {
        light: '#5F00A8',
        dark: '#370097',
      },
      grey: {
        dark: '#303135',
      },
      pink: {
        light: '#E00072',
      },
      surface: {
        main: '#FFFFFF',
        dark: '#F8FBFE',
        darkest: '#EFF3F5',
      },
      onSurface: {
        main: '#000000',
        lightest: '#D8D8D8',
        700: 'rgba(0,0,0,0.7)',
        300: 'rgba(0,0,0,0.3)',
        divider: '#EFF3F5',
        disabled: '#CBD1D4',
        medium: '#A3A3A7',
      },
      box: {
        main: '#000000',
        800: 'rgba(0, 0, 0, 0.8)',
        700: 'rgba(0, 0, 0, 0.7)',
        500: 'rgba(0, 0, 0, 0.5)',
        300: 'rgba(0, 0, 0, 0.3)',
      },
      onBox: {
        main: '#FFFFFF',
        light: '#E1DED0',
        400: 'rgba(255,255,255,0.4)',
      },
      button: {
        main: '#EFF3F5',
        hover: 'rgba(176, 189, 197, 0.4)',
        text: '#000000',
      },
      onButton: {
        main: '#000000',
        disabled: '#A7A89B',
      },
    },
  },
})
