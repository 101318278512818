import { Flex } from '@chakra-ui/react'

import { AboutStep } from './Step'
import { AboutSubStep } from './SubStep'

export const AboutHowPurchaseWalletConnect = () => {
  return (
    <Flex direction="column" marginTop="64px" gridRowGap="32px">
      <AboutStep
        index={1}
        title="Choose the event"
        description="First select the event that you would like to purchase."
      />

      <AboutStep index={2} title="Purchase">
        <Flex direction="column" gridRowGap="54px">
          <AboutSubStep
            index={1}
            description={
              <>
                <b>Get access</b>
                <br />
                If you are happy that you have the right event click on the “Get Exclusive Access” button on the
                righthand side.
              </>
            }
          />

          <AboutSubStep
            index={2}
            description={
              <>
                <b>Authorization request</b>
                <br />
                You will now be instructed to authorize the payment request via your wallet. Go to your wallet and
                confirm.
              </>
            }
          />

          <AboutSubStep
            index={3}
            description={
              <>
                <b>Enter payment details</b>
                <br />
                Paying is easy and simple to do. Simply enter your payment details, country and area code and click pay.
              </>
            }
          />
        </Flex>
      </AboutStep>

      <AboutStep
        index={3}
        title="NFT generation"
        description="The partner provider will generate a custom NFT - your Letter Key, granting you access to the event or theme you’ve chosen."
      />
    </Flex>
  )
}
