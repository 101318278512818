import React from 'react'

const Fonts: React.FC = () => {
  return (
    <style>
      @import
      url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
    </style>
  )
}

export default Fonts
