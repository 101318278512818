import { Box, Input as ChakraInput, InputProps as ChakraInputProps, InputProps, Text, useToken } from '@chakra-ui/react'
import { Fragment } from 'react'
import { useIMask } from 'react-imask'

type Props = {
  label?: string
  name: string
  mask?: string
} & InputProps

export const Input = ({ label, name, mask, ...props }: Props) => {
  const divider = useToken('colors', 'letter.onSurface.divider')
  const { ref } = useIMask({ mask: mask ?? '' })

  const styles: ChakraInputProps = {
    boxShadow: `0px 2px 4px ${divider}`,
    borderColor: 'letter.onSurface.divider',
    name,
    _focus: {},
    _focusVisible: {},
    ...props,
  }

  const Parent = label ? Box : Fragment

  return (
    <Parent>
      {label && (
        <Text as="label" htmlFor={name} display="inline-block" fontSize="14px" fontWeight="bold" paddingBottom="12px">
          {label}
        </Text>
      )}

      <ChakraInput ref={mask ? ref : undefined} {...styles} />
    </Parent>
  )
}
