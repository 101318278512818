import { Box, Flex, Heading, Text } from '@chakra-ui/react'

type Props = {
  title: string
  price: number
}

export const EventPaymentInfo = ({ title, price }: Props) => {
  return (
    <Flex
      direction="row"
      gridRowGap={{ base: '30px', xl: '64px' }}
      width="100%"
      background="letter.surface.darkest"
      borderRadius="5px"
      marginBottom="32px"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      padding="6px 14px"
      justifyContent="space-between"
      alignItems="center"
    >
      <Heading as="h4" fontSize="20px" fontWeight="bold">
        {title}
      </Heading>
      <Box textAlign="end">
        <Heading as="h4" fontSize="24px" fontWeight="bold" textTransform="uppercase">
          ${price}
        </Heading>

        <Text as="span" display="block" fontSize="10px" color="letter.onSurface.medium">
          *Payment fee’s may apply
        </Text>
      </Box>
    </Flex>
  )
}
