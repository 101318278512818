import { Flex, useToken } from '@chakra-ui/react'
import { Link as RRDLink } from 'react-router-dom'

import { FacebookIcon } from '../../assets/components/FacebookIcon'
import { InstagramIcon } from '../../assets/components/InstagramIcon'
import { LetterLogo } from '../../assets/components/LetterLogo'
import { TwitterIcon } from '../../assets/components/TwitterIcon'
import { DEFAULT_CATEGORY_ROUTE } from '../../constants/categories'
import type { TStaticCategory } from '../../types/pages/category'
import { SocialButton } from './SocialButton'

type Props = {
  staticCategory?: TStaticCategory
}

export const Footer = ({ staticCategory }: Props) => {
  const tokenColor = useToken('colors', `letter.onSurface.main`)

  return (
    <Flex
      backgroundColor={staticCategory?.theme.surface.dark ?? `letter.surface.dark`}
      width="100%"
      justifyContent="center"
    >
      <Flex
        width="100%"
        maxWidth="2178px"
        paddingY="42px"
        paddingX={{ base: '16px', md: '32px', lg: 'min(154px, 8%)' }}
        justifyContent="space-between"
        alignItems="center"
        height="80px"
      >
        <RRDLink to={DEFAULT_CATEGORY_ROUTE}>
          <LetterLogo color={staticCategory?.theme.onSurface.main ?? tokenColor} />
        </RRDLink>

        <Flex gridColumnGap="6px">
          <SocialButton
            aria-label="Instagram"
            backgroundColor={staticCategory?.theme.primary ?? 'letter.box.main'}
            backgroundColorHover={staticCategory?.theme.hover ?? 'letter.box.700'}
            icon={<InstagramIcon />}
          />

          <SocialButton
            aria-label="Facebook"
            backgroundColor={staticCategory?.theme.primary ?? 'letter.box.main'}
            backgroundColorHover={staticCategory?.theme.hover ?? 'letter.box.700'}
            icon={<FacebookIcon />}
          />

          <SocialButton
            aria-label="Twitter"
            backgroundColor={staticCategory?.theme.primary ?? 'letter.box.main'}
            backgroundColorHover={staticCategory?.theme.hover ?? 'letter.box.700'}
            icon={<TwitterIcon />}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
