import type { TStaticCategory } from '../../types/pages/category'
import { Link, LinkProps } from '../Link'

type Props = {
  staticCategory: TStaticCategory
} & LinkProps

export const EventCategoryLink = ({ staticCategory, ...props }: Props) => {
  return (
    <Link
      backgroundColor={staticCategory.theme.primary}
      _hover={{ backgroundColor: staticCategory.theme.hover }}
      color={staticCategory.theme.onPrimary}
      {...props}
    />
  )
}
