import { Icon, IconProps } from '@chakra-ui/react'
import { memo } from 'react'

export const LetterAltIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 19 20" stroke="white" strokeWidth="1" strokeMiterlimit="10" {...props} fill="transparent">
      <path d="M7.45897 14.3008L10.9085 16.435L7.46154 18.5938L0.516113 14.5798V6.38204L10.9085 0.59375L17.7293 4.61032V12.4943L14.5188 14.5798L7.46154 10.6995" />
      <path d="M7.46411 18.5795V10.3445L17.7293 4.59863" />
      <path d="M17.7447 12.4748L10.8262 8.53027" />
      <path d="M7.49362 10.4205L0.575195 6.47461" />
    </Icon>
  )
})
