import { Box, Flex, Heading, Hide } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

import { LetterAltIcon } from '../assets/components/LetterAltLogo'
import { AboutNavLink } from '../components/about/NavLink'
import { MainTemplate } from '../templates/Main'

export const AboutLayout = () => {
  return (
    <MainTemplate>
      <Flex as="header" direction="column" backgroundColor="letter.grey.dark" alignItems="center">
        <Flex
          position="relative"
          maxWidth="2178px"
          paddingX={{ base: '16px', md: '32px', lg: 'min(154px, 8%)' }}
          paddingBottom="28px"
          width="100%"
          height="178px"
          justifyContent="space-between"
          overflow="hidden"
        >
          <Heading as="h2" fontSize="58px" fontWeight="bold" color="letter.onBox.main" alignSelf="end">
            About letter
          </Heading>

          <LetterAltIcon
            position="absolute"
            right={{ base: '16px', md: '32px', lg: 'min(154px, 8%)' }}
            top="50%"
            transform="translateY(-50%)"
            boxSize="290px"
            strokeWidth="0.5px"
            stroke="letter.onBox.main"
            opacity="0.11"
          />
        </Flex>

        <Box background="linear-gradient(126.08deg, #E00072 11.44%, #370097 100%)" height="5px" width="full" />
      </Flex>

      <Flex backgroundColor="letter.surface.main" justifyContent="center" height="100%">
        <Flex
          maxWidth="2178px"
          paddingX={{ base: '16px', md: '32px', lg: 'min(154px, 8%)' }}
          marginY={{ base: '76px', md: '145px' }}
          width="100%"
          gridColumnGap="20px"
        >
          <Hide below="md">
            <Box width="320px" minWidth={{ base: '200px', lg: '320px' }}>
              <AboutNavLink label="Overview" to="/about" />
              <AboutNavLink label="How it works" to="/about/how">
                <AboutNavLink label="Sign up" to="/about/how/signup" isSub />
                <AboutNavLink label="Purchase" to="/about/how/purchase" isSub />
                <AboutNavLink label="Discord event access" to="/about/how/discord" isSub />
              </AboutNavLink>
              <AboutNavLink label="Partners" to="/about/partners" />
            </Box>
          </Hide>

          <Box width="full">
            <Outlet />
          </Box>
        </Flex>
      </Flex>
    </MainTemplate>
  )
}
