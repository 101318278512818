import { Box, Flex, Text } from '@chakra-ui/react'

type Props = {
  title: string
  description?: string | React.ReactNode
  index: number
  children?: React.ReactNode
}

export const AboutStep = ({ description, index, title, children }: Props) => {
  return (
    <Flex gridColumnGap="18px">
      <Flex
        width="30px"
        minWidth="30px"
        height="30px"
        background="letter.pink.light"
        alignItems="center"
        justifyContent="center"
        fontSize="20px"
        fontWeight="semibold"
        color="letter.onBox.main"
        borderRadius="full"
      >
        {index}
      </Flex>

      <Box>
        <Text fontSize="22px" fontWeight="semibold">
          {title}
        </Text>

        {description && <Text fontSize="18px">{description}</Text>}

        {children && <Box marginTop="32px">{children}</Box>}
      </Box>
    </Flex>
  )
}
