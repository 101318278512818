import { Box, Flex, Heading, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { ReactNode } from 'react'

import { CloseIcon } from '../assets/components/CloseIcon'
import { IconButton } from './IconButton'

type Props = {
  onClose(): void
  isOpen: boolean
  children: ReactNode
  title: string
  size?: string
  disableClose?: boolean
}

export const ModalTemplate = ({ isOpen, onClose, children, title, size, disableClose = false }: Props) => {
  return (
    <Modal size={size} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={!disableClose}>
      <ModalOverlay background="letter.box.800" />

      <ModalContent
        paddingX={{ base: '12px', sm: '34px' }}
        paddingY={{ base: '28px', sm: '34px' }}
        color="letter.onSurface.main"
        backgroundColor="letter.surface.main"
        borderRadius={size === 'full' ? 'none' : 'md'}
      >
        <Box>
          <Box>
            <Flex justifyContent="space-between">
              <Heading as="h2" fontSize={{ base: '28px', sm: '40px' }} fontWeight="semibold" lineHeight="40px">
                {title}
              </Heading>

              <IconButton
                size="xs"
                aria-label="Close modal"
                icon={<CloseIcon fill="letter.onSurface.main" />}
                onClick={onClose}
                disabled={disableClose}
              />
            </Flex>

            <Box
              width={{ base: '42px', sm: '60px' }}
              height={{ base: '6px', sm: '8px' }}
              background="letter.gradient.main"
              marginTop="12px"
            />
          </Box>

          {children}
        </Box>
      </ModalContent>
    </Modal>
  )
}
