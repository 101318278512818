import { NeonInvoker } from '@cityofzion/neon-invoker'
import { NetworkType } from '@cityofzion/wallet-connect-sdk-core'
import { Neo3Authenticator } from 'letter-sdk'

import {flowMainnetOptions, FlowOptions, flowTestnetOptions} from './flow'

export const Networks: Record<
  string,
  { wcIdentifier: NetworkType; rpcAddress: string; scriptHash: string; flowOptions: FlowOptions }
> = {
  PRIVATE: {
    wcIdentifier: 'neo3:private',
    rpcAddress: 'http://127.0.0.1:50012',
    scriptHash: '0x4219eb74718c768032225ea77e7f669049a46e4e',
    flowOptions: flowTestnetOptions,
  },
  TESTNET: {
    wcIdentifier: 'neo3:testnet',
    rpcAddress: NeonInvoker.TESTNET,
    scriptHash: Neo3Authenticator.TESTNET,
    flowOptions: flowTestnetOptions,
  },
  MAINNET: {
    wcIdentifier: 'neo3:mainnet',
    rpcAddress: NeonInvoker.MAINNET,
    scriptHash: Neo3Authenticator.MAINNET,
    flowOptions: flowMainnetOptions,
  },
}

export const NETWORK = process.env.REACT_APP_NETWORK == 'main' ? Networks.MAINNET : Networks.TESTNET
