import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const PreviousIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 12 21" fill="white" {...props}>
      <path d="M11.2962 19.4636L2.02944 10.2372L11.2962 0.993714L10.2901 0L5.72205e-06 10.2372L10.2901 20.4744L11.2962 19.4636Z" />
    </Icon>
  );
});
