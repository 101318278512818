import { Flex, FlexProps, IconProps, Text } from '@chakra-ui/react'
import { cloneElement } from 'react'

export type TagProps = {
  icon?: JSX.Element
  label: string
  isRounded?: boolean
  iconStyle?: IconProps
} & FlexProps

export const Tag = ({ label, icon, isRounded = true, iconStyle, ...props }: TagProps) => {
  return (
    <Flex
      gridColumnGap="8px"
      backgroundColor="letter.grey.dark"
      height="34px"
      borderRadius={isRounded ? '17px' : '0px'}
      paddingX="16px"
      alignItems="center"
      width="fit-content"
      {...props}
    >
      {icon && cloneElement(icon, { boxSize: '24px', fill: 'letter.onBox.main', ...iconStyle })}

      <Text fontSize="14px" fontWeight="medium" color="letter.onBox.main" whiteSpace="nowrap">
        {label}
      </Text>
    </Flex>
  )
}
