import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { format, fromUnixTime } from 'date-fns'
import { useMemo } from 'react'

import { ClockIcon } from '../../assets/components/ClockIcon'
import { DiscordIcon } from '../../assets/components/DiscordIcon'
import { LaptopIcon } from '../../assets/components/LaptopIcon'
import { MapIcon } from '../../assets/components/MapIcon'
import { EventHelper } from '../../helpers/event'
import type { TStaticCategory } from '../../types/pages/category'
import type { TEvent } from '../../types/pages/event'
import { EventCategoryLink } from '../category/EventCategoryLink'
import { SectionHeader } from '../SectionHeader'

type Props = {
  event: TEvent
  staticCategory: TStaticCategory
  hasAccess?: boolean
}

export const EventAccessDetails = ({ event, staticCategory, hasAccess }: Props) => {
  const isLive = EventHelper.isLive(event)

  const datetimeFormatted = useMemo(() => {
    if (!event.datetime) return

    const formattedDate = fromUnixTime(event.datetime)

    const date = format(formattedDate, 'EEEE, MMMM do, yyyy')
    const hour = format(formattedDate, 'h:mmaa')

    return {
      date,
      hour,
    }
  }, [event])

  return (
    <Box width="full">
      <SectionHeader title="ACCESS DETAILS" icon={<MapIcon />} iconColor={staticCategory.theme.onSurface.emphasis} />

      <SimpleGrid
        backgroundColor={staticCategory.theme.surface.lightest}
        padding="20px"
        marginTop="24px"
        spacing="20px"
        columns={{ base: 1, md: 2, xl: 1 }}
        borderRadius="6px"
      >
        {((!!datetimeFormatted && isLive) || !isLive) && (
          <Flex>
            <ClockIcon boxSize="24px" fill={staticCategory.theme.onSurface.emphasis} />
            <Box marginLeft="18px" minWidth="0">
              {!!datetimeFormatted && isLive ? (
                <>
                  <Text fontSize="16px" fontWeight="medium" noOfLines={1} title={datetimeFormatted.date}>
                    {datetimeFormatted.date}
                  </Text>
                  <Text fontSize="16px" fontWeight="light" noOfLines={1} title={datetimeFormatted.hour}>
                    {datetimeFormatted.hour}
                  </Text>
                </>
              ) : (
                <Text fontSize="16px" fontWeight="medium" noOfLines={1}>
                  TBC
                </Text>
              )}
            </Box>
          </Flex>
        )}

        <Box>
          <Flex marginBottom="12px">
            <LaptopIcon boxSize="24px" fill={staticCategory.theme.onSurface.emphasis} />
            <Text fontSize="16px" fontWeight="medium" marginLeft="18px">
              Access info
            </Text>
          </Flex>

          {hasAccess && isLive ? (
            <EventCategoryLink
              to={event.accessLink}
              isExternal={true}
              label="Join this discussion"
              staticCategory={staticCategory}
              leftIcon={<DiscordIcon fill={staticCategory.theme.onPrimary} />}
              full
            />
          ) : (
            <Box padding="24px" width="100%" border="2px dashed" borderColor={staticCategory.theme.onSurface.disable}>
              Access information will appear once access has been purchased
            </Box>
          )}
        </Box>
      </SimpleGrid>
    </Box>
  )
}
