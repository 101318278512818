import { Box, Flex, Heading, Portal, useDisclosure, useMediaQuery, useSafeLayoutEffect } from '@chakra-ui/react'
import { useEffect } from 'react'

import { ArrowLeftIcon } from '../../assets/components/ArrowLeftIcon'
import { CloseIcon } from '../../assets/components/CloseIcon'
import { MoreIcon } from '../../assets/components/MoreIcon'
import { DEFAULT_CATEGORY_ROUTE } from '../../constants/categories'
import { useMultiAuth } from '../../hooks/useMultiAuth'
import { Button } from '../Button'
import { IconButton } from '../IconButton'
import { Link } from '../Link'

export const MobileNav = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isOpen: aboutLetterIsOpen, onClose: aboutLetterOnClose, onOpen: aboutLetterOnOpen } = useDisclosure()
  const [isLargerThanMD] = useMediaQuery('(min-width: 768px)')
  const { authData } = useMultiAuth()

  const handleClose = () => {
    if (aboutLetterIsOpen) {
      aboutLetterOnClose()

      setTimeout(() => onClose(), 200)

      return
    }

    onClose()
  }

  useEffect(() => {
    if (isLargerThanMD) {
      onClose()
    }
  }, [onClose, isLargerThanMD])

  useSafeLayoutEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset'
  }, [isOpen])

  return (
    <>
      <IconButton
        display={{ base: 'block', md: 'none' }}
        aria-label="Navigation"
        icon={isOpen ? <CloseIcon boxSize="18px" /> : <MoreIcon boxSize="18px" />}
        onClick={isOpen ? handleClose : onOpen}
      />

      <Portal>
        <Box
          height={isOpen ? 'calc(100vh - 72px)' : '0px'}
          width="100vw"
          position="fixed"
          top="72px"
          left="0px"
          zIndex={51}
          backgroundColor="letter.box.main"
          transition="height 0.3s, opacity 0.15s"
          overflow="hidden"
        >
          <Flex
            height="full"
            width="full"
            direction="column"
            backgroundColor="letter.box.main"
            position="absolute"
            display={aboutLetterIsOpen ? 'flex' : 'none'}
          >
            <Flex justifyContent="center" alignItems="center" position="relative" marginTop="16px">
              <IconButton
                position="absolute"
                left="16px"
                top="0px"
                aria-label="Navigation"
                icon={<ArrowLeftIcon boxSize="18px" fill="letter.onBox.main" />}
                onClick={aboutLetterOnClose}
              />

              <Heading fontSize="24px" as="h3" color="letter.onBox.main">
                About Letter
              </Heading>
            </Flex>

            <Flex direction="column" alignItems="center" justifyContent="center" width="full" height="full">
              <Box width="100%" as="nav">
                <Link
                  to="/about"
                  label="Overview"
                  variant="text"
                  color="letter.onBox.main"
                  full
                  size="big"
                  height="56px"
                  onClick={handleClose}
                />

                <Link
                  to="/about/how"
                  label="How it works"
                  variant="text"
                  color="letter.onBox.main"
                  full
                  size="big"
                  height="56px"
                  onClick={handleClose}
                />

                <Flex justifyContent="center">
                  <Box height="1px" width="38px" backgroundColor="letter.onBox.main" />
                </Flex>

                <Link
                  to="/about/how/signup"
                  label="Sign up"
                  variant="text"
                  color="letter.onBox.main"
                  full
                  size="big"
                  height="56px"
                  onClick={handleClose}
                />

                <Link
                  to="/about/how/purchase"
                  label="Purchase"
                  variant="text"
                  color="letter.onBox.main"
                  full
                  size="big"
                  height="56px"
                  onClick={handleClose}
                />

                <Link
                  to="/about/how/discord"
                  label="Discord event access"
                  variant="text"
                  color="letter.onBox.main"
                  full
                  size="big"
                  height="56px"
                  onClick={handleClose}
                />
              </Box>
            </Flex>
          </Flex>

          <Flex
            height="full"
            opacity={isOpen ? 1 : 0}
            width={aboutLetterIsOpen ? '0px' : 'full'}
            position="absolute"
            direction="column"
            backgroundColor="letter.box.main"
            transition="width 0.3s, opacity 0.15s"
            alignItems="center"
            justifyContent="center"
            as="nav"
            overflow="hidden"
          >
            <Link
              to={DEFAULT_CATEGORY_ROUTE}
              label="Home"
              variant="text"
              color="letter.onBox.main"
              full
              size="big"
              height="56px"
              onClick={handleClose}
            />

            <Button
              label="About letter"
              variant="text"
              color="letter.onBox.main"
              full
              height="56px"
              onClick={aboutLetterOnOpen}
            />

            {!authData.isAuthenticated && (
              <>
                <Flex justifyContent="center">
                  <Box height="1px" width="38px" backgroundColor="letter.onBox.main" />
                </Flex>

                <Link
                  to="/signup"
                  label="Sign Up"
                  variant="text"
                  color="letter.onBox.main"
                  full
                  size="big"
                  height="56px"
                  onClick={handleClose}
                />
              </>
            )}
            
          </Flex>
        </Box>
      </Portal>
    </>
  )
}
