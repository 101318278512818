import { Box, Flex, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react'
import { FlowWalletService } from 'letter-sdk/dist/utils/FlowAdapter'
import React, { FormEvent, useState } from 'react'

import { AddUser } from '../assets/components/AddUser'
import { LoginIcon } from '../assets/components/LoginIcon'
import background from '../assets/images/letter_logo-background.svg'
import neonWalletLogo from '../assets/images/neon-wallet.png'
import { Button } from '../components/Button'
import { GradientButton } from '../components/GradientButton'
import { Input } from '../components/Input'
import { LoginCreatedAccountModal } from '../components/login/LoginCreatedAccountModal'
import { LoginNeoWalletConnectionModal } from '../components/login/LoginNeoWalletConnectionModal'
import { SectionHeader } from '../components/SectionHeader'
import { buttonsByFlowServices } from '../constants/flow'
import { useMultiAuth } from '../hooks/useMultiAuth'
import { MainTemplate } from '../templates/Main'
import { EWalletConnectStatus } from '../types/contexts/wallet-connect'
import { EMultiAuthStatus } from '../types/hooks/useMultiAuth'

type Props = {
  onLogin: (code: string) => void

  neoWalletConnectionModalIsOpen: boolean
  onCloseNeoWalletConnectionModal(): void
  onLoginNeoWalletConnectionModal(): Promise<string>
  onOpenNeoWalletConnectionModal(): void

  createdAccountModalIsOpen: boolean
  onCloseCreatedAccountModal(): void
  onOpenCreatedAccountModal(): void
  onLoadCodeCreatedAccountModal(): Promise<string>

  onLoginFlowWallet(service: FlowWalletService): Promise<void>
}

export const LoginLayout = ({
  onLogin,
  createdAccountModalIsOpen,
  neoWalletConnectionModalIsOpen,
  onLoginNeoWalletConnectionModal,
  onOpenCreatedAccountModal,
  onOpenNeoWalletConnectionModal,
  onCloseCreatedAccountModal,
  onCloseNeoWalletConnectionModal,
  onLoadCodeCreatedAccountModal,
  onLoginFlowWallet,
}: Props) => {
  const { walletConnectStatus, flowServices, authStatus } = useMultiAuth()

  const [code, setCode] = useState('')

  const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => setCode(event.target.value)

  const handleLogin = (event: FormEvent) => {
    event.preventDefault()

    onLogin(code)
  }

  return (
    <MainTemplate loading={authStatus !== EMultiAuthStatus.started}>
      <Flex
        flexGrow={1}
        width="100%"
        backgroundImage={`url(${background})`}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          paddingX={{ base: '12px', sm: '34px' }}
          paddingY={{ base: '28px', sm: '34px' }}
          marginY="64px"
          marginX="12px"
          color="letter.onSurface.main"
          backgroundColor="letter.surface.main"
          borderRadius="md"
          border="1px solid"
          borderColor="letter.onSurface.divider"
          width="100%"
          maxWidth="654px"
        >
          <Heading as="h2" fontSize={{ base: '28px', sm: '40px' }} fontWeight="semibold" lineHeight="40px">
            Login
          </Heading>

          <Box
            width={{ base: '42px', sm: '60px' }}
            height={{ base: '6px', sm: '8px' }}
            background="letter.gradient.main"
            marginTop="12px"
          />

          <Box marginTop="34px">
            <SectionHeader icon={<LoginIcon />} title="LOGIN DETAILS" />

            <Box
              as="form"
              marginTop="20px"
              border="1px solid"
              borderColor="letter.onSurface.divider"
              borderRadius="6px"
              paddingX={{ base: '18px', sm: '68px' }}
              paddingY="26px"
              onSubmit={handleLogin}
            >
              <Input
                label="AUTHENTICATION CODE"
                autoComplete="off"
                name="authentication-code"
                value={code}
                onChange={handleChangeCode}
                isRequired
                minLength={24}
                maxLength={25}
              />
              <GradientButton marginTop="24px" full label="LOGIN" type="submit" />
            </Box>
          </Box>

          <Box marginTop="34px">
            <SectionHeader icon={<AddUser />} title="NEW USERS" />

            <Box
              marginTop="20px"
              border="1px solid"
              borderColor="letter.onSurface.divider"
              borderRadius="6px"
              paddingX={{ base: '18px', sm: '68px' }}
              paddingY="26px"
            >
              <GradientButton full label="1-CLICK ACCOUNT CREATION" onClick={onOpenCreatedAccountModal} />
            </Box>
          </Box>

          <Box marginY="36px" position="relative">
            <Box width="100%" height="1px" backgroundColor="letter.onSurface.divider" />
            <Text
              fontSize="14px"
              fontWeight="bold"
              color="letter.onSurface.medium"
              as="span"
              backgroundColor="letter.surface.main"
              textAlign="center"
              paddingX="4px"
              position="absolute"
              top="50%"
              left="50%"
              transform={'translateX(-50%) translateY(-50%)'}
              whiteSpace="nowrap"
            >
              OR LOGIN WITH A WALLET
            </Text>
          </Box>

          <SimpleGrid columns={{ base: 1, sm: 1 }} rowGap="8px" columnGap="16px">
            {walletConnectStatus !== EWalletConnectStatus.error && (
              <Button
                leftIcon={<Image src={neonWalletLogo} />}
                leftIconSize="big"
                size="big"
                label="NEON"
                full
                variant="outline"
                onClick={onOpenNeoWalletConnectionModal}
              />
            )}

            {flowServices.map(service => (
              <React.Fragment key={service.uid}>
                {buttonsByFlowServices[service.uid] && (
                  <Button
                    leftIcon={<Image src={buttonsByFlowServices[service.uid].image} />}
                    leftIconSize="big"
                    size="big"
                    label={buttonsByFlowServices[service.uid].label}
                    full
                    variant="outline"
                    onClick={() => onLoginFlowWallet(service)}
                  />
                )}
              </React.Fragment>
            ))}
          </SimpleGrid>
        </Box>
      </Flex>

      <LoginNeoWalletConnectionModal
        isOpen={neoWalletConnectionModalIsOpen}
        onClose={onCloseNeoWalletConnectionModal}
        onLogin={onLoginNeoWalletConnectionModal}
      />
      <LoginCreatedAccountModal
        isOpen={createdAccountModalIsOpen}
        onClose={onCloseCreatedAccountModal}
        onLoadCode={onLoadCodeCreatedAccountModal}
        onLogin={onLogin}
      />
    </MainTemplate>
  )
}
