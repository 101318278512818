import { Box, Flex, Image } from '@chakra-ui/react'

import { EventCategoryInfo } from '../components/category/EventCategoryInfo'
import { EventCategoryLatestEvents } from '../components/category/EventCategoryLatestEvents'
import { MainTemplate } from '../templates/Main'
import type { TStaticCategory } from '../types/pages/category'
import type { TEvent } from '../types/pages/event'

type Props = {
  staticCategory: TStaticCategory
  loading: boolean
  featureEvent?: TEvent
  events?: TEvent[]
}

export const EventCategoryLayout = ({ featureEvent, staticCategory, loading, events }: Props) => {
  return (
    <MainTemplate staticCategory={staticCategory} loading={loading}>
      {featureEvent && events && (
        <Flex
          alignItems="center"
          position="relative"
          direction="column"
          width="100%"
          minW={0}
          backgroundColor="red"
          grow={1}
        >
          <Image
            src={featureEvent.backgroundImage ?? featureEvent.thumbnailImage}
            position="absolute"
            zIndex={1}
            width="full"
            height="full"
            objectFit="cover"
          />

          {!featureEvent.disableBackgroundDarkLayer && (
            <Box position="absolute" width="full" height="full" zIndex={2} backgroundColor="letter.box.500" />
          )}

          <EventCategoryInfo staticCategory={staticCategory} featureEvent={featureEvent} />

          <EventCategoryLatestEvents events={events} staticCategory={staticCategory} />
        </Flex>
      )}
    </MainTemplate>
  )
}
