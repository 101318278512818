import { Box, Text } from "@chakra-ui/react";
import { addHours, format, fromUnixTime } from "date-fns";

type Props = {
  datetime: number;
  duration?: number;
};

export const PaymentModalHeaderDate = ({ datetime, duration }: Props) => {
  const date = fromUnixTime(datetime);
  const formatDate = format(date, "MMMM, eeee do, yyyy");
  const startHour = format(date, "h:mmaa");

  const endsDate = addHours(date, duration ?? 0);
  const endHour = format(endsDate, "h:mmaa");

  return (
    <Box>
      <Text fontSize="16px" fontWeight="medium">
        {formatDate}
      </Text>

      <Text fontSize="16px" fontWeight="light">
        {startHour} to {endHour} UTC
      </Text>
    </Box>
  );
};
