import { FCLAdapter, FCLOptions, FlowAuthenticator, FlowNetwork } from 'letter-sdk'

export type FlowOptions = {
  adapterOptions: FCLOptions
  authenticatorNetwork: FlowNetwork
}

export const flowTestnetOptions: FlowOptions = {
  adapterOptions: {
    ...FCLAdapter.TESTNET,
    appTitle: 'Letter',
    appImage: window.location.origin + '/letter_logo.png',
  },
  authenticatorNetwork: FlowAuthenticator.TESTNET,
}

export const flowMainnetOptions: FlowOptions = {
  adapterOptions: {
    ...FCLAdapter.MAINNET,
    appTitle: 'Letter',
    appImage: window.location.origin + '/letter_logo.png',
  },
  authenticatorNetwork: FlowAuthenticator.MAINNET,
}

export const buttonsByFlowServices: Record<string, { label: string; image: string }> = {
  // TODO: uncomment when flow contract is reviewed
  // 'dapper-wallet#authn': { label: 'Dapper', image: dapperLogo },
}
