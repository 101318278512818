import { IconButton } from '@chakra-ui/react'
import { cloneElement } from 'react'

type Props = {
  icon: JSX.Element
  backgroundColor: string
  backgroundColorHover: string
}

export const SocialButton = ({ icon, backgroundColor, backgroundColorHover }: Props) => {
  return (
    <IconButton
      backgroundColor={backgroundColor}
      borderRadius="full"
      variant="solid"
      aria-label="Instagram"
      size="xs"
      _hover={{
        backgroundColor: backgroundColorHover,
      }}
      icon={cloneElement(icon, { boxSize: '12px' })}
    />
  )
}
