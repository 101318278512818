import { CalendarIcon } from '../assets/components/CalendarIcon'
import { TStaticCategory } from '../types/pages/category'
import { Tag, TagProps } from './Tag'

type Props = {
  staticCategory: TStaticCategory
} & Omit<TagProps, 'label' | 'icon'>

export const ComingSoonTag = ({ staticCategory, ...props }: Props) => {
  return (
    <Tag
      backgroundColor="letter.box.main"
      color="letter.onBox.main"
      marginBottom="12px"
      iconStyle={{
        fill: staticCategory.theme.brand,
        boxSize: '18px',
      }}
      icon={<CalendarIcon />}
      label="COMING SOON"
      isRounded={false}
      {...props}
    />
  )
}
