import { createBrowserRouter, Navigate } from 'react-router-dom'

import { DEFAULT_CATEGORY_ROUTE } from '../constants/categories'
import { AboutPage } from '../pages/About'
import { AboutHowPage } from '../pages/AboutHow'
import { AboutHowDiscordPage } from '../pages/AboutHowDiscord'
import { AboutHowPurchasePage } from '../pages/AboutHowPurchase'
import { AboutHowSignupPage } from '../pages/AboutHowSignup'
import { AboutOverviewPage } from '../pages/AboutOverview'
import { AboutPartnersPage } from '../pages/AboutPartners'
import { DiscordSignPage } from '../pages/DiscordSign'
import { EventPage } from '../pages/Event'
import { EventCategoryPage } from '../pages/EventCategory'
import { LoginPage } from '../pages/Login'
import { NFTPage } from '../pages/NFT'
import { SignUpPage } from '../pages/SignUp'
import { discordSignLoader } from './loaders/discordSign'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to={DEFAULT_CATEGORY_ROUTE} />,
  },
  {
    path: '/category/:contentProviderLabel/:categoryLabel',
    element: <EventCategoryPage />,
  },
  {
    path: '/object/:contentProviderLabel/:categoryLabel/:eventLabel',
    element: <EventPage />,
  },
  {
    path: '/discordSign/:token',
    element: <DiscordSignPage />,
    loader: discordSignLoader,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/signup',
    element: <SignUpPage />,
  },
  {
    path: '/license/:blockchainLabel/:tokenId',
    element: <NFTPage />,
  },
  {
    path: '/about',
    element: <AboutPage />,
    children: [
      {
        path: '/about',
        element: <AboutOverviewPage />,
      },
      {
        path: '/about/how',
        element: <AboutHowPage />,
      },
      {
        path: '/about/how/signup',
        element: <AboutHowSignupPage />,
      },
      {
        path: '/about/how/purchase',
        element: <AboutHowPurchasePage />,
      },
      {
        path: '/about/how/discord',
        element: <AboutHowDiscordPage />,
      },
      {
        path: '/about/partners',
        element: <AboutPartnersPage />,
      },
    ],
  },
])

export { router }
