import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const AnchorIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 17 18" fill="white" {...props}>
      <path d="M16.2 13.5L16.2 8.1C16.2 5.95175 15.3466 3.89148 13.8276 2.37244C12.3085 0.853391 10.2483 -4.3481e-08 8.10001 -2.31287e-07C5.95176 -4.19094e-07 3.8915 0.853391 2.37245 2.37243C0.853406 3.89148 1.47624e-05 5.95175 1.45746e-05 8.1L1.41025e-05 13.5L3.60001 13.5L3.60001 8.1C3.60001 6.90652 4.07412 5.76193 4.91803 4.91802C5.76195 4.07411 6.90654 3.6 8.10001 3.6C9.29349 3.6 10.4381 4.07411 11.282 4.91802C12.1259 5.76193 12.6 6.90653 12.6 8.1L12.6 13.5M3.60001 15.3L1.39451e-05 15.3L1.37091e-05 18L3.60001 18M16.2 15.3L12.6 15.3L12.6 18L16.2 18" />
    </Icon>

  );
});
