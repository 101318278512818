import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react'
import { Link as RRDLink } from 'react-router-dom'

import { ArrowRightAltIcon } from '../../assets/components/ArrowRightAltIcon'
import { RoundedIcon } from '../RoundedIcon'

type Props = {
  title: string
  description: string
  to: string
  image: JSX.Element
}

export const LinkCard = ({ description, image, title, to }: Props) => {
  return (
    <Link
      as={RRDLink}
      to={to}
      display="flex"
      paddingY="22px"
      paddingX="18px"
      boxShadow="0px 0px 11px rgba(168, 44, 244, 0.14)"
      alignItems="center"
      gap="18px"
      _hover={{}}
    >
      <RoundedIcon icon={image} size="giant" background="letter.surface.darkest" />

      <Box>
        <Heading fontWeight="semibold" as="h5" fontSize="20px">
          {title}
        </Heading>
        <Text>{description}</Text>
      </Box>

      <Flex alignItems="flex-end" height="100%">
        <ArrowRightAltIcon fill="letter.gradient.main" />
      </Flex>
    </Link>
  )
}
