import { Flex, Text } from '@chakra-ui/react'

import { CheckCircleIcon } from '../assets/components/CheckCircleIcon'

export const ClipboardDefaultMessage = () => (
  <Flex alignItems="center" columnGap="12px">
    <CheckCircleIcon />
    <Text>Successfully copied!</Text>
  </Flex>
)
