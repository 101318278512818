import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import { addDays, addHours, format, fromUnixTime, isEqual, subDays } from 'date-fns'
import useResizeObserver from 'use-resize-observer'

import type { TStaticCategory } from '../../types/pages/category'
import { EventDateCard } from './EventDateCard'

const DAYS_BEFORE = 1
const MAX_DAYS_AFTER = 8
const MIN_DAYS_AFTER = 1
const MAGIC_NUMBER_MOBILE = 68
const MAGIC_NUMBER_DESKTOP = 89

type Props = {
  datetime: number
  duration?: number
  staticCategory: TStaticCategory
}

export const EventDates = ({ datetime, duration, staticCategory }: Props) => {
  const { ref, width } = useResizeObserver()
  const magicNumber = useBreakpointValue({
    base: MAGIC_NUMBER_MOBILE,
    md: MAGIC_NUMBER_DESKTOP,
  })

  const formattedDate = fromUnixTime(datetime)

  const datesBefore = Array(DAYS_BEFORE)
    .fill(null)
    .map((_, index) => subDays(formattedDate, index + 1))

  const daysAfterCalculation = width && magicNumber ? Math.floor(width / magicNumber - 2) : MIN_DAYS_AFTER

  const daysAfter =
    daysAfterCalculation <= MIN_DAYS_AFTER
      ? MIN_DAYS_AFTER
      : daysAfterCalculation >= MAX_DAYS_AFTER
      ? MAX_DAYS_AFTER
      : daysAfterCalculation

  const datesAfter = Array(daysAfter)
    .fill(null)
    .map((_, index) => addDays(formattedDate, index + 1))

  const allDates = [...datesBefore, formattedDate, ...datesAfter]

  const formattedActiveDate = format(formattedDate, 'MMMM d')
  const dateSuffix = format(formattedDate, 'do').slice(-2)
  const hour = format(formattedDate, 'h:mmaa')
  const year = format(formattedDate, 'yyyy')

  const endsDate = addHours(formattedDate, duration ?? 0)
  const endHour = format(endsDate, 'h:mmaa')

  return (
    <Box>
      <Flex justifyContent="space-between" ref={ref} gridColumnGap="8px">
        {allDates.map((date, index) => (
          <EventDateCard
            key={`dates-${index}`}
            date={date}
            isActive={isEqual(date, formattedDate)}
            staticCategory={staticCategory}
          />
        ))}
      </Flex>

      <Flex
        width="fit-content"
        alignItems={{ base: 'flex-start', sm: 'flex-end' }}
        wrap="wrap"
        gridColumnGap="4px"
        direction={{ base: 'column', sm: 'row' }}
      >
        <Text fontSize={{ base: '14px', md: '18px' }}>Starts</Text>

        <Text
          fontSize={{ base: '26px', md: '38px' }}
          fontWeight="semibold"
          lineHeight={{ base: '26px', md: '38px' }}
          marginX="8px"
        >
          {formattedActiveDate}
          <Text as="sup">{dateSuffix}</Text>
        </Text>

        <Text fontSize={{ base: '14px', md: '18px' }}>
          {year} from {hour} to {endHour} UTC
        </Text>
      </Flex>
    </Box>
  )
}
