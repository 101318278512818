import { Box, Text } from '@chakra-ui/react'

type Props = {
  index: number
  description: string | React.ReactNode
}

export const AboutSubStep = ({ description, index }: Props) => {
  return (
    <Box padding="20px" border="1px solid" borderColor="letter.onSurface.divider">
      <Text
        backgroundColor="letter.pink.light"
        paddingX="20px"
        paddingY="6px"
        fontFamily="12px"
        fontWeight="bold"
        width="fit-content"
        borderRadius="full"
        color="letter.onBox.main"
      >
        STEP {index}
      </Text>

      <Text marginTop="18px" fontSize="18px">
        {description}
      </Text>
    </Box>
  )
}
