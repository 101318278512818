import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const TwitterIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 15" {...props}>
      <path
        d="M18 1.72084C17.3375 2.02199 16.6233 2.21989 15.8834 2.31453C16.6405 1.85851 17.2256 1.13576 17.501 0.26673C16.7868 0.696941 15.9952 0.998088 15.1606 1.17017C14.4809 0.43021 13.5258 0 12.4417 0C10.4197 0 8.76769 1.65201 8.76769 3.69121C8.76769 3.98375 8.8021 4.26769 8.86233 4.53442C5.79924 4.37954 3.0717 2.90822 1.25621 0.679732C0.937859 1.2218 0.75717 1.85851 0.75717 2.52964C0.75717 3.81166 1.40249 4.94742 2.40057 5.59273C1.78968 5.59273 1.2218 5.42065 0.722754 5.16252C0.722754 5.16252 0.722754 5.16252 0.722754 5.18834C0.722754 6.97801 1.99618 8.47514 3.6826 8.81071C3.37285 8.89675 3.04589 8.93977 2.71033 8.93977C2.47801 8.93977 2.2457 8.91396 2.02199 8.87094C2.48662 10.325 3.83748 11.4092 5.46367 11.435C4.20746 12.4331 2.61568 13.0182 0.877629 13.0182C0.585086 13.0182 0.292543 13.001 0 12.9665C1.6348 14.0163 3.57935 14.6272 5.66157 14.6272C12.4417 14.6272 16.1673 9 16.1673 4.12142C16.1673 3.95794 16.1673 3.80306 16.1587 3.63958C16.8815 3.12333 17.501 2.46941 18 1.72084Z"
        fill="white"
      />
    </Icon>
  );
});
