import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const NextIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 12 21" fill="white" {...props}>
      <path d="M0 19.4636L9.26678 10.2372L0 0.993714L1.00614 0L11.2962 10.2372L1.00614 20.4744L0 19.4636Z" />
    </Icon>
  );
});
