import { wallet } from '@cityofzion/neon-core'
import { NeonEventListener } from '@cityofzion/neon-event-listener'
import { NeonInvoker } from '@cityofzion/neon-invoker'
import { NeonParser } from '@cityofzion/neon-parser'
import { NeonSigner } from '@cityofzion/neon-signer'
import { BIP39Encoded } from '@cityofzion/wallet-sdk'
import { Neo3Authenticator } from 'letter-sdk'
import { createContext, useCallback, useEffect, useRef, useState } from 'react'

import { NETWORK } from '../constants/blockchain'
import { useAuth } from '../hooks/useAuth'
import { TAuthType } from '../types/contexts/auth'
import { ENeontatus, NeonContextData, NeonContextProps, TNeonLocalStorageData } from '../types/contexts/neon'

const CODE_SESSION_STORAGE_KEY = '@Letter:code'
const AUTH_LOCAL_STORAGE_KEY = '@Letter:auth'

const NeonContext = createContext<NeonContextData>({} as NeonContextData)

export const NeonProvider = ({ children }: NeonContextProps) => {
  const { validate, revoke } = useAuth()
  const [status, setStatus] = useState<ENeontatus>(ENeontatus.starting)

  const accountRef = useRef<wallet.Account>()
  const blockchainLabel = useRef<string>('neo3')

  const getAuthenticator = useCallback(async () => {
    const invoker = await NeonInvoker.init(NETWORK.rpcAddress, accountRef.current)
    const parser = NeonParser
    const signer = new NeonSigner(accountRef.current)
    const eventListener = new NeonEventListener(NETWORK.rpcAddress)

    return new Neo3Authenticator({ invoker, parser, signer, eventListener }, NETWORK.scriptHash)
  }, [])

  const create = useCallback(async () => {
    const bip39Wallet = new BIP39Encoded({ length: 12 })

    return bip39Wallet.mnemonic.base58
  }, [])

  const connect = useCallback(
    async (base58: string) => {
      const bip39Wallet = new BIP39Encoded({ mnemonic: base58, length: 12 })

      const neoKeychain = bip39Wallet.getKeychain('neo')
      const neonKey = neoKeychain.generateChildKey("m/44'/888'/0'/0/0")
      const privateKey = neonKey.getWIF()

      const account = new wallet.Account(privateKey)

      accountRef.current = account

      const localStorageData: TNeonLocalStorageData = {
        type: 'connect',
        code: base58,
      }

      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(localStorageData))
      sessionStorage.setItem(CODE_SESSION_STORAGE_KEY, base58)

      validate({
        type: TAuthType.neon,
        address: account.address,
        code: base58,
        blockchainLabel: blockchainLabel.current,
      })
    },
    [validate]
  )

  const disconnect = useCallback(async () => {
    accountRef.current = undefined
    const localStorageData: TNeonLocalStorageData = {
      type: 'disconnect',
    }

    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(localStorageData))
    sessionStorage.removeItem(CODE_SESSION_STORAGE_KEY)
    revoke()
  }, [revoke])

  useEffect(() => {
    if (status === ENeontatus.started) return

    const localCode = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    const sessionCode = sessionStorage.getItem(CODE_SESSION_STORAGE_KEY)

    if (localCode) {
      const localCodeParsed = JSON.parse(localCode) as TNeonLocalStorageData

      if (localCodeParsed.code) {
        connect(localCodeParsed.code)
      } else if (sessionCode) {
        connect(sessionCode)
      }
    }

    setStatus(ENeontatus.started)
  }, [connect, disconnect, status])

  useEffect(() => {
    const onBeforeUnload = () => {
      const localStorageData: TNeonLocalStorageData = {
        type: 'closed',
      }

      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(localStorageData))
    }
    window.addEventListener('beforeunload', onBeforeUnload)

    const onStorage = (event: StorageEvent) => {
      if (event.key !== AUTH_LOCAL_STORAGE_KEY || !event.isTrusted || status === ENeontatus.starting || !event.newValue)
        return

      const value = JSON.parse(event.newValue) as TNeonLocalStorageData

      if (value.type === 'closed') {
        const sessionCode = sessionStorage.getItem(CODE_SESSION_STORAGE_KEY)

        if (!sessionCode) return

        connect(sessionCode)
      }

      if (value.type === 'connect' && value.code) {
        connect(value.code)
        return
      }

      if (value.type === 'disconnect') {
        disconnect()
      }
    }
    window.addEventListener('storage', onStorage)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
      window.removeEventListener('storage', onStorage)
    }
  }, [connect, disconnect, status])

  return (
    <NeonContext.Provider value={{ connect, disconnect, create, status, getAuthenticator }}>
      {children}
    </NeonContext.Provider>
  )
}

export default NeonContext
