import { Icon, IconProps } from '@chakra-ui/react'
import { memo } from 'react'

export const GradientCheckAltIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 90 91" {...props}>
      <path
        d="M45 91C20.185 91 0 70.5907 0 45.5C0 20.4093 20.185 0 45 0C69.815 0 90 20.4093 90 45.5C90 70.5907 69.815 91 45 91ZM45 6.07177C23.5029 6.07177 6.01135 23.7577 6.01135 45.4936C6.01135 67.2295 23.5029 84.9155 45 84.9155C66.4971 84.9155 83.9886 67.2295 83.9886 45.4936C83.9886 23.7577 66.4971 6.07177 45 6.07177Z"
        fill="url(#paint0_linear_751_7)"
      />
      <path
        d="M39.7492 69C38.8734 69 38.0298 68.6327 37.4502 67.9804L26.7667 56.0687C25.6462 54.8212 25.7686 52.9151 27.0372 51.8069C28.3058 50.705 30.2442 50.8253 31.3711 52.0728L39.3822 61.0082L60.3628 29.3703C61.2901 27.9771 63.1898 27.5781 64.6066 28.49C66.0233 29.4019 66.429 31.2701 65.5017 32.6632L42.3187 67.6195C41.7971 68.4111 40.9277 68.9113 39.9682 68.981C39.8909 68.981 39.8201 68.9873 39.7428 68.9873L39.7492 69Z"
        fill="url(#paint1_linear_751_7)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_751_7"
          x1="-9.02451e-07"
          y1="24.6899"
          x2="90.6875"
          y2="90.0461"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072" />
          <stop offset="1" stopColor="#370097" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_751_7"
          x1="-9.02451e-07"
          y1="24.6899"
          x2="90.6875"
          y2="90.0461"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072" />
          <stop offset="1" stopColor="#370097" />
        </linearGradient>
      </defs>
    </Icon>
  )
})
