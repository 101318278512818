import { Box, Flex, Hide, Image, Show, Text } from '@chakra-ui/react'

import { TStaticCategory } from '../../types/pages/category'

type Props = {
  staticCategory: TStaticCategory
  image?: string | React.ReactNode
  name: string
  caption?: string
  description?: string
}

export const EventPresenter = ({ staticCategory, image, name, caption, description }: Props) => {
  return (
    <Box
      backgroundColor={staticCategory.theme.surface.lightest}
      borderRadius="6px"
      padding="16px"
      boxSizing="content-box"
    >
      <Flex>
        {image && (
          <>
            {typeof image === 'string' ? (
              <Image
                src={image}
                borderRadius="6px"
                width="92px"
                height="92px"
                minWidth="92px"
                minHeight="92px"
                objectFit="cover"
                backgroundColor={staticCategory.theme.onSurface.disable}
              />
            ) : (
              image
            )}
          </>
        )}

        <Box marginLeft="18px" minWidth={0}>
          <Text fontSize="20px" fontWeight="semibold" noOfLines={1} color={staticCategory.theme.brand}>
            {`${name}`}
          </Text>
          {caption && (
            <Text fontSize="16px" fontWeight="light" noOfLines={1} marginTop="-6px">
              {caption}
            </Text>
          )}
          {description && (
            <Show above="sm">
              <Text fontSize="16px" fontWeight="light" marginTop="10px">
                {description}
              </Text>
            </Show>
          )}
        </Box>
      </Flex>

      {description && (
        <Hide above="sm">
          <Text fontSize="16px" fontWeight="light" marginTop={{ base: '28px', sm: '10px' }}>
            {description}
          </Text>
        </Hide>
      )}
    </Box>
  )
}
