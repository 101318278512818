import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const FacebookIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 10 18" fill="white" {...props}>
      <path d="M9.47247 6.30004H6.29998V4.50004C6.29998 3.57125 6.37558 2.98625 7.70668 2.98625H9.38787V0.124256C8.56977 0.0396561 7.74718 -0.00174374 6.92368 5.62498e-05C4.48199 5.62498e-05 2.69999 1.49135 2.69999 4.22914V6.30004H0V9.90003L2.69999 9.89913V18H6.29998V9.89733L9.05937 9.89643L9.47247 6.30004Z" />
    </Icon>
  );
});
