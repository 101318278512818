import { useBreakpointValue } from '@chakra-ui/react'

import { ModalTemplate } from '../ModalTemplate'
import { SignMessageContent } from './SignMessageContent'

type Props = {
  isOpen: boolean
  onClose(): void
  description?: string
}

export const SignMessageModal = ({ isOpen, onClose, description }: Props) => {
  const modalSize = useBreakpointValue({ base: 'full', md: '2xl' })

  return (
    <ModalTemplate size={modalSize} isOpen={isOpen} onClose={onClose} disableClose={true} title="Event access">
      <SignMessageContent description={description} />
    </ModalTemplate>
  )
}
