import { Box, Heading, Text } from '@chakra-ui/react'

import type { TStaticCategory } from '../../types/pages/category'
import type { TEvent } from '../../types/pages/event'
import { EventCategoryLink } from './EventCategoryLink'

type Props = {
  event: TEvent
  staticCategory: TStaticCategory
}

export const FeaturedEvent = ({ event, staticCategory }: Props) => {
  return (
    <Box
      padding={{ base: '12px', md: '28px' }}
      backgroundColor="letter.box.700"
      minWidth={{ base: 'unset', md: '316px' }}
      width={{ base: '100%', md: '316px' }}
      maxHeight={{ base: 'unset', md: '316px' }}
      color="letter.onBox.main"
      height="min-content"
    >
      <Text as="span" color={staticCategory.theme.primary} fontSize="14px" fontWeight="semibold">
        UP YOUR GAME
      </Text>

      <Heading fontSize="26px" fontWeight="bold" textTransform="uppercase" noOfLines={2}>
        {event.thumbnailTitle ?? event.title}
      </Heading>

      <Text marginTop="16px" fontSize="14px" lineHeight="18px" noOfLines={5}>
        {event.thumbnailDescription ?? event.description}
      </Text>

      <EventCategoryLink
        to={`/object/${staticCategory.contentProviderLabel}/${staticCategory.label}/${event.label}`}
        justifyContent="center"
        marginTop="24px"
        label="GET YOUR SEASON PASS NOW"
        staticCategory={staticCategory}
        full
      />
    </Box>
  )
}
