import { useLocation } from 'react-router-dom'

import { useMultiAuth } from '../../hooks/useMultiAuth'
import { GradientLink } from '../GradientLink'
import { AuthenticateButton } from './AuthenticateButton'

export const Login = () => {
  const { authData, disconnect } = useMultiAuth()

  const location = useLocation()

  return (
    <>
      {authData.isAuthenticated ? (
        <AuthenticateButton authData={authData} onDisconnect={disconnect} />
      ) : (
        <GradientLink to={`/login?redirect_to=${location.pathname}`} isExternal={false} label="Login" />
      )}
    </>
  )
}
