import type { TEvent } from '../types/pages/event'

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

type WithNoRequired<T, K extends keyof T> = T & { [P in K]?: undefined }

export class EventHelper {
  static isComingSoon(event: TEvent): event is WithNoRequired<TEvent, 'accessLink'> {
    return !event.accessLink
  }

  static isLive(event: TEvent): event is WithRequired<TEvent, 'accessLink'> {
    return EventHelper.isComingSoon(event) === false
  }
}
