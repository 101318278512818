import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const CheckIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 14" fill="white" {...props}>
      <path d="M6.42857 13.9772L0 7.54865L1.81286 5.73579L6.42857 10.3386L16.1871 0.580078L18 2.40579L6.42857 13.9772Z" />
    </Icon>
  );
});
