import { createStandaloneToast } from '@chakra-ui/react'

import { Toast } from '../components/Toast'
import { TRenderToastOptions } from '../types/helpers/chakraToast'

const { ToastContainer, toast } = createStandaloneToast()

const renderToast = ({ id, message, type }: TRenderToastOptions) => {
  if (id && toast.isActive(id)) return

  const title = message ? (typeof message === 'string' ? message : message()) : undefined

  return toast({
    position: 'top',
    duration: 2000,
    title,
    status: type ?? 'success',
    render: Toast,
  })
}

export { renderToast, ToastContainer }
