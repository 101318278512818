import { Box, Flex, Heading, Image, Show } from '@chakra-ui/react'

import { CloseIcon } from '../../../assets/components/CloseIcon'
import { CredentialsIcon } from '../../../assets/components/CredentialIcon'
import { DiscordAltIcon } from '../../../assets/components/DiscordAltIcon'
import type { TStaticCategory } from '../../../types/pages/category'
import type { TEvent } from '../../../types/pages/event'
import { IconButton } from '../../IconButton'
import { RoundedIcon } from '../../RoundedIcon'
import { Tag } from '../../Tag'
import { PaymentModalHeaderDate } from './PaymentModalHeaderDate'

type Props = {
  event: TEvent
  staticCategory: TStaticCategory
  onClose(): void
  disableClose?: boolean
}

export const PaymentModalHeader = ({ staticCategory, event, onClose, disableClose = false }: Props) => {
  return (
    <Box as="header" color="letter.onBox.main" position="relative">
      <Image
        src={event.backgroundImage ?? event.thumbnailImage}
        position="absolute"
        zIndex={1}
        width="full"
        height="full"
        objectFit="cover"
      />

      {!event.disableBackgroundDarkLayer && (
        <Box
          position="absolute"
          width="full"
          height="full"
          zIndex={2}
          objectFit="cover"
          backgroundColor="letter.box.500"
        />
      )}

      <Box
        paddingX={{ base: '16px', md: '34px' }}
        paddingTop={{ base: '26px', md: '36px' }}
        paddingBottom={{ base: '16px', md: '20px' }}
        zIndex={2}
        position="relative"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex gridColumnGap="12px" alignItems="center">
            <Show above="md">
              <RoundedIcon icon={<CredentialsIcon />} background={staticCategory.theme.brand} />
            </Show>

            <Heading as="h2" fontSize={{ base: '28px', md: '42px' }} fontWeight="semibold">
              Get exclusive access
            </Heading>
          </Flex>

          <IconButton
            size="xs"
            aria-label="Close modal"
            icon={<CloseIcon fill="letter.onBox.main" />}
            onClick={onClose}
            disabled={disableClose}
          />
        </Flex>

        <Box
          width="60px"
          height="8px"
          backgroundColor={staticCategory.theme.brand}
          marginBottom="26px"
          marginTop="8px"
        />

        <Flex
          justifyContent={{ base: 'unset', md: 'space-between' }}
          alignItems={{ base: 'unset', md: 'flex-end' }}
          direction={{ base: 'column', md: 'row' }}
          gap="8px"
        >
          <Box>
            <Heading as="h3" fontSize={{ base: '20px', md: '26px' }} fontWeight="semibold">
              {event.title}
            </Heading>

            {event.datetime && <PaymentModalHeaderDate datetime={1666125537} duration={event.duration} />}
          </Box>

          {event.type === 'online' && (
            <Tag icon={<DiscordAltIcon boxSize="24px" fill="letter.onBox.main" />} label="Discord Event" />
          )}
        </Flex>
      </Box>
    </Box>
  )
}
