import { Box, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react'

import { GradientDiscordIcon } from '../assets/components/GradientDiscordIcon'
import { GradientUserIcon } from '../assets/components/GradientUserIcon'
import { GradientUserPay } from '../assets/components/GradientUserPay'
import { LinkCard } from '../components/about/LinkCard'

export const AboutHowLayout = () => {
  return (
    <Box lineHeight="28px" fontSize="18px">
      <Box>
        <Heading as="h3" fontSize="32px" fontWeight="semibold" color="letter.onSurface.main">
          How it works
        </Heading>

        <Box width="64px" height="5px" background="letter.gradient.dark" marginTop="12px" />
      </Box>

      <Flex direction="column" gridRowGap="60px" marginTop="64px">
        <Flex gridColumnGap="32px">
          <Box>
            <Text fontWeight="bold">
              Ready to be a member of the most innovative content platform ever created? Letter is here to offer you an
              enjoyable experience, and accessibility is the first step. In just a few clicks, you can get your Letter
              Key to explore the best stories written by world-renowned creators.
            </Text>
            <br />
            <Text>
              Passwords are old news, your Letter Key, a custom-minted NFT, authenticates you automatically with total
              safety. Since Letter is a platform on blockchain, no third parties can access or modify your personal
              information. See how it works.
            </Text>
          </Box>
        </Flex>
      </Flex>

      <SimpleGrid columns={{ base: 1, xl: 2 }} gap="20px" marginTop="64px">
        <LinkCard
          title="Sign up"
          description="Learn about the different sign up options"
          image={<GradientUserIcon />}
          to="/about/how/signup"
        />

        <LinkCard
          title="Purchasing"
          description="How you can purchase an event"
          image={<GradientUserPay />}
          to="/about/how/purchase"
        />

        <LinkCard
          title="Using Discord for event access"
          description="Learn how to setup your discord profile"
          image={<GradientDiscordIcon />}
          to="/about/how/discord"
        />
      </SimpleGrid>
    </Box>
  )
}
