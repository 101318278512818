import { Box, Flex } from '@chakra-ui/react'

import { GradientDiscordIcon } from '../assets/components/GradientDiscordIcon'
import { AboutStep } from '../components/about/Step'
import { AboutSubHeader } from '../components/about/SubHeader'

export const AboutHowDiscordLayout = () => {
  return (
    <Box width="full">
      <AboutSubHeader icon={<GradientDiscordIcon />} title="Using Discord for event accesing" />

      <Flex direction="column" marginTop="64px" gridRowGap="32px">
        <AboutStep
          index={1}
          title="Join the discussion"
          description="After purchasing an event you will now find a “Join the discussion” button on the event details page. Click this button and you will be taken to the Discord platform where the event will take place."
        />

        <AboutStep
          index={2}
          title="Discord authentication"
          description="Go to any channel and type /authenticate and, with the provided link, proceed to identify your Discord account on the website with a single step. Don’t worry, you’ll only need to do this process once in a lifetime, after that, the LetterBot will automatically connect to the partner’s server and assign you a role, giving you access to exclusive private channels"
        />

        <AboutStep
          index={3}
          title="Enjoy!"
          description="And there you go! You will have unlocked the world of knowledge available on Letter."
        />
      </Flex>
    </Box>
  )
}
