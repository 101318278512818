import {
  Box,
  Flex,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'

import { ArrowDownIcon } from '../../assets/components/ArrowDownIcon'
import { CopyIcon } from '../../assets/components/CopyIcon'
import { LetterAltIcon } from '../../assets/components/LetterAltLogo'
import { useClipboard } from '../../hooks/useClipboard'
import { TAuthAuthenticatedData, TAuthType } from '../../types/contexts/auth'
import { Button } from '../Button'
import { GradientButton } from '../GradientButton'
import { IconButton } from '../IconButton'
import { RoundedIcon } from '../RoundedIcon'
import { AccountDetailsModal } from './AccountDetailsModal'

type Props = {
  authData: TAuthAuthenticatedData
  onDisconnect(): void
}

export const AuthenticateButton = ({ authData, onDisconnect }: Props) => {
  const isSmallerThanMd = useBreakpointValue({ base: true, md: false })
  const copy = useClipboard()
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <Popover placement="bottom-start" arrowSize={28} gutter={18}>
      <Flex alignItems="center">
        <PopoverAnchor>
          <Flex alignItems="center">
            <RoundedIcon
              icon={<LetterAltIcon />}
              size={isSmallerThanMd ? 'big' : 'bigger'}
              background="letter.gradient.dark"
            />

            <Text color="letter.onBox.main" fontSize="16px" marginLeft={{ base: '4px', md: '6px' }} marginRight="4px">
              <Text as="span">...</Text>
              {authData.address.slice(-5)}
            </Text>
          </Flex>
        </PopoverAnchor>
        <PopoverTrigger>
          <IconButton size="xs" aria-label="Open modal" icon={<ArrowDownIcon />} />
        </PopoverTrigger>
      </Flex>

      <PopoverContent width={{ base: `100vw`, md: 'auto' }} borderRadius={{ base: 'unset', md: 'md' }}>
        <PopoverArrow />
        <PopoverBody paddingX="22px" paddingY="12px">
          <Box color="letter.onSurface.main">
            <Text fontSize="16px" fontWeight="bold">
              You are connected with:
            </Text>

            <Flex marginTop="8px" alignItems="center">
              <RoundedIcon
                icon={<LetterAltIcon />}
                size={isSmallerThanMd ? 'big' : 'bigger'}
                background="letter.gradient.dark"
              />

              <Text
                fontSize="16px"
                marginLeft="6px"
                marginRight="8px"
                flexGrow={1}
                noOfLines={1}
                title={authData.address}
              >
                {authData.address}
              </Text>

              <IconButton
                size="sm"
                aria-label="Copy code"
                icon={<CopyIcon fill="letter.gradient.main" boxSize="18px" />}
                onClick={() => copy(authData.address)}
              />
            </Flex>

            <GradientButton label="DISCONNECT" full marginTop="22px" onClick={onDisconnect} />

            {authData.type === TAuthType.neon && (
              <>
                <Flex justifyContent="flex-end">
                  <Button
                    variant="text"
                    label="View account details"
                    color="letter.purple.light"
                    textDecoration="underline"
                    marginTop="6px"
                    textDecorationColor="letter.purple.light"
                    onClick={onOpen}
                  />
                </Flex>

                <AccountDetailsModal isOpen={isOpen} onClose={onClose} authData={authData} />
              </>
            )}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
