import { Box, Flex, Text } from '@chakra-ui/react'
import { format } from 'date-fns'

import type { TStaticCategory } from '../../types/pages/category'

type Props = {
  date: Date
  staticCategory: TStaticCategory
  isActive?: boolean
}

export const EventDateCard = ({ date, isActive = false, staticCategory }: Props) => {
  const weekDay = format(date, 'EEE')
  const day = format(date, 'dd')

  return (
    <Box>
      <Flex
        direction="column"
        alignItems="center"
        width={{ base: isActive ? '62px' : '50px', md: isActive ? '84px' : '64px' }}
        paddingY="8px"
        backgroundColor={isActive ? staticCategory.theme.surface.light : 'transparent'}
        borderRadius="4px"
      >
        <Text
          fontSize={{ base: '8px', md: '12px' }}
          textTransform="uppercase"
          fontWeight="medium"
          color={staticCategory.theme.onSurface.main}
        >
          {weekDay}
        </Text>

        <Text
          fontSize={{ base: '40px', md: '56px' }}
          fontWeight="semibold"
          color={isActive ? staticCategory.theme.onSurface.emphasis : staticCategory.theme.onSurface.disable}
          lineHeight={{ base: '40px', md: '56px' }}
        >
          {day}
        </Text>
      </Flex>

      {isActive && (
        <Box
          width="0"
          height="0"
          borderLeft={{ base: '16px solid transparent', md: '22px solid transparent' }}
          borderRight={{ base: '16px solid transparent', md: '22px solid transparent' }}
          borderTop={{ base: '10px solid', md: '16px solid' }}
          borderTopColor={{ base: staticCategory.theme.surface.light, md: staticCategory.theme.surface.light }}
          position="relative"
          left="50%"
          transform="translate(-50%)"
        />
      )}
    </Box>
  )
}
