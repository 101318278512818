import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { renderToast } from '../helpers/chakraToast'
import { useSDK } from '../hooks/useSDK'
import { useStaticCategory } from '../hooks/useStaticCategory'
import { EventCategoryLayout } from '../layouts/EventCategory'
import type { TEvent } from '../types/pages/event'

type Params = {
  categoryLabel: string
  contentProviderLabel: string
}

export const EventCategoryPage = () => {
  const { getEventsByCategory, getStatus } = useSDK()
  const { categoryLabel, contentProviderLabel } = useParams<Params>()
  const { staticCategory } = useStaticCategory({ categoryLabel, contentProviderLabel })

  const [latestEvents, setLatestEvents] = useState<TEvent[]>([])

  const featureEvent = useMemo(() => latestEvents[0], [latestEvents])
  const loading = useMemo(() => {
    const getEventsByCategoryStatus = getStatus('getEventsByCategory')

    if (!getEventsByCategoryStatus) return true

    return getEventsByCategoryStatus === 'requesting'
  }, [getStatus])

  const handlePopulate = useCallback(async () => {
    try {
      if (!contentProviderLabel || !categoryLabel) throw new Error('Missing parameters')

      const { events } = await getEventsByCategory({ contentProviderLabel, categoryLabel })

      setLatestEvents(prevState => [...prevState, ...events])
    } catch (error: any) {
      renderToast({ type: 'error', message: error.message })
    }
  }, [contentProviderLabel, categoryLabel, getEventsByCategory])

  useEffect(() => {
    handlePopulate()
  }, [handlePopulate])

  return (
    <EventCategoryLayout
      staticCategory={staticCategory}
      loading={loading}
      featureEvent={featureEvent}
      events={latestEvents}
    />
  )
}
