import { Icon, IconProps } from '@chakra-ui/react'

export const CopyIcon = ({ fill, ...props }: IconProps) => {
  return (
    <Icon viewBox="0 0 16 18" fill={fill === 'letter.gradient.main' ? 'url(#paint0_linear_169_4353)' : fill} {...props}>
      <path d="M14.2105 16.3636H5.01548V4.90909H14.2105M14.2105 3.27273H5.01548C4.57208 3.27273 4.14685 3.44513 3.83332 3.75201C3.51979 4.05888 3.34365 4.4751 3.34365 4.90909V16.3636C3.34365 16.7976 3.51979 17.2138 3.83332 17.5207C4.14685 17.8276 4.57208 18 5.01548 18H14.2105C14.6539 18 15.0792 17.8276 15.3927 17.5207C15.7062 17.2138 15.8824 16.7976 15.8824 16.3636V4.90909C15.8824 4.4751 15.7062 4.05888 15.3927 3.75201C15.0792 3.44513 14.6539 3.27273 14.2105 3.27273M11.7028 0H1.67183C1.22843 0 0.803195 0.172402 0.489667 0.47928C0.176138 0.786158 0 1.20237 0 1.63636V13.0909H1.67183V1.63636H11.7028V0Z" />
      <defs>
        <linearGradient
          id="paint0_linear_169_4353"
          x1="-5.171"
          y1="2.16279"
          x2="18.5035"
          y2="9.27449"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#26B9FF" />
          <stop offset="1" stopColor="#A3008E" />
        </linearGradient>
      </defs>
    </Icon>
  )
}
