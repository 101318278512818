import { NeonEventListener } from '@cityofzion/neon-event-listener'
import { NeonParser } from '@cityofzion/neon-parser'
import WalletConnectSDK, {
  COMPATIBILITY_VERSION,
  SUPPORTED_BLOCKCHAINS,
  SUPPORTED_METHODS,
} from '@cityofzion/wallet-connect-sdk-core'
import SignClient from '@walletconnect/sign-client'
import { Neo3Authenticator } from 'letter-sdk'
import { createContext, useCallback, useEffect, useRef, useState } from 'react'

import { NETWORK } from '../constants/blockchain'
import { walletConnectOptions } from '../constants/wallet-connect'
import { useAuth } from '../hooks/useAuth'
import { TAuthType } from '../types/contexts/auth'
import {
  EWalletConnectStatus,
  WalletConnectContextData,
  WalletConnectContextProps,
} from '../types/contexts/wallet-connect'

const INIT_TIMEOUT = 5000

const WalletConnectContext = createContext<WalletConnectContextData>({} as WalletConnectContextData)

export const WalletConnectProvider = ({ children }: WalletConnectContextProps) => {
  const { revoke, validate } = useAuth()

  const [status, setStatus] = useState<EWalletConnectStatus>(EWalletConnectStatus.starting)
  const isStarted = useRef(false)

  const sdkRef = useRef<WalletConnectSDK>()
  const blockchainLabel = useRef<string>('neo3')

  const getSDK = useCallback(() => {
    if (!sdkRef.current) throw new Error('Client not initiated')

    return sdkRef.current
  }, [])

  const getAuthenticator = useCallback(() => {
    if (!sdkRef.current) return

    const invoker = sdkRef.current
    const signer = sdkRef.current
    const parser = NeonParser
    const eventListener = new NeonEventListener(NETWORK.rpcAddress)

    return new Neo3Authenticator({ invoker, signer, parser, eventListener }, NETWORK.scriptHash)
  }, [])

  const handleValidate = useCallback(async () => {
    const sdk = getSDK()

    const address = sdk.getAccountAddress()

    if (!address) return

    validate({ type: TAuthType.wc, address, blockchainLabel: blockchainLabel.current })
  }, [getSDK, validate])

  const disconnect = useCallback(async () => {
    const sdk = getSDK()

    await sdk.disconnect()
    revoke()
  }, [revoke, getSDK])

  const connect = useCallback(async () => {
    const sdk = getSDK()

    const { uri, approval } = await sdk.signClient.connect({
      requiredNamespaces: {
        [SUPPORTED_BLOCKCHAINS[0]]: {
          chains: [NETWORK.wcIdentifier],
          methods: [...SUPPORTED_METHODS],
          events: [],
        },
      },
    })

    const uriAndWccv = `${uri}&wccv=${COMPATIBILITY_VERSION}`

    const approvalWrapper = async () => {
      await approval()
      sdk.loadSession()
      handleValidate()
    }

    return {
      uri: uriAndWccv,
      approval: approvalWrapper,
    }
  }, [getSDK, handleValidate])

  const init = useCallback(async () => {
    const abortController = new AbortController()

    setTimeout(() => {
      if (isStarted.current) return

      abortController.abort()
    }, INIT_TIMEOUT)

    return new Promise<void>(resolve => {
      abortController.signal.addEventListener('abort', () => {
        setStatus(EWalletConnectStatus.error)
        resolve()
      })

      SignClient.init(walletConnectOptions)
        .then(client => {
          if (abortController.signal.aborted) {
            resolve()
            return
          }

          const sdk = new WalletConnectSDK(client)

          sdk.loadSession()

          sdkRef.current = sdk

          setStatus(EWalletConnectStatus.started)
        })
        .catch(() => {
          setStatus(EWalletConnectStatus.error)
        })
        .finally(() => {
          isStarted.current = true
          resolve()
        })
    })
  }, [])

  useEffect(() => {
    init()
  }, [init])

  useEffect(() => {
    if (status !== EWalletConnectStatus.started) return

    const sdk = getSDK()

    if (!sdk) return

    sdk.signClient.events.removeAllListeners()
    sdk.manageDisconnect()
    sdk.signClient.events.on('session_delete', revoke)
  }, [status, revoke, getSDK])

  useEffect(() => {
    if (status !== EWalletConnectStatus.started) return

    handleValidate()
  }, [status, handleValidate])

  return (
    <WalletConnectContext.Provider
      value={{
        status,
        connect,
        disconnect,
        getAuthenticator,
      }}
    >
      {children}
    </WalletConnectContext.Provider>
  )
}

export default WalletConnectContext
