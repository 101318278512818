import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import jsPDF from 'jspdf'

import { CopyIcon } from '../../assets/components/CopyIcon'
import { DownloadIcon } from '../../assets/components/DownloadIcon'
import { useClipboard } from '../../hooks/useClipboard'
import { TAuthAuthenticatedNeonData } from '../../types/contexts/auth'
import { Button } from '../Button'
import { IconButton } from '../IconButton'
import { ModalTemplate } from '../ModalTemplate'
import { SectionHeader } from '../SectionHeader'

type Props = {
  onClose(): void
  isOpen: boolean
  authData: TAuthAuthenticatedNeonData
}

export const AccountDetailsModal = ({ isOpen, onClose, authData }: Props) => {
  const modalSize = useBreakpointValue({ base: 'full', lg: '3xl' })
  const copy = useClipboard()

  const handleDownload = () => {
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
    })

    pdf.text('YOUR AUTHENTICATION CODE:', 20, 20)
    pdf.text(authData.code, 20, 30)
    pdf.save('bip39code-letter.pdf')
  }

  return (
    <ModalTemplate size={modalSize} isOpen={isOpen} onClose={onClose} title="Account details">
      <Box marginTop="30px">
        <SectionHeader title="PUBLIC ADDRESS" />

        <Box backgroundColor="letter.surface.darkest" paddingY="24px" paddingX="32px" marginTop="18px">
          <Flex justifyContent="center" position="relative">
            <Text
              fontSize="18px"
              fontWeight="semibold"
              letterSpacing="4px"
              noOfLines={2}
              textAlign="center"
              paddingX="48px"
            >
              {authData.address}
            </Text>

            <IconButton
              aria-label="Copy"
              icon={<CopyIcon fill="letter.gradient.main" />}
              size="md"
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              right="0px"
              onClick={() => copy(authData.address)}
            />
          </Flex>
        </Box>
      </Box>

      <Box marginTop="30px">
        <SectionHeader title="AUTHENTICATION CODE" />

        <Box
          backgroundColor="letter.surface.darkest"
          paddingTop="24px"
          paddingBottom="8px"
          paddingX="32px"
          marginTop="18px"
        >
          <Flex justifyContent="center" position="relative">
            <Text
              fontSize="18px"
              fontWeight="semibold"
              letterSpacing="4px"
              noOfLines={2}
              textAlign="center"
              paddingX="48px"
            >
              {authData.code}
            </Text>

            <IconButton
              aria-label="Copy"
              icon={<CopyIcon fill="letter.gradient.main" />}
              size="md"
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              right="0px"
              onClick={() => copy(authData.code)}
            />
          </Flex>

          <Flex justifyContent="center">
            <Text as="span" fontSize="12px" fontWeight="light">
              BIP39 CODE
            </Text>
          </Flex>
        </Box>
      </Box>

      <Box marginTop="30px">
        <SectionHeader title="DOWNLOAD PDF BACK UP" />

        <Box backgroundColor="letter.surface.darkest" paddingY="24px" paddingX="32px" marginTop="18px">
          <Flex justifyContent="space-between" alignItems="center" direction={{ base: 'column', md: 'row' }}>
            <Text fontSize="18px" width={{ base: 'full', md: '45%' }}>
              Click the download button below to download a PDF document containing your code.
            </Text>

            <Button
              variant="solid"
              width={{ base: 'full', md: '45%' }}
              marginTop="18px"
              backgroundColor="letter.box.main"
              _hover={{
                color: 'letter.blue.main',
              }}
              color="letter.onBox.main"
              label="DOWNLOAD YOUR CODE"
              leftIcon={<DownloadIcon fill="letter.gradient.main" />}
              onClick={handleDownload}
            />
          </Flex>
        </Box>
      </Box>
    </ModalTemplate>
  )
}
