import { Flex, Text } from '@chakra-ui/react'

import { GradientCalendarCheckIcon } from '../../assets/components/GradientCalendarCheckIcon'
import { GradientButton } from '../GradientButton'

type Props = {
  onClose(): void
}

export const EventPaymentSuccess = ({ onClose }: Props) => {
  return (
    <Flex alignItems="center" height="full" direction="column" textAlign="center" maxWidth="346px">
      <GradientCalendarCheckIcon marginTop="36px" />
      <Text color="letter.gray.darker" fontWeight="bold" fontSize="24px" marginTop="20px" marginBottom="5px">
        Congratulations!
      </Text>
      <Text color="letter.gray.darker" fontWeight="bold" fontSize="18px" marginBottom="9px">
        You have successfully purchased access to this event
      </Text>
      <Flex marginBottom="34px">
        <Text color="letter.gray.darker" fontSize="18px" lineHeight="24px">
          Close this window and select the{' '}
          <Text as="span" color="letter.pink.light" fontWeight="semibold">
            “Join the discussion”
          </Text>{' '}
          button on the event page to continue.
        </Text>
      </Flex>
      <GradientButton label="CLOSE" full onClick={onClose} width="103px" />
    </Flex>
  )
}
