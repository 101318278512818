import { Box, Flex, Heading, Image, Link, LinkProps, Text } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { EventHelper } from '../../helpers/event'
import type { TStaticCategory } from '../../types/pages/category'
import type { TEvent } from '../../types/pages/event'
import { ComingSoonTag } from '../ComingSoonTag'

type Props = {
  event: TEvent
  staticCategory: TStaticCategory
  withResponsiveness?: boolean
} & Omit<LinkProps, 'to' | 'as'>

export const EventCard = ({ event, staticCategory, withResponsiveness = false, ...props }: Props) => {
  const isComingSoon = EventHelper.isComingSoon(event)

  return (
    <Link
      display="block"
      as={RouterLink}
      width={!withResponsiveness ? '320px' : { base: '210px', lg: '320px' }}
      to={`/object/${staticCategory.contentProviderLabel}/${staticCategory.label}/${event.label}`}
      role="group"
      borderRadius="4px"
      _hover={{
        textDecoration: 'unset',
        boxShadow: `0px 0px 22px ${staticCategory.theme.primary400}`,
      }}
      {...props}
    >
      <Box position="relative">
        <Image
          src={event.thumbnailImage}
          width="full"
          height={!withResponsiveness ? '210px' : { base: '138px', lg: '210px' }}
          borderRadius="4px"
          objectFit="cover"
        />

        {isComingSoon && (
          <ComingSoonTag
            staticCategory={staticCategory}
            position="absolute"
            right="0px"
            bottom={{ base: '8px', lg: '18px' }}
          />
        )}
      </Box>

      <Flex alignItems="center" gridColumnGap="28px" padding="12px">
        <Box minWidth="0">
          <Heading
            color={staticCategory.theme.primary}
            as="h6"
            fontSize={!withResponsiveness ? '18px' : { base: '16px', lg: '18px' }}
            fontWeight="semibold"
            noOfLines={1}
            title={event.thumbnailTitle ?? event.title}
          >
            {event.thumbnailTitle ?? event.title}
          </Heading>

          <Text fontSize="14px" fontWeight="light" noOfLines={2}>
            {event.thumbnailDescription ?? event.description}
          </Text>
        </Box>
      </Flex>
    </Link>
  )
}
