import { Box, Link, Text } from '@chakra-ui/react'
import { NavLink as RRDNavLink } from 'react-router-dom'

type Props = {
  to: string
  label: string
  isSub?: boolean
  children?: React.ReactNode
}

export const AboutNavLink = ({ label, to, isSub = false, children }: Props) => {
  return (
    <Box
      borderBottom={isSub ? 'none' : '1px solid'}
      borderBottomColor="letter.onSurface.divider"
      paddingTop="18px"
      paddingBottom={isSub ? '0px' : '18px'}
    >
      <Link
        as={RRDNavLink}
        to={to}
        end
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        color="letter.onSurface.disabled"
        fontWeight="semibold"
        fontSize="18px"
        _hover={{
          color: 'letter.onSurface.main',
        }}
        sx={{
          '&.active': {
            color: 'letter.onSurface.main',
          },

          '&.active > .active-icon': {
            display: 'inline-block',
          },
        }}
      >
        <Text>{label}</Text>
      </Link>

      <Box paddingLeft="18px">{children}</Box>
    </Box>
  )
}
