import { Box, Flex } from '@chakra-ui/react'
import { Link as RRDLink } from 'react-router-dom'

import { LetterLogo } from '../../assets/components/LetterLogo'
import { DEFAULT_CATEGORY_ROUTE } from '../../constants/categories'
import { useMultiAuth } from '../../hooks/useMultiAuth'
import { Link } from '../Link'
import { Login } from './Login'
import { MobileNav } from './MobileNav'

export const Header = () => {
  const { authData } = useMultiAuth()

  return (
    <Flex
      height="72px"
      alignItems="center"
      width="full"
      paddingX={{ base: '16px', md: '32px', '2xl': '72px' }}
      justifyContent="space-between"
      backgroundColor="letter.box.main"
    >
      <RRDLink to={DEFAULT_CATEGORY_ROUTE}>
        <LetterLogo width={{ base: '124px', md: 'unset' }} />
      </RRDLink>

      <Flex gridColumnGap={{ base: '12px', md: '40px' }}>
        <Flex as="nav" alignItems="center" gridColumnGap="10px" display={{ base: 'none', md: 'flex' }}>
          <Link to={DEFAULT_CATEGORY_ROUTE} label="Home" variant="text" color="letter.onBox.main" />
          <Link to="/about" label="About letter" variant="text" color="letter.onBox.main" />

          {!authData.isAuthenticated &&
            (
            <>
              <Flex justifyContent="center">
                <Box height="30px" width="1px" backgroundColor="letter.onBox.main" />
              </Flex>
              <Link to="/signup" label="Sign Up" variant="text" color="letter.onBox.main" />
            </>
            )
          }
          
        </Flex>

        <Login />

        <MobileNav />
      </Flex>
    </Flex>
  )
}
