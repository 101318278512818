import { Icon, IconProps } from '@chakra-ui/react'

export const ArrowRightAltIcon = ({ fill = 'white', ...props }: IconProps) => {
  return (
    <Icon
      viewBox="0 0 16 16"
      fill={fill === 'letter.gradient.main' ? 'url(#paint0_linear_1101_4629)' : fill}
      {...props}
    >
      <path d="M0 6.92V8.92H12L6.5 14.42L7.92 15.84L15.84 7.92L7.92 0L6.5 1.42L12 6.92H0Z" />

      <defs>
        <linearGradient
          id="paint0_linear_1101_4629"
          x1="-1.58831e-07"
          y1="4.29767"
          x2="15.84"
          y2="15.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072" />
          <stop offset="1" stopColor="#370097" />
        </linearGradient>
      </defs>
    </Icon>
  )
}
