import { Box, Flex, Hide, Spinner, Text, useBreakpointValue, useToken } from '@chakra-ui/react'
import { QRCodeCanvas } from 'qrcode.react'
import { useEffect, useState } from 'react'

import { AnchorIcon } from '../../assets/components/AnchorIcon'
import { ChainIcon } from '../../assets/components/ChainIcon'
import { CopyIcon } from '../../assets/components/CopyIcon'
import { QRCodeIcon } from '../../assets/components/QRCodeIcon'
import { WalletIcon } from '../../assets/components/WalletIcon'
import { useClipboard } from '../../hooks/useClipboard'
import { Button } from '../Button'
import { Link } from '../Link'
import { ModalTemplate } from '../ModalTemplate'
import { RoundedIcon } from '../RoundedIcon'
import { SectionHeader } from '../SectionHeader'

type Props = {
  onClose(): void
  isOpen: boolean
  onLogin: () => Promise<string>
}

export const LoginNeoWalletConnectionModal = ({ isOpen, onClose, onLogin }: Props) => {
  const modalSize = useBreakpointValue({ base: 'full', md: '2xl' })
  const surfaceDarkest = useToken('colors', 'letter.surface.darkest')
  const copy = useClipboard()

  const [uri, setUri] = useState<string>()

  const base64URI = uri ? Buffer.from(uri).toString('base64') : undefined

  useEffect(() => {
    if (!isOpen) return

    onLogin().then(setUri)
  }, [isOpen, onLogin])

  return (
    <ModalTemplate size={modalSize} isOpen={isOpen} onClose={onClose} title="Login">
      {!!uri && (
        <Box marginTop="34px">
          <SectionHeader icon={<WalletIcon />} title="WALLET CONNECTION" />

          <Flex marginTop="28px" backgroundColor="letter.surface.darkest" padding="18px" gridColumnGap="48px">
            <Box>
              <SectionHeader icon={<QRCodeIcon />} iconSize={{ base: '22px', sm: '38px' }} title="QR CODE CONNECT" />

              <Hide above="sm">
                <Flex marginTop="34px" justifyContent="center">
                  <QRCodeCanvas bgColor={surfaceDarkest} size={196} value={uri} />
                </Flex>
              </Hide>

              <Text fontSize="18px" textAlign={{ base: 'center', sm: 'unset' }} marginTop={{ base: '24px', sm: '8px' }}>
                Please scan QR code to connect your wallet on a compatible device
              </Text>
            </Box>
            <Hide below="sm">
              <QRCodeCanvas bgColor={surfaceDarkest} size={196} value={uri} />
            </Hide>
          </Flex>

          <Box marginTop="28px">
            <SectionHeader title="DEEPLINK CONNECT" />

            <Text fontSize="16px">
              Ensure your Neon Wallet is open on the correct account then click the deeplink button below to connect
            </Text>

            <Link
              to={`neon://uri=${base64URI}`}
              isExternal
              label="LINK WALLET"
              full
              size="big"
              variant="outline"
              marginTop="24px"
              leftIcon={<RoundedIcon icon={<AnchorIcon />} background="letter.gradient.dark" />}
              leftIconSize="big"
            />
          </Box>

          <Box marginTop="28px">
            <SectionHeader title="CONNECTION URL" />

            <Text fontSize="16px">Copy and paste the connection URL into the Add connection page in your wallet</Text>

            <Button
              label={uri}
              full
              size="big"
              variant="outline"
              marginTop="24px"
              leftIcon={<RoundedIcon icon={<ChainIcon />} background="letter.gradient.dark" />}
              leftIconSize="big"
              rightIcon={<CopyIcon fill="letter.gradient.main" />}
              onClick={() => copy(uri)}
            />
          </Box>

          <Flex justifyContent="center" marginTop="28px">
            <Button
              label="Change login method"
              variant="text"
              textStyles={{
                color: 'letter.purple.dark',
                textTransform: 'unset',
                textDecoration: 'underline',
              }}
              onClick={onClose}
            />
          </Flex>
        </Box>
      )}

      {!uri && (
        <Flex height="550px" alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      )}
    </ModalTemplate>
  )
}
