import { AxiosError, AxiosRequestConfig } from 'axios'

type TMETHOD = 'POST' | 'GET' | 'PUT' | 'DELETE'

export type TLazyFetchOptions = {
  endpoint: string
  method: TMETHOD
  options?: Omit<AxiosRequestConfig, 'url' | 'baseUrl' | 'data' | 'params' | 'method'>
}

export type TLazyFetchData = Pick<AxiosRequestConfig, 'data' | 'params'>

export type TEagerFetchOptions = {
  endpoint: string
  method: TMETHOD
  options?: Omit<AxiosRequestConfig, 'url' | 'baseUrl' | 'method'>
}

export enum ERequestType {
  request = 'request',
  success = 'success',
  failure = 'failure',
}

export enum ERequestStatus {
  init = 'init',
  error = 'error',
  fetched = 'fetched',
  fetching = 'fetching',
}

export type TStateSuccess<T> = {
  status: ERequestStatus.fetched
  data: T
}

export type TStateFailure = {
  status: ERequestStatus.error
  error: AxiosError
}

export type TStateRequest = {
  status: ERequestStatus.fetching
}

export type TStateInit = {
  status: ERequestStatus.init
}

export type TState<T> = TStateSuccess<T> | TStateFailure | TStateRequest | TStateInit

export type TAction<T> =
  | { type: ERequestType.request }
  | { type: ERequestType.success; payload: T }
  | { type: ERequestType.failure; error: AxiosError }
