import 'github-markdown-css/github-markdown.css'

import { Box, Flex, Heading, Hide } from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { DiscordAltIcon } from '../../assets/components/DiscordAltIcon'
import { FilesIcon } from '../../assets/components/FilesIcon'
import { sanitize } from '../../helpers/sanitizeHTML'
import type { TStaticCategory } from '../../types/pages/category'
import type { TEvent } from '../../types/pages/event'
import { SectionHeader } from '../SectionHeader'
import { Tag } from '../Tag'
import { EventDates } from './EventDates'
import { EventPresenters } from './EventPresenters'

type Props = {
  event: TEvent
  staticCategory: TStaticCategory
}

export const EventDetails = ({ event, staticCategory }: Props) => {
  const sanitized = sanitize(event.details)

  return (
    <Flex
      direction="column"
      gridRowGap={{ base: '20px', xl: '54px' }}
      width="100%"
      marginLeft={{ base: 'unset', xl: 'min(10%, 225px)' }}
    >
      <Box>
        <Flex alignItems="center" justifyContent="space-between">
          <SectionHeader
            title="EVENT DETAILS"
            icon={<FilesIcon />}
            iconColor={staticCategory.theme.onSurface.emphasis}
          />

          {event.type === 'online' && (
            <Hide above="xl">
              <Tag icon={<DiscordAltIcon boxSize="24px" fill="letter.onBox.main" />} label="Discord Event" />
            </Hide>
          )}
        </Flex>

        <Flex alignItems="center" marginTop="26px" justifyContent="space-between">
          <Heading as="h2" fontSize={{ base: '32px', xl: '40px' }} fontWeight="semibold">
            {event.title}
          </Heading>

          {event.type === 'online' && (
            <Hide below="xl">
              <Tag icon={<DiscordAltIcon boxSize="24px" fill="letter.onBox.main" />} label="Discord Event" />
            </Hide>
          )}
        </Flex>
      </Box>

      {event.datetime && (
        <EventDates datetime={event.datetime} duration={event.duration} staticCategory={staticCategory} />
      )}

      <Box
        className="markdown-body"
        backgroundColor="transparent"
        color={staticCategory.theme.onSurface.main}
        fontSize="18px"
        sx={{
          '& .bigger': {
            fontSize: '90px',
            margin: '0 18px',
            fontWeight: 'bold',
            float: 'left',
            lineHeight: '72px',
          },

          '& .emphasis': {
            color: staticCategory.theme.onSurface.emphasis,
          },

          '& .brand': {
            color: staticCategory.theme.brand,
          },

          '& .font-22': {
            fontSize: '22px',
          },

          '& .font-light': {
            fontWeight: 'light',
          },
        }}
      >
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{sanitized}</ReactMarkdown>
      </Box>

      <EventPresenters event={event} staticCategory={staticCategory} />
    </Flex>
  )
}
