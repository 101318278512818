import { Flex, Hide, Image, Link as ChakraLink, Text } from '@chakra-ui/react'

import { DownloadAltIcon } from '../../assets/components/DownloadAltIcon'
import { RoundedIcon } from '../RoundedIcon'

export type LinkProps = {
  leftIcon: string
  label: string
  to: string
}

export const Web3Link = ({ label, to, leftIcon }: LinkProps) => {
  return (
    <ChakraLink
      href={to}
      isExternal
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      color="letter.onSurface.main"
      backgroundColor="transparent"
      border="1px solid"
      borderColor="letter.onSurface.divider"
      paddingX="20px"
      paddingY="10px"
      borderRadius="6px"
      height="57px"
      _hover={{ backgroundColor: 'letter.button.hover' }}
    >
      <Flex>
        <Image src={leftIcon} width="30px" objectFit="contain" />

        <Text width="150px" fontSize="18px" fontWeight="bold" paddingX="16px">
          {label}
        </Text>

        <Hide below="xl">
          <Text fontSize="18px" background="letter.gradient.main" backgroundClip="text">
            {to}
          </Text>
        </Hide>
      </Flex>

      <RoundedIcon icon={<DownloadAltIcon />} background="letter.gradient.dark" iconColor="letter.onBox.main" />
    </ChakraLink>
  )
}
