import { Box, Flex, Heading, Hide, Image, Text } from '@chakra-ui/react'

import { CredentialsIcon } from '../../assets/components/CredentialIcon'
import { HomeIcon } from '../../assets/components/HomeIcon'
import { EventHelper } from '../../helpers/event'
import type { TStaticCategory } from '../../types/pages/category'
import type { TEvent } from '../../types/pages/event'
import { Breadcrumb } from '../Breadcrumb'
import { ComingSoonTag } from '../ComingSoonTag'
import { Tag } from '../Tag'
import { EventPartner } from './EventPartner'

type Props = {
  event: TEvent
  staticCategory: TStaticCategory
  hasAccess?: boolean
}

export const EventInfo = ({ event, hasAccess, staticCategory }: Props) => {
  const isComingSoon = EventHelper.isComingSoon(event)

  return (
    <Box
      maxWidth="2178px"
      minHeight="33vh"
      width="100%"
      paddingTop="18px"
      paddingBottom="32px"
      paddingX={{ base: '16px', md: '32px', lg: 'min(154px, 8%)' }}
      zIndex={2}
      color="letter.onBox.main"
    >
      <Flex direction="column" height="full">
        <Flex
          flexGrow={1}
          width="full"
          justifyContent={{ base: 'flex-end', md: 'space-between' }}
          alignItems="flex-start"
        >
          <Hide below="md">
            <Breadcrumb
              items={[
                {
                  label: <HomeIcon boxSize="18px" />,
                  to: '/',
                },
                {
                  label: staticCategory.title,
                  to: `/category/${staticCategory.contentProviderLabel}/${staticCategory.label}`,
                },
                {
                  label: event.title,
                  to: `/object/${staticCategory.contentProviderLabel}/${staticCategory.label}/${event.label}`,
                },
              ]}
            />
          </Hide>

          {staticCategory.logoURL && <Image src={staticCategory.logoURL} width="84px" objectFit="contain" />}
        </Flex>

        <Box marginTop="40px">
          {!isComingSoon && hasAccess && (
            <Tag
              backgroundColor={staticCategory.theme.brand}
              marginBottom="12px"
              icon={<CredentialsIcon />}
              label="You are signed up to this event "
            />
          )}

          {isComingSoon && <ComingSoonTag staticCategory={staticCategory} />}

          <Heading
            as="h1"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize={{ base: '36px', md: '48px', xl: '58px' }}
            noOfLines={3}
            title={event.title}
          >
            {event.title}
          </Heading>

          <Box
            width="60px"
            height="8px"
            backgroundColor={staticCategory.theme.brand}
            marginTop="12px"
            marginBottom="30px"
          />
        </Box>

        <Flex alignItems={{ base: 'unset', md: 'center' }} direction={{ base: 'column', md: 'row' }}>
          <Text fontSize="16px" noOfLines={3} maxWidth={{ base: '80%', md: '40%' }}>
            {event.description}
          </Text>

          {staticCategory.partner && (
            <Flex justifyContent="flex-end" flexGrow={1} marginTop={{ base: '32px', md: 'unset' }}>
              <EventPartner title={staticCategory.partner.title} imageURL={staticCategory.partner.imageURL} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
