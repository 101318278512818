import { useDisclosure } from '@chakra-ui/react'
import { FlowWalletService } from 'letter-sdk/dist/utils/FlowAdapter'
import { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { renderToast } from '../helpers/chakraToast'
import { useMultiAuth } from '../hooks/useMultiAuth'
import { SignUpLayout } from '../layouts/SignUp'
import { TAuthType } from '../types/contexts/auth'

const messageByAuthType: Record<TAuthType, string> = {
  [TAuthType.flow]: 'You are connected with Flow!',
  [TAuthType.wc]: 'You are connected with WalletConnect!',
  [TAuthType.neon]: 'You are connected!',
}

export const SignUpPage = () => {
  const {
    isOpen: neoWalletConnectionModalIsOpen,
    onClose: onCloseNeoWalletConnectionModal,
    onOpen: onOpenNeoWalletConnectionModal,
  } = useDisclosure()

  const {
    isOpen: createdAccountModalIsOpen,
    onClose: onCloseCreatedAccountModal,
    onOpen: onOpenCreatedAccountModal,
  } = useDisclosure()

  const { flowConnect, neonConnect, walletConnectConnect, neonCreate, authData } = useMultiAuth()

  const navigation = useNavigate()
  const [searchParams] = useSearchParams()

  const handleLoadCode = useCallback(async () => {
    const code = await neonCreate()

    return code
  }, [neonCreate])

  const handleLogin = async (code: string) => {
    neonConnect(code).catch(error => renderToast({ type: 'error', message: error.message }))
  }

  const handleLoginNeoWallet = useCallback(async () => {
    const { approval, uri } = await walletConnectConnect()

    approval()
      .catch(error => renderToast({ type: 'error', message: error.message }))
      .finally(() => onCloseNeoWalletConnectionModal())

    return uri ?? ''
  }, [walletConnectConnect, onCloseNeoWalletConnectionModal])

  const handleLoginFlowWallet = async (service: FlowWalletService) => {
    flowConnect(service).catch(error => renderToast({ type: 'error', message: error.message }))
  }

  useEffect(() => {
    if (!authData.isAuthenticated) return

    const redirectTo = searchParams.get('redirect_to')

    renderToast({ type: 'success', message: messageByAuthType[authData.type], id: `${authData.type}-success-auth` })
    navigation(redirectTo ?? '/')
  }, [authData, navigation, searchParams])

  return (
    <SignUpLayout
      onLogin={handleLogin}
      createdAccountModalIsOpen={createdAccountModalIsOpen}
      onCloseCreatedAccountModal={onCloseCreatedAccountModal}
      onLoadCodeCreatedAccountModal={handleLoadCode}
      onOpenCreatedAccountModal={onOpenCreatedAccountModal}
      neoWalletConnectionModalIsOpen={neoWalletConnectionModalIsOpen}
      onCloseNeoWalletConnectionModal={onCloseNeoWalletConnectionModal}
      onOpenNeoWalletConnectionModal={onOpenNeoWalletConnectionModal}
      onLoginNeoWalletConnectionModal={handleLoginNeoWallet}
      onLoginFlowWallet={handleLoginFlowWallet}
    />
  )
}
