export const global = {
  fonts: {
    body: "'Outfit', sans-serif",
    heading: "'Outfit', sans-serif",
  },
  styles: {
    global: {
      'html, body': {
        fontSize: '16px',
        scrollBehavior: 'smooth',
      },
      body: {
        overflowX: 'hidden',
      },

      '*': {
        fontFamily: "'Outfit', sans-serif !important",
      },
    },
  },
}
