import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const ChainIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="white" {...props}>
      <path d="M8.59001 11.4103C9.00001 11.8003 9.00001 12.4403 8.59001 12.8303C8.20001 13.2203 7.56001 13.2203 7.17001 12.8303C5.22001 10.8803 5.22001 7.71031 7.17001 5.76031L10.71 2.22031C12.66 0.270313 15.83 0.270313 17.78 2.22031C19.73 4.17031 19.73 7.34031 17.78 9.29031L16.29 10.7803C16.3 9.96031 16.17 9.14031 15.89 8.36031L16.36 7.88031C17.54 6.71031 17.54 4.81031 16.36 3.64031C15.19 2.46031 13.29 2.46031 12.12 3.64031L8.59001 7.17031C7.41001 8.34031 7.41001 10.2403 8.59001 11.4103ZM11.41 7.17031C11.8 6.78031 12.44 6.78031 12.83 7.17031C14.78 9.12031 14.78 12.2903 12.83 14.2403L9.29001 17.7803C7.34001 19.7303 4.17001 19.7303 2.22001 17.7803C0.270007 15.8303 0.270007 12.6603 2.22001 10.7103L3.71001 9.22031C3.70001 10.0403 3.83001 10.8603 4.11001 11.6503L3.64001 12.1203C2.46001 13.2903 2.46001 15.1903 3.64001 16.3603C4.81001 17.5403 6.71001 17.5403 7.88001 16.3603L11.41 12.8303C12.59 11.6603 12.59 9.76031 11.41 8.59031C11 8.20031 11 7.56031 11.41 7.17031Z" />
    </Icon>

  );
});
