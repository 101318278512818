import { Icon, IconProps } from '@chakra-ui/react'
import { memo } from 'react'

export const GradientAuthorizationIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 19 16" {...props}>
      <path
        d="M9.99964 15.3206C9.53983 15.3206 9.08036 15.2775 8.62464 15.1908C7.82772 15.0396 7.0666 14.7607 6.3624 14.3622C5.64525 13.9562 5.00717 13.4374 4.4659 12.8197C4.40693 12.7525 4.41375 12.6502 4.48123 12.5914C4.54872 12.5326 4.65132 12.5394 4.71029 12.6067C6.86482 15.0661 10.3926 15.7113 13.2892 14.1763C14.4198 13.577 15.3629 12.6842 16.0164 11.5946C16.0624 11.5178 16.1622 11.4927 16.2389 11.5385C16.316 11.5844 16.3412 11.684 16.2952 11.7604C15.6114 12.9009 14.6247 13.8352 13.4416 14.4621C12.6903 14.8602 11.8859 15.1235 11.0515 15.2445C10.7021 15.2951 10.3507 15.3206 9.99929 15.3206H9.99964Z"
        fill={'url(#paint0_linear_694_575)'}
      />
      <path
        d="M5.89579 12.7303L4.81154 12.9715L4.23311 13.917L3.65332 11.3594L5.89579 12.7303Z"
        fill="url(#paint1_linear_694_575)"
      />
      <path
        d="M3.83135 4.48274C3.80306 4.48274 3.77443 4.47527 3.74818 4.45964C3.67115 4.41377 3.64592 4.31423 3.69194 4.23779C4.37569 3.09726 5.36246 2.16295 6.54556 1.53612C7.29679 1.13794 8.10121 0.874637 8.93561 0.753687C9.74207 0.636814 10.5588 0.654821 11.3628 0.807707C12.1597 0.958894 12.9209 1.23783 13.6251 1.63635C14.3422 2.04234 14.9803 2.56114 15.5216 3.1788C15.5805 3.24607 15.5737 3.34833 15.5062 3.40711C15.4387 3.46588 15.3361 3.45909 15.2772 3.39182C13.1223 0.932394 9.59482 0.286875 6.69826 1.82185C5.56765 2.42116 4.62451 3.31402 3.9711 4.40358C3.94076 4.4542 3.88691 4.4824 3.83169 4.4824L3.83135 4.48274Z"
        fill="url(#paint2_linear_694_575)"
      />
      <path
        d="M14.0908 3.26647L15.1751 3.02525L15.7535 2.08008L16.3333 4.63735L14.0908 3.26647Z"
        fill="url(#paint3_linear_694_575)"
      />
      <path
        d="M3.02441 10.4237C1.6835 10.4237 0.592773 9.33649 0.592773 7.99992C0.592773 6.66336 1.6835 5.57617 3.02441 5.57617C4.36533 5.57617 5.45606 6.66336 5.45606 7.99992C5.45606 9.33649 4.36533 10.4237 3.02441 10.4237V10.4237ZM3.02441 5.89961C1.86279 5.89961 0.917606 6.84173 0.917606 7.99958C0.917606 9.15744 1.86279 10.0996 3.02441 10.0996C4.18604 10.0996 5.13122 9.15744 5.13122 7.99958C5.13122 6.84173 4.18604 5.89961 3.02441 5.89961V5.89961Z"
        fill="url(#paint4_linear_694_575)"
      />
      <path
        d="M2.76583 9.23871C2.71947 9.23871 2.67482 9.219 2.64414 9.18401L2.07867 8.54495C2.01936 8.47802 2.02584 8.37575 2.09299 8.3163C2.16013 8.25718 2.26273 8.26364 2.32238 8.33057L2.7464 8.80995L3.8569 7.11258C3.90598 7.03783 4.00653 7.01643 4.08152 7.06535C4.15651 7.11428 4.17798 7.2145 4.1289 7.28924L2.90183 9.16465C2.87422 9.20711 2.8282 9.23395 2.77742 9.23769C2.77333 9.23769 2.76958 9.23803 2.76549 9.23803L2.76583 9.23871Z"
        fill="url(#paint5_linear_694_575)"
      />
      <path
        d="M10.5996 9.65644H9.1997C9.1196 9.65644 9.04495 9.61363 9.00507 9.54432L8.3053 8.33584C8.26542 8.26653 8.26542 8.18092 8.3053 8.11161L9.00507 6.90313C9.04495 6.83416 9.1196 6.79102 9.1997 6.79102H10.5996C10.6797 6.79102 10.7543 6.83382 10.7942 6.90313L11.494 8.11161C11.5339 8.18092 11.5339 8.26653 11.494 8.33584L10.7942 9.54432C10.7543 9.61329 10.6797 9.65644 10.5996 9.65644ZM9.2573 9.33266H10.5416L11.1838 8.22407L10.5416 7.11513H9.2573L8.61514 8.22407L9.2573 9.33266V9.33266Z"
        fill="url(#paint6_linear_694_575)"
      />
      <path
        d="M15.4439 6.24805C15.327 6.24805 15.2323 6.34275 15.2323 6.45958V6.45958C15.2323 6.5764 15.327 6.67111 15.4439 6.67111H17.9645V7.09416H16.7082C16.4779 7.09416 16.2894 7.28454 16.2894 7.51722V8.7864C16.2894 9.01908 16.4779 9.20946 16.7082 9.20946H17.9645V9.63252H15.033V7.23175C15.033 7.11611 14.9393 7.02236 14.8237 7.02236V7.02236C14.708 7.02236 14.6143 7.11611 14.6143 7.23175V9.63252C14.6143 9.8652 14.8006 10.0556 15.033 10.0556H17.9645C18.1949 10.0556 18.3833 9.8652 18.3833 9.63252V9.15023C18.5069 9.0762 18.5927 8.94293 18.5927 8.7864V7.51722C18.5927 7.36069 18.5069 7.22743 18.3833 7.15339V6.67111C18.3833 6.43842 18.1949 6.24805 17.9645 6.24805H15.4439ZM16.7082 7.51722H18.1739V8.7864H16.7082V7.51722ZM17.3364 7.83452C17.2531 7.83452 17.1732 7.86795 17.1143 7.92745C17.0554 7.98696 17.0223 8.06766 17.0223 8.15181C17.0223 8.23596 17.0554 8.31667 17.1143 8.37617C17.1732 8.43568 17.2531 8.46911 17.3364 8.46911C17.4197 8.46911 17.4996 8.43568 17.5585 8.37617C17.6174 8.31667 17.6505 8.23596 17.6505 8.15181C17.6505 8.06766 17.6174 7.98696 17.5585 7.92745C17.4996 7.86795 17.4197 7.83452 17.3364 7.83452Z"
        fill="url(#paint7_linear_694_575)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_694_575"
          x1="4.42578"
          y1="12.548"
          x2="7.36859"
          y2="19.2502"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072" />
          <stop offset="1" stopColor="#370097" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_694_575"
          x1="3.65332"
          y1="12.0533"
          x2="6.09133"
          y2="13.6109"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072" />
          <stop offset="1" stopColor="#370097" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_694_575"
          x1="3.66895"
          y1="1.7101"
          x2="6.61174"
          y2="8.41241"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072" />
          <stop offset="1" stopColor="#370097" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_694_575"
          x1="14.0908"
          y1="2.77391"
          x2="16.5286"
          y2="4.33163"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072" />
          <stop offset="1" stopColor="#370097" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_694_575"
          x1="0.592773"
          y1="6.89139"
          x2="5.44508"
          y2="10.4387"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072" />
          <stop offset="1" stopColor="#370097" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_694_575"
          x1="2.03809"
          y1="7.63587"
          x2="4.21071"
          y2="9.15967"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072" />
          <stop offset="1" stopColor="#370097" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_694_575"
          x1="8.27539"
          y1="7.56846"
          x2="11.2314"
          y2="10.0104"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072" />
          <stop offset="1" stopColor="#370097" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_694_575"
          x1="14.6143"
          y1="7.2811"
          x2="18.47"
          y2="10.2168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E00072" />
          <stop offset="1" stopColor="#370097" />
        </linearGradient>
      </defs>
    </Icon>
  )
})
