import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const MapIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 16 18" fill="white" {...props}>
      <path d="M9.70588 8.55C9.99556 8.55 10.2824 8.4918 10.55 8.37873C10.8177 8.26566 11.0608 8.09992 11.2657 7.89099C11.4705 7.68206 11.633 7.43402 11.7439 7.16104C11.8547 6.88806 11.9118 6.59547 11.9118 6.3C11.9118 5.70326 11.6794 5.13097 11.2657 4.70901C10.852 4.28705 10.2909 4.05 9.70588 4.05C9.12085 4.05 8.55977 4.28705 8.14609 4.70901C7.7324 5.13097 7.5 5.70326 7.5 6.3C7.5 6.59547 7.55706 6.88806 7.66791 7.16104C7.77877 7.43402 7.94125 7.68206 8.14609 7.89099C8.55977 8.31295 9.12085 8.55 9.70588 8.55ZM9.70588 0C13.1118 0 15.8824 2.817 15.8824 6.3C15.8824 11.025 9.70588 18 9.70588 18C9.70588 18 3.52941 11.025 3.52941 6.3C3.52941 4.62914 4.18015 3.02671 5.33846 1.84523C6.49677 0.663748 8.06778 0 9.70588 0M1.76471 6.3C1.76471 10.35 6.24706 15.894 7.05882 16.929L6.17647 18C6.17647 18 0 11.025 0 6.3C0 3.447 1.86176 1.035 4.41176 0.261C2.78824 1.746 1.76471 3.897 1.76471 6.3Z" />
    </Icon>
  );
});
