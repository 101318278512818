import { Neo3Authenticator } from 'letter-sdk'
import { ReactNode } from 'react'

export enum ENeontatus {
  starting = 'starting',
  started = 'started',
}

export type TNeonLocalStorageData = {
  type: 'connect' | 'disconnect' | 'closed'
  code?: string
}

export interface NeonContextData {
  connect(base58: string): Promise<void>
  getAuthenticator(): Promise<Neo3Authenticator>
  create(): Promise<string>
  disconnect(): Promise<void>
  status: ENeontatus
}

export type NeonContextProps = {
  children: ReactNode
}
