import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { renderToast } from '../helpers/chakraToast'
import { useSDK } from '../hooks/useSDK'
import { NFTLayout } from '../layouts/NFT'
import { TGetObjectResponse, TPropertiesResponse } from '../types/helpers/letterSDK'

type TParams = {
  tokenId: string
  blockchainLabel: string
}

export const NFTPage = () => {
  const { getNFTProperties, getStatus, getObject } = useSDK()
  const { tokenId, blockchainLabel } = useParams<TParams>()
  const navigate = useNavigate()

  const [properties, setProperties] = useState<TPropertiesResponse>()
  const [object, setObject] = useState<TGetObjectResponse>()

  const loading = useMemo(
    () => getStatus('getNFTProperties') === 'requesting' || getStatus('getObject') === 'requesting',
    [getStatus]
  )

  const handlePopulate = useCallback(async () => {
    try {
      if (!tokenId || !blockchainLabel) throw new Error('Missing params')

      const response = await getNFTProperties({ tokenId, blockchainLabel })

      if (response.attributes.objectLabel) {
        const objectResponse = await getObject({
          objectLabel: response.attributes.objectLabel,
          contentProviderLabel: response.attributes.contentProviderLabel,
        })
        setObject(objectResponse)
      }

      setProperties(response)
    } catch (error: any) {
      renderToast({ type: 'error', message: 'License not found' })
      navigate('/')
    }
  }, [setProperties, tokenId, navigate, getNFTProperties, getObject, blockchainLabel])

  useEffect(() => {
    handlePopulate()
  }, [handlePopulate])

  return <NFTLayout properties={properties} loading={loading} object={object} />
}
