import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const CalendarIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 17 18" fill="white" {...props}>
      <path d="M14.4 1.8H13.5V0H11.7V1.8H4.5V0H2.7V1.8H1.8C0.81 1.8 0 2.601 0 3.6V16.2C0 17.199 0.81 18 1.8 18H14.4C15.399 18 16.2 17.199 16.2 16.2V3.6C16.2 2.601 15.399 1.8 14.4 1.8ZM14.4 16.2H1.8V7.2H14.4V16.2ZM14.4 5.4H1.8V3.6H14.4M3.6 9H8.1V13.5H3.6" />
    </Icon>
  );
});
