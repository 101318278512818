import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const OutlookCalendarIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 18" {...props}>
      <path
        d="M10.7998 4.05078H17.3397C17.7042 4.05078 17.9998 4.34643 17.9998 4.71093V13.2906C17.9998 13.6551 17.7042 13.9508 17.3397 13.9508H10.7998V4.05078Z"
        fill="#1976D2"
      />
      <path d="M17.0998 6.28125H10.7998V13.2751H17.0998V6.28125Z" fill="white" />
      <path d="M10.35 18L0 15.975V2.025L10.35 0V18Z" fill="#1976D2" />
      <path
        d="M5.0626 5.625C3.6334 5.625 2.4751 7.1361 2.4751 9C2.4751 10.8639 3.6334 12.375 5.0626 12.375C6.4918 12.375 7.6501 10.8639 7.6501 9C7.6501 7.1361 6.4918 5.625 5.0626 5.625ZM4.9501 11.025C4.20445 11.025 3.6001 10.1182 3.6001 9C3.6001 7.88175 4.20445 6.975 4.9501 6.975C5.69575 6.975 6.3001 7.88175 6.3001 9C6.3001 10.1182 5.69575 11.025 4.9501 11.025Z"
        fill="white"
      />
      <path d="M12.0363 11.582H10.8213V12.887H12.0363V11.582Z" fill="#1976D2" />
      <path d="M13.5666 11.582H12.3516V12.887H13.5666V11.582Z" fill="#1976D2" />
      <path d="M15.0973 11.582H13.8823V12.887H15.0973V11.582Z" fill="#1976D2" />
      <path d="M12.0363 9.9707H10.8213V11.2757H12.0363V9.9707Z" fill="#1976D2" />
      <path d="M13.5666 9.9707H12.3516V11.2757H13.5666V9.9707Z" fill="#1976D2" />
      <path d="M15.0973 9.9707H13.8823V11.2757H15.0973V9.9707Z" fill="#1976D2" />
      <path d="M16.6276 9.9707H15.4126V11.2757H16.6276V9.9707Z" fill="#1976D2" />
      <path d="M12.0363 8.41797H10.8213V9.72297H12.0363V8.41797Z" fill="#1976D2" />
      <path d="M13.5666 8.41797H12.3516V9.72297H13.5666V8.41797Z" fill="#1976D2" />
      <path d="M15.0973 8.41797H13.8823V9.72297H15.0973V8.41797Z" fill="#1976D2" />
      <path d="M16.6276 8.41797H15.4126V9.72297H16.6276V8.41797Z" fill="#1976D2" />
      <path d="M13.5666 6.80078H12.3516V8.10578H13.5666V6.80078Z" fill="#1976D2" />
      <path d="M15.0973 6.80078H13.8823V8.10578H15.0973V6.80078Z" fill="#1976D2" />
      <path d="M16.6276 6.80078H15.4126V8.10578H16.6276V6.80078Z" fill="#1976D2" />
    </Icon>
  );
});
