import { Button as ChakraButton, ButtonProps as ChakraButtonProps, Text, TextProps } from '@chakra-ui/react'
import { cloneElement } from 'react'

type TIconSize = 'normal' | 'big' | 'small'

type TVariant = 'solid' | 'outline' | 'text'

export type ButtonProps = ChakraButtonProps & {
  label: string
  full?: boolean
  size?: 'normal' | 'big'
  leftIconSize?: TIconSize
  rightIconSize?: TIconSize
  variant?: TVariant
  textStyles?: TextProps
}

export const Button = ({
  label,
  leftIcon,
  rightIcon,
  full = false,
  size = 'normal',
  leftIconSize = 'normal',
  rightIconSize = 'normal',
  variant = 'solid',
  textStyles,
  ...props
}: ButtonProps) => {
  const solidVariantStyle: ChakraButtonProps =
    variant === 'solid'
      ? {
          borderRadius: '2px',
          backgroundColor: 'letter.button.main',
          paddingY: '10px',
          paddingX: '8px',
          height: size === 'normal' ? '40px' : '57px',
          textTransform: 'uppercase',
          color: 'letter.onButton.main',
          _hover: { backgroundColor: 'letter.button.hover' },
        }
      : {}

  const outlineVariantStyle: ChakraButtonProps =
    variant === 'outline'
      ? {
          borderRadius: '6px',
          backgroundColor: 'transparent',
          paddingY: '10px',
          paddingX: '8px',
          height: size === 'normal' ? '40px' : '57px',
          border: '1px solid',
          borderColor: 'letter.onSurface.divider',
          textTransform: 'uppercase',
          color: 'letter.onButton.main',
          _hover: { backgroundColor: 'letter.button.hover' },
        }
      : {}

  const solidVariantTextStyle: TextProps =
    variant === 'solid'
      ? {
          textAlign: 'center',
          paddingX: '8px',
        }
      : {}

  const outlineVariantTextStyle: TextProps =
    variant === 'outline'
      ? {
          textAlign: 'center',
          paddingX: '8px',
        }
      : {}

  const textVariantStyle: ChakraButtonProps =
    variant === 'text'
      ? {
          borderRadius: '2px',
          backgroundColor: 'transparent',
          color: 'letter.onButton.main',
          _hover: { opacity: '0.6' },
        }
      : {}

  const iconStyle = (iconSize: TIconSize) => ({
    boxSize: iconSize === 'normal' ? '18px' : iconSize === 'big' ? '38px' : '12px',
    minWidth: iconSize === 'normal' ? '18px' : iconSize === 'big' ? '38px' : '12px',
  })

  return (
    <ChakraButton
      display="flex"
      alignItems="center"
      borderRadius="6px"
      width={!full ? 'fit-content' : '100%'}
      height={size === 'normal' ? '40px' : '57px'}
      paddingX="0px"
      {...solidVariantStyle}
      {...outlineVariantStyle}
      {...textVariantStyle}
      {...props}
    >
      {!!leftIcon &&
        cloneElement(leftIcon, {
          ...iconStyle(leftIconSize),
        })}

      <Text
        width="100%"
        minWidth="0"
        noOfLines={1}
        whiteSpace="normal"
        fontSize="14px"
        lineHeight="16px"
        fontWeight="bold"
        {...solidVariantTextStyle}
        {...outlineVariantTextStyle}
        {...textStyles}
      >
        {label}
      </Text>

      {!!rightIcon &&
        cloneElement(rightIcon, {
          ...iconStyle(rightIconSize),
        })}
    </ChakraButton>
  )
}
