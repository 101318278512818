import { Box, Flex, Heading, Spinner, Text } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

import { DiscordAltIcon } from '../assets/components/DiscordAltIcon'
import background from '../assets/images/letter_logo-background.svg'
import { DiscordSignSuccessModal } from '../components/discordSign/DiscordSignSuccessModal'
import { GradientButton } from '../components/GradientButton'
import { GradientLink } from '../components/GradientLink'
import { SignMessageModal } from '../components/SignMessageModal/SignMessageModal'
import { useMultiAuth } from '../hooks/useMultiAuth'
import { MainTemplate } from '../templates/Main'
import { EMultiAuthStatus } from '../types/hooks/useMultiAuth'

type Props = {
  authorizationModalIsOpen: boolean
  onCloseAuthorizationModal(): void

  successModalIsOpen: boolean
  onCloseSuccessModal(): void

  onGivePermission(): void
  isRequesting: boolean

  discordId: string
  discordUsername: string
}

export const DiscordSignLayout = ({
  authorizationModalIsOpen,
  onCloseAuthorizationModal,
  successModalIsOpen,
  onCloseSuccessModal,
  onGivePermission,
  isRequesting,
  discordId,
  discordUsername,
}: Props) => {
  const location = useLocation()
  const { authStatus, authData } = useMultiAuth()

  return (
    <MainTemplate>
      <Flex
        flexGrow={1}
        width="100%"
        backgroundImage={`url(${background})`}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          paddingX={{ base: '12px', sm: '34px' }}
          paddingY={{ base: '28px', sm: '34px' }}
          marginY="64px"
          marginX="12px"
          color="letter.onSurface.main"
          backgroundColor="letter.surface.main"
          borderRadius="md"
          border="1px solid"
          borderColor="letter.onSurface.divider"
          width="658px"
        >
          <Heading as="h2" fontSize={{ base: '28px', sm: '40px' }} fontWeight="semibold" lineHeight="40px">
            Event access
          </Heading>

          <Box
            width={{ base: '42px', sm: '60px' }}
            height={{ base: '6px', sm: '8px' }}
            background="letter.gradient.main"
            marginTop="12px"
          />

          {authStatus === EMultiAuthStatus.starting ? (
            <Flex justifyContent="center" marginY="28px">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Flex direction="column" alignItems="center">
              <Flex
                width="150px"
                height="150px"
                backgroundColor="letter.surface.darkest"
                borderRadius="full"
                justifyContent="center"
                alignItems="center"
                marginY="28px"
              >
                <DiscordAltIcon boxSize="78px" fill="letter.gradient.main" />
              </Flex>

              <Box maxWidth="432px" textAlign="center" fontSize="16px">
                <Text fontWeight="semibold">Your event is hosted on Discord, the online chat forum.</Text>

                <Text marginTop="28px">
                  In order to continue to you must give the Discord Bot permission to review your NFTs to assign the
                  appropriate permissions on the Discord site.
                </Text>

                <Flex direction="column" marginTop="28px" gridRowGap="8px">
                  {discordId && (
                    <Text>
                      <b>Discord ID:</b> {discordId}
                    </Text>
                  )}

                  {discordUsername && (
                    <Text>
                      <b>Discord username:</b> {discordUsername}
                    </Text>
                  )}
                </Flex>
              </Box>

              {authData.isAuthenticated ? (
                <GradientButton
                  label="GIVE PERMISSION"
                  width="370px"
                  marginTop="28px"
                  onClick={onGivePermission}
                  isLoading={isRequesting}
                />
              ) : (
                <GradientLink
                  to={`/login?redirect_to=${location.pathname}`}
                  label="Login"
                  width="370px"
                  marginTop="28px"
                />
              )}
            </Flex>
          )}
        </Box>
      </Flex>

      <SignMessageModal isOpen={authorizationModalIsOpen} onClose={onCloseAuthorizationModal} />
      <DiscordSignSuccessModal isOpen={successModalIsOpen} onClose={onCloseSuccessModal} />
    </MainTemplate>
  )
}
