import { useDisclosure } from '@chakra-ui/react'
import type { Stripe, StripeElements } from '@stripe/stripe-js'
import { ISignedMessage } from 'letter-sdk'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'

import { renderToast } from '../helpers/chakraToast'
import { useLazyFetch } from '../hooks/useFetch'
import { useMultiAuth } from '../hooks/useMultiAuth'
import { useSDK } from '../hooks/useSDK'
import { useStaticCategory } from '../hooks/useStaticCategory'
import { EventLayout } from '../layouts/Event'
import type { TEvent } from '../types/pages/event'

type TParams = {
  eventLabel: string
  contentProviderLabel: string
  categoryLabel: string
}

type TAuthData = {
  token: string
}

const AUTH_ENDPOINT = `${process.env.REACT_APP_API_URL}/auth`
const MINT_ENDPOINT = `${process.env.REACT_APP_API_URL}/license/mint`
const GET_STRIPE_CLIENT_SECRET = `${process.env.REACT_APP_API_URL}/payment`

export const EventPage = () => {
  const { contentProviderLabel, eventLabel, categoryLabel } = useParams<TParams>()
  const { staticCategory } = useStaticCategory({ categoryLabel, contentProviderLabel })
  const { getEvent, checkAccess, signMessage, getStatus, needsAuthorizationToWrite, buyLicense, getPrice } = useSDK()
  const { authData } = useMultiAuth()
  const { isOpen: paymentModalIsOpen, onClose: onClosePaymentModal, onOpen: onOpenPaymentModal } = useDisclosure()

  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const [dispatchAuth] = useLazyFetch<TAuthData>({ endpoint: AUTH_ENDPOINT, method: 'GET' })
  const [dispatchMint] = useLazyFetch({ endpoint: MINT_ENDPOINT, method: 'POST' })
  const [dispatchStripeClientSecret] = useLazyFetch<string>({
    endpoint: GET_STRIPE_CLIENT_SECRET,
    method: 'POST',
  })

  const [event, setEvent] = useState<TEvent>()
  const [eventPrice, setEventPrice] = useState<number>()
  const [hasAccess, setHasAccess] = useState(false)
  const [showPaymentSignMessageAlert, setShowPaymentSignMessageAlert] = useState(false)
  const [isPayed, setIsPayed] = useState(false)
  const [isPaying, setIsPaying] = useState(false)

  const loading = useMemo(() => {
    const getEventStatus = getStatus('getEvent')
    const getPriceStatus = getStatus('getPrice')

    if (!getEventStatus || !getPriceStatus) return true

    return getEventStatus === 'requesting' || getPriceStatus === 'requesting'
  }, [getStatus])

  const signedMessageRef = useRef<ISignedMessage<any>>()

  const handlePopulate = useCallback(async () => {
    try {
      if (!contentProviderLabel || !eventLabel) throw new Error('Missing parameters')

      const object = await getEvent({ contentProviderLabel, eventLabel })
      const price = await getPrice({ contentProviderLabel, objectLabel: eventLabel })

      setEvent(object)
      setEventPrice(price)
    } catch (error: any) {
      navigate('/404')
      renderToast({ type: 'error', message: error.message })
    }
  }, [contentProviderLabel, eventLabel, getEvent, navigate, getPrice])

  const handleOpenPaymentModal = () => {
    if (!authData.isAuthenticated) {
      navigate(`/login?redirect_to=${location.pathname}?payment_modal=1`)
      return
    }

    onOpenPaymentModal()
  }

  const handleStripePay = async (stripe: Stripe, elements: StripeElements) => {
    if (!event || !signedMessageRef.current) return

    setIsPaying(true)

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      })

      if (error) {
        throw error
      }

      if (!paymentIntent) throw new Error('Unexpected Error')

      await dispatchMint({
        data: {
          objectLabel: event.label,
          signedMessage: signedMessageRef.current,
          paymentInfo: {
            id: paymentIntent.id,
          },
        },
      })

      setHasAccess(true)
      setIsPayed(true)
      renderToast({ type: 'success', message: 'Payment has been done successful' })
    } catch (error: any) {
      renderToast({ type: 'error', message: error.message })
    } finally {
      setIsPaying(false)
    }
  }

  const handleStripeGetClientSecret = useCallback(async () => {
    if (!event) throw new Error('Event not found')

    try {
      const {
        data: { token },
      } = await dispatchAuth()

      if (!signedMessageRef.current) {
        if (needsAuthorizationToWrite) {
          setShowPaymentSignMessageAlert(true)
        }

        const signedMessage = await signMessage({ message: token })

        signedMessageRef.current = signedMessage

        if (needsAuthorizationToWrite) {
          setShowPaymentSignMessageAlert(false)
        }
      }

      const { data: clienSecret } = await dispatchStripeClientSecret({
        data: {
          objectLabel: event.label,
          signedMessage: signedMessageRef.current,
        },
      })

      return clienSecret
    } catch (error: any) {
      renderToast({ type: 'error', message: error.message })
      onClosePaymentModal()
    }
  }, [dispatchAuth, dispatchStripeClientSecret, signMessage, needsAuthorizationToWrite, event, onClosePaymentModal])

  const handlePayWithFlow = async () => {
    setIsPaying(true)

    try {
      if (!event) throw new Error('Event not found')

      await buyLicense({ contentProviderLabel: staticCategory.contentProviderLabel, objectLabel: event.label })

      setHasAccess(true)
      setIsPayed(true)
      renderToast({ type: 'success', message: 'Payment has been done successful' })
    } catch (error: any) {
      renderToast({ type: 'error', message: error.message })
    } finally {
      setIsPaying(false)
    }
  }

  useEffect(() => {
    handlePopulate()
  }, [handlePopulate])

  useEffect(() => {
    if (!authData.isAuthenticated || !event || !event.accessLink) {
      setHasAccess(false)
      return
    }

    checkAccess({
      contentProviderLabel: staticCategory.contentProviderLabel,
      objectLabel: event.label,
      address: authData.address,
      blockchainLabel: authData.blockchainLabel,
    })
      .then(setHasAccess)
      .catch(() => {
        setHasAccess(false)
      })
  }, [authData, event, checkAccess, staticCategory])

  useEffect(() => {
    if (!authData.isAuthenticated) return

    if (getStatus('checkAccess') !== 'success') return

    if (hasAccess) return

    const openPaymentModal = searchParams.get('payment_modal')

    if (!openPaymentModal || openPaymentModal !== '1') return

    onOpenPaymentModal()
  }, [searchParams, onOpenPaymentModal, hasAccess, getStatus, authData])

  return (
    <EventLayout
      event={event}
      eventPrice={eventPrice}
      staticCategory={staticCategory}
      loading={loading}
      hasAccess={hasAccess}
      paymentModalIsOpen={paymentModalIsOpen}
      onClosePaymentModal={onClosePaymentModal}
      onOpenPaymentModal={handleOpenPaymentModal}
      showPaymentSignMessageAlert={showPaymentSignMessageAlert}
      onStripeGetClientSecret={handleStripeGetClientSecret}
      onStripePay={handleStripePay}
      onPayWithFlow={handlePayWithFlow}
      isPayed={isPayed}
      isPaying={isPaying}
    />
  )
}
