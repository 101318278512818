import { Icon, IconProps } from "@chakra-ui/react";
import { memo } from "react";

export const DiscordIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 17 18" fill="white" {...props}>
      <path d="M1.80952 0C0.820562 0 0 0.818106 0 1.80411V14.4329C0 15.4183 0.819577 16.236 1.80776 16.237L12.3892 16.2616C12.5297 16.2617 12.6682 16.2291 12.7939 16.1665L14.8102 17.7979C14.9429 17.9055 15.1034 17.9733 15.2732 17.9936C15.4429 18.0139 15.615 17.9858 15.7694 17.9126C15.9238 17.8394 16.0543 17.7241 16.1456 17.58C16.237 17.4359 16.2856 17.269 16.2857 17.0985V1.80411C16.2857 0.818106 15.4652 0 14.4762 0H1.80952ZM1.80952 1.80411H14.4762V15.2028L12.7656 13.8197C12.6371 13.716 12.4826 13.6493 12.3187 13.627C12.1549 13.6046 11.988 13.6275 11.8363 13.693C11.6846 13.7586 11.5538 13.8644 11.4581 13.9988C11.3624 14.1333 11.3055 14.2913 11.2936 14.4558L1.81129 14.4329C1.8107 14.4329 1.81011 14.4329 1.80952 14.4329V1.80411ZM6.90941 4.65122C5.74046 4.76307 4.85249 5.45989 4.85249 5.45989C4.85249 5.45989 3.8 6.97275 3.61905 9.92788C4.68305 11.1402 6.29269 11.1471 6.29269 11.1471L6.62667 10.7031C6.28558 10.5858 5.99484 10.4354 5.69717 10.2062L5.75902 10.023C6.31545 10.2765 7.03 10.4247 8.14286 10.4247C9.25571 10.4247 9.97026 10.2756 10.5267 10.023L10.5885 10.2062C10.29 10.4363 9.99923 10.5867 9.65904 10.7031L9.99303 11.1471C9.99303 11.1471 11.6027 11.1402 12.6667 9.92788C12.4857 6.97275 11.4332 5.45989 11.4332 5.45989C11.4332 5.45989 10.491 4.7342 9.3763 4.65122L9.14658 5.11986C8.80548 5.05762 8.44957 5.01239 8.14286 5.01239C7.82619 5.01239 7.47037 5.05508 7.1356 5.11281L6.90941 4.65122ZM6.51358 7.21643C6.91167 7.21643 7.2381 7.62055 7.2381 8.11849C7.2381 8.61642 6.91167 9.02054 6.51358 9.02054C6.11548 9.02054 5.79083 8.61642 5.79083 8.11849C5.79083 7.62055 6.11548 7.21643 6.51358 7.21643ZM9.77214 7.21643C10.1702 7.21643 10.4949 7.62055 10.4949 8.11849C10.4949 8.61642 10.1702 9.02054 9.77214 9.02054C9.37404 9.02054 9.04762 8.61642 9.04762 8.11849C9.04762 7.62055 9.37404 7.21643 9.77214 7.21643Z" />
    </Icon>
  );
});
