import axios from 'axios'
import { LoaderFunction, redirect } from 'react-router-dom'

type TResponse = {
  discordId: string
  discordUsername: string
  isAuthenticated: boolean
}

export type TLoaderResponse = {
  discordId: string
  discordUsername: string
}

const VERIFY_USER_AUTHENTICATION_ENDPOINT = `${process.env.REACT_APP_DISCORD_BOT_URL}/verify_authentication`

export const discordSignLoader: LoaderFunction = async ({ params }) => {
  try {
    if (!params.token) {
      return redirect('/')
    }

    const {
      data: { discordId, discordUsername, isAuthenticated },
    } = await axios.post<TResponse>(VERIFY_USER_AUTHENTICATION_ENDPOINT, { token: params.token })

    if (isAuthenticated) return redirect('/')

    return {
      discordId,
      discordUsername,
    }
  } catch {
    return redirect('/')
  }
}
