import { Box } from '@chakra-ui/react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FormEvent, useState } from 'react'

import { GradientButton } from '../../GradientButton'
import { TStripeOnPay } from './PaymentStripeModal'

type Props = {
  onPay: TStripeOnPay
  isPaying?: boolean
}

export const PaymentStripeForm = ({ onPay, isPaying }: Props) => {
  const stripe = useStripe()
  const elements = useElements()

  const [isReady, setIsReady] = useState(false)

  const handleReady = () => {
    setIsReady(true)
  }

  const handleSubmit = async (formEvent: FormEvent) => {
    formEvent.preventDefault()

    if (!stripe || !elements) return

    await onPay(stripe, elements)
  }

  return (
    <Box as="form" onSubmit={handleSubmit} transition="opacity .5s" opacity={isReady ? 1 : 0}>
      <PaymentElement onReady={handleReady} />
      <GradientButton label="PAY" full marginTop="42px" type="submit" isLoading={isPaying} />
    </Box>
  )
}
