import { Icon, IconProps } from '@chakra-ui/react'
import { memo } from 'react'

export const ObjectIcon = memo((props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 18" fill="white" {...props}>
      <path d="M9.83317 0.708374C14.0415 1.12504 17.3332 4.68337 17.3332 9.00004C17.3332 10.2084 17.0665 11.3584 16.5998 12.3917L14.4165 11.1167C14.6832 10.4584 14.8332 9.75004 14.8332 9.00004C14.8332 6.05837 12.6582 3.64171 9.83317 3.23337V0.708374ZM8.99984 14.8334C10.8415 14.8334 12.4748 14 13.5415 12.65L15.7082 13.925C14.1915 15.9917 11.7498 17.3334 8.99984 17.3334C4.3915 17.3334 0.666504 13.5834 0.666504 9.00004C0.666504 4.67504 3.94984 1.12504 8.1665 0.708374V3.23337C5.3415 3.64171 3.1665 6.05837 3.1665 9.00004C3.1665 10.5471 3.78109 12.0309 4.87505 13.1248C5.96901 14.2188 7.45274 14.8334 8.99984 14.8334ZM8.99984 4.00004C10.3259 4.00004 11.5977 4.52682 12.5354 5.46451C13.4731 6.40219 13.9998 7.67396 13.9998 9.00004C13.9998 11.475 12.1998 13.5334 9.83317 13.9334V11.3584C10.8082 11.0167 11.4998 10.0917 11.4998 9.00004C11.4998 8.337 11.2364 7.70111 10.7676 7.23227C10.2988 6.76343 9.66288 6.50004 8.99984 6.50004L8.5415 6.54171L7.25817 4.31671C7.79984 4.10837 8.38317 4.00004 8.99984 4.00004ZM3.99984 9.00004C3.99984 7.45004 4.70817 6.08337 5.8165 5.15004L7.09984 7.37504C6.72484 7.80837 6.49984 8.38337 6.49984 9.00004C6.49984 10.0917 7.1915 11.0167 8.1665 11.3584V13.9334C5.79984 13.5334 3.99984 11.475 3.99984 9.00004Z" />
    </Icon>
  )
})
