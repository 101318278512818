import { Flex } from '@chakra-ui/react'

import { AboutStep } from './Step'
import { AboutSubStep } from './SubStep'

export const AboutHowPurchaseFlow = () => {
  return (
    <Flex direction="column" marginTop="64px" gridRowGap="32px">
      <AboutStep
        index={1}
        title="Choose the event"
        description="First select the event that you would like to purchase."
      />

      <AboutStep index={2} title="Purchase">
        <Flex direction="column" gridRowGap="54px">
          <AboutSubStep
            index={1}
            description={
              <>
                <b>Get access</b>
                <br />
                If you are happy that you have the right event click on the “Get Exclusive Access” button on the
                righthand side.
              </>
            }
          />

          <AboutSubStep
            index={2}
            description={
              <>
                <b>Make payment via Dapper</b>
                <br />
                <br />
                Paying is easy and simple to do. Simply click the pay button, this will launch the Dapper payment
                window.
                <br />
                <br />
                Once in the payment window follow the instructions to make your purchase either through credit card or
                via your existing Dapper balance.
                <br />
                <br />
                <b>
                  Please be aware that Dapper wallet will also charge you a service fee, this will be added to the total
                  you pay for the event.
                </b>
              </>
            }
          />
        </Flex>
      </AboutStep>

      <AboutStep
        index={3}
        title="NFT generation"
        description="The partner provider will generate a custom NFT - your Letter Key, granting you access to the event or theme you’ve chosen."
      />
    </Flex>
  )
}
