import 'swiper/css'
import 'swiper/css/navigation'

import { Box, Flex, Heading } from '@chakra-ui/react'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { NextIcon } from '../../assets/components/NextIcon'
import { PreviousIcon } from '../../assets/components/PreviousIcon'
import type { TStaticCategory } from '../../types/pages/category'
import type { TEvent } from '../../types/pages/event'
import { EventCard } from './EventCard'

type Props = {
  events: TEvent[]
  staticCategory: TStaticCategory
}

export const EventCategoryLatestEvents = ({ events, staticCategory }: Props) => {
  return (
    <Flex
      zIndex={2}
      width="100%"
      justifyContent="center"
      color="letter.onBox.main"
      background="letter.box.700"
      id="LatestEvents"
    >
      <Box width="100%" maxWidth="2178px" paddingY="32px" paddingX={{ base: '16px', md: '32px', lg: 'min(154px, 8%)' }}>
        <Heading as="h3" fontSize={{ base: '20px', md: '26px' }} fontWeight="semibold" marginBottom="14px">
          WEEKLY HIGHLIGHTS
        </Heading>

        <Box position="relative">
          <Swiper
            resizeObserver
            modules={[Navigation]}
            navigation={{
              nextEl: '.swiper-button-next-unique',
              prevEl: '.swiper-button-prev-unique',
            }}
            spaceBetween={22}
            slidesPerView={1}
            style={{
              padding: '22px',
              margin: '0px 22px',
            }}
            breakpoints={{
              390: { slidesPerView: 1.1, slidesPerGroup: 1 },

              450: { slidesPerView: 1.5, slidesPerGroup: 1 },

              550: { slidesPerView: 2.1, slidesPerGroup: 2 },

              685: { slidesPerView: 2.5, slidesPerGroup: 2 },

              800: { slidesPerView: 3.1, slidesPerGroup: 3 },

              900: { slidesPerView: 3.5, slidesPerGroup: 3 },

              992: { slidesPerView: 2.1, slidesPerGroup: 2 },

              1070: { slidesPerView: 2.5, slidesPerGroup: 2 },

              1300: { slidesPerView: 3.1, slidesPerGroup: 3 },

              1450: { slidesPerView: 3.5, slidesPerGroup: 3 },

              1700: { slidesPerView: 4.1, slidesPerGroup: 4 },

              1850: { slidesPerView: 4.5, slidesPerGroup: 4 },
            }}
          >
            {events.map((event, index) => (
              <SwiperSlide key={`latest-${event.label}-${index}`}>
                <EventCard event={event} staticCategory={staticCategory} withResponsiveness />
              </SwiperSlide>
            ))}
          </Swiper>

          <PreviousIcon
            className="swiper-button-prev-unique"
            aria-label="Previous slide"
            position="absolute"
            top="50%"
            display={{ base: 'none', md: 'flex' }}
            transform="translateY(-50%)"
            left={{ base: '-12px', lg: '-20px', '2xl': '-40px' }}
            boxSize={{ base: '16px', lg: '20px', '2xl': '40px' }}
            sx={{
              '&.swiper-button-disabled': {
                display: 'none',
              },
            }}
          />

          <NextIcon
            fontSize="unset"
            className="swiper-button-next-unique"
            aria-label="Next slide"
            position="absolute"
            display={{ base: 'none', md: 'flex' }}
            top="50%"
            transform="translateY(-50%)"
            right={{ base: '-16px', lg: '-20px', '2xl': '-40px' }}
            boxSize={{ base: '16px', lg: '20px', '2xl': '40px' }}
            sx={{
              '&.swiper-button-disabled': {
                display: 'none',
              },
            }}
          />
        </Box>
      </Box>
    </Flex>
  )
}
