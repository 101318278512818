export const walletConnectOptions = {
  projectId: '56e6c49cc5322f46778cd12dd69eeeff',
  relayUrl: 'wss://relay.walletconnect.com',
  metadata: {
    name: 'Letter',
    description: 'Letter platform',
    url: 'https://letter.coz.io',
    icons: [window.location.origin + '/letter_logo.png'],
  },
}
