import { Box, BoxProps, Flex, Text, useBreakpointValue } from '@chakra-ui/react'

import { Button } from '../Button'

type Props = {
  title: string
  description: string
  buttonLabel: string
  buttonIcon: React.ReactElement
  buttonClick(): void
} & BoxProps

export const LoginBackupOption = ({ buttonLabel, buttonIcon, description, buttonClick, title, ...props }: Props) => {
  const buttonFull = useBreakpointValue({ base: true, md: false })

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      padding="18px"
      backgroundColor="letter.surface.darkest"
      width={{ base: '100%', md: '50%' }}
      borderRadius="6px"
      {...props}
    >
      <Box>
        <Text fontSize="14px" fontWeight="bold">
          {title}
        </Text>

        <Text fontSize="16px" marginTop="8px">
          {description}
        </Text>
      </Box>

      <Button
        marginTop="18px"
        backgroundColor="letter.box.main"
        _hover={{
          color: 'letter.blue.main',
        }}
        full={buttonFull}
        color="letter.onBox.main"
        label={buttonLabel}
        leftIcon={buttonIcon}
        onClick={buttonClick}
      />
    </Flex>
  )
}
