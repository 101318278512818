import apLogo from '../assets/images/ap_logo.png'
import { APTop25light } from '../assets/themes/categories/APTop25'
import type { TStaticCategory } from '../types/pages/category'

type TCategoriesByContentProvider = Record<
  string,
  Record<string, Omit<TStaticCategory, 'label' | 'contentProviderLabel'>>
>

const TESTNET_STATIC_CATEGORIES: TCategoriesByContentProvider = {
  ap: {
    sports: {
      theme: APTop25light,
      logoURL: apLogo,
      title: 'AP SPORTS',
      presenterCaptionPlaceholder: 'Ap Sports writer',
      description:
        'Join the world’s first Web3 Sports News Service from AP. Members receive early access to the AP Sport channels and gain direct access to our top sports writers, coaches and analysts.',
    },
  },
}

const MAINNET_STATIC_CATEGORIES: TCategoriesByContentProvider = {
  ap: {
    sports: {
      theme: APTop25light,
      logoURL: apLogo,
      title: 'AP SPORTS',
      presenterCaptionPlaceholder: 'Ap Sports writer',
      description:
        'Join the world’s first Web3 Sports News Service from AP. Members receive early access to the AP Sport channels and gain direct access to our top sports writers, coaches and analysts.',
    },
  },
}

export const STATIC_CATEGORIES =
  process.env.REACT_APP_NETWORK == 'main' ? MAINNET_STATIC_CATEGORIES : TESTNET_STATIC_CATEGORIES

export const DEFAULT_CATEGORY_ROUTE = `/category/ap/sports`
