import { Box, Heading, Text } from '@chakra-ui/react'

export const AboutOverviewLayout = () => {
  return (
    <Box>
      <Box>
        <Heading as="h3" fontSize="32px" fontWeight="semibold" color="letter.onSurface.main">
          Overview
        </Heading>

        <Box width="64px" height="5px" background="letter.gradient.dark" marginTop="12px" />
      </Box>

      <Text fontSize="18px" marginTop="64px">
        <b>
          Letter is a transformational platform that connects readers, creators and publishers. It is the future of
          information, uniting the power and safety of blockchain with world class content partners.
        </b>
        <br />
        <br />
        Letter transforms you from a passive recipient to an active participant. Now, you’ll finally get to know the
        person behind the stories that shape your life. No more annoying paywalls, disruptive ads or overpriced and
        unused subscriptions. As a Letter Member, you have full access to high-quality information directly from the
        source, that you can verify yourself, with unprecedented access to journalists, specialists and influencers.
        <br />
        <br />
        Your Letter Key is a NFT that unlocks a world of knowledge, with no slant and no mysterious algorithms that tell
        people what to read. The power of choice is back where it belongs – in your hands. The Letter Membership is
        unlike anything else, because you are not a subscriber, you are the owner of the Letter Key that grants you
        access to thousands of articles, news pieces, exclusive events, forums and live discussions with athletes,
        specialists, influencers and much more.
        <br />
        <br />
        In a nutshell – you pay only for the content that interests you. When you want more, just get an additional NFT
        or trade yours to get a different one.
      </Text>

      <Text fontSize="34px" fontWeight="semibold" marginTop="64px">
        <Text
          fontSize="90px"
          color="letter.pink.light"
          as="span"
          lineHeight="0px"
          position="relative"
          top="24px"
          marginRight="6px"
        >
          “
        </Text>
        From the stories that change your world to the stories that change the world, every great story starts with a
        letter. Every great story is on Letter.
        <Text
          fontSize="90px"
          color="letter.pink.light"
          as="span"
          display="inline-block"
          transform="rotate(180deg)"
          lineHeight="0px"
          position="relative"
          top="12px"
          marginLeft="6px"
        >
          “
        </Text>
      </Text>
    </Box>
  )
}
