import sanitizeHtml from 'sanitize-html'

export const sanitize = (html: string) => {
  return sanitizeHtml(html, {
    allowedTags: ['span'],
    allowedAttributes: {
      span: ['class'],
    },
    allowedClasses: {
      span: ['emphasis', 'bigger', 'brand', 'font-22', 'font-light'],
    },
  })
}
